import React from "react";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

interface AppBarDropdownMenuItemProps extends MenuItemProps {
  showSpinner?: boolean;
  icon?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrap: {
      "& svg": {
        fontSize: "14px",
      },
    },
  })
);

export default function AppBarDropdownMenuItem({
  children,
  disabled,
  showSpinner,
  onClick,
  icon,
}: AppBarDropdownMenuItemProps) {
  const classes = useStyles();

  return (
    <MenuItem disabled={showSpinner || disabled} onClick={onClick}>
      <Box
        margin={"-2px 0 0"}
        display={"flex"}
        alignItems={"center"}
        className={classes.iconWrap}
      >
        {showSpinner ? <CircularProgress size={12} /> : icon}
      </Box>
      <Box fontSize={"13px"} fontWeight={"600"} margin={"0 0 0 6px"}>
        {children}
      </Box>
    </MenuItem>
  );
}
