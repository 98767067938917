import React from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      fontWeight: 700,
      borderRadius: "2px",
      background: fade(theme.palette.primary.main, 0.07),
    },
    wrapper: {
      margin: "3px",
      position: "relative",
      display: "inline-block",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default function TableMobileActionButton({
  buttonProps,
  actionInProgress,
  icon = undefined,
}: {
  buttonProps?: IconButtonProps;
  actionInProgress?: boolean;
  icon?: JSX.Element | undefined;
}) {
  const classes = useStyles();

  return (
    <span className={classes.wrapper}>
      <IconButton
        color="primary"
        className={classes.actionButton}
        disabled={actionInProgress}
        {...buttonProps}
      >
        {icon}
      </IconButton>

      {actionInProgress && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </span>
  );
}
