import React, { useState } from "react";
import AuthPaper from "components/uiComponents/papers/AuthPaper";
import { Box } from "@material-ui/core";
import { useFormMesssageBar } from "components/uiComponents/forms/FormMessageBar";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";
import forgotPasswordFormConfig from "config/auth/forgotPasswordForm";
import axios from "axios";
import config from "config";

const ForgotPasswordPage = () => {
  const { title, children, type, setMessage } = useFormMesssageBar();
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const onSubmitValidForm = async ({
    userName,
    ...rest
  }: {
    [index: string]: any;
  }) => {
    setDataLoading(true);

    axios
      .request({
        method: "PUT",
        url: `${config.apiUrl}/mfa/forgot_pwd/${userName}`,
        data: { ...rest },
      })
      .then((response) => {
        setDataLoading(false);
        setMessage(response.data.message, null);
      })
      .catch((err) => {
        setDataLoading(false);
        setMessage(err.response.data.message, null, "error");
      });
  };

  return (
    <Box display={"flex"} minHeight={"100vh"}>
      <Box maxWidth={"571px"} margin={"auto"} width={"100%"}>
        <AuthPaper
          title={"Forgot password"}
          formMessageBar={{
            title: title,
            children: children,
            type: type,
          }}
        >
          <AuthFormLayout
            inputsById={forgotPasswordFormConfig.inputsById}
            inputsByHash={forgotPasswordFormConfig.inputsByHash}
            onSubmitValidForm={onSubmitValidForm}
            isDataLoading={dataLoading}
            submitLabel={"Reset"}
          />
        </AuthPaper>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
