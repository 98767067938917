import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import clsx from "clsx";
import { CheckedIcon } from "components/icons";

export interface optionInterface {
  label: string;
  value: string;
  icon?: JSX.Element[] | JSX.Element | string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    padding: "10px 14px",
    borderRadius: "10px",
    width: "100%",

    "&.Mui-disabled": {
      background: '#fff',
      borderColor: fade(theme.palette.primary2.main, 0.4),
      color: fade(theme.palette.primary2.main, 0.8),
      "& .MuiButton-startIcon": {
        background: fade(theme.palette.primary2.main, 0.1),
      },
    },

    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "flex-start",
    },

    "& .MuiButton-startIcon": {
      width: "29px",
      height: "29px",
      background: fade(theme.palette.primary2.main, 0.1),
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "background .5s",
      "& .MuiSvgIcon-root": {
        transition: "background .5s",
      },
    },
    "& .MuiButton-endIcon": {
      width: "15px",
      height: "15px",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      right: 13,
      top: "50%",
      transform: "translateY(-50%)",
      opacity: 0,

      "& .MuiSvgIcon-root": {
        fontSize: "8px",
      },
    },
  },
  buttonActive: {
    borderColor: theme.palette.primary.main,
    background: fade(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    "& .MuiButton-startIcon": {
      background: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        color: "white !important",
      },
    },
    "& .MuiButton-endIcon": {
      opacity: 1,
    },
    "&.Mui-disabled": {
      background: fade(theme.palette.primary2.main, 0.1),
      borderColor: fade(theme.palette.primary2.main, 0.5),
      color: fade(theme.palette.primary2.main, 0.8),
      "& .MuiButton-startIcon": {
        background: fade(theme.palette.primary2.main, 0.6),
      },
      "& .MuiButton-endIcon": {
        background: fade(theme.palette.primary2.main, 0.6),
      },
    },
  },

  startIcon: {
    color: theme.palette.primary2.main,
  },
}));

//TODO fix issue with ButtonRadioInterface

const ButtonRadio = ({ input, inputId, handleInputs }: any) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    handleInputs({
      target: {
        name: e.currentTarget.name,
        value: e.currentTarget.value,
      },
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        {input.options.map((option: optionInterface) => {
          return (
            <Grid item xs={6} key={option.value}>
              <Button
                className={clsx({
                  [classes.button]: true,
                  [classes.buttonActive]: input.value === option.value,
                })}
                onClick={handleChange}
                variant={"outlined"}
                value={option.value}
                name={inputId}
                startIcon={option.icon}
                disabled={input.disabled}
                endIcon={<CheckedIcon />}
              >
                {option.label}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ButtonRadio;
