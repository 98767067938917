import React from "react";
import { Box } from "@material-ui/core";

const StatusCellValue = ({
  value,
  status = "success",
}: {
  value: any;
  status?: "success" | "warning" | "error";
}) => {
  const color = `${status}.main`;
  if (!value) return null;
  return (
    <Box color={color}>
      <Box
        display={"inline-block"}
        width={"7px"}
        height={"7px"}
        mr={"10px"}
        borderRadius={"50%"}
        bgcolor={color}
      />
      {value}
    </Box>
  );
};


export default StatusCellValue;