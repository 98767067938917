import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function CloseThinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.7,19.2c0.4,0.4,0.4,1.1,0,1.5c-0.2,0.2-0.5,0.3-0.7,0.3s-0.5-0.1-0.7-0.3L12,13.5l-7.2,7.2
        C4.6,20.9,4.3,21,4.1,21c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1.1,0-1.5l7.2-7.2L3.3,4.8c-0.4-0.4-0.4-1.1,0-1.5
        c0.4-0.4,1.1-0.4,1.5,0l7.2,7.2l7.2-7.2c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5L13.5,12L20.7,19.2z"
      />
    </SvgIcon>
  );
}