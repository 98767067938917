import decorateWithEvents from "utils/decorateWithEvents";

const storage = {
  namespace: "authPP.v0.1",
  key(name: string) {
    return `${this.namespace}.${name}`;
  },
  save(
    name: string,
    value: string | null | boolean,
    storageType: "localStorage" | "sessionStorage" = "localStorage"
  ) {
    setTimeout(() => this.saveSync(name, value, storageType), 1);
  },
  saveSync(
    name: string,
    value: string | null | boolean,
    storageType: "localStorage" | "sessionStorage" = "localStorage"
  ) {
    if (storageType === "sessionStorage") {
      sessionStorage.setItem(this.key(name), JSON.stringify(value));
    } else {
      localStorage.setItem(this.key(name), JSON.stringify(value));
    }
  },
  load(name: string, storageType = "localStorage") {
    const key = this.key(name);
    const str =
      storageType === "sessionStorage"
        ? sessionStorage.getItem(key)
        : localStorage.getItem(key);
    let result = null;
    if (!str) {
      return result;
    }
    try {
      result = JSON.parse(str);
    } catch (e) {
      console.warn(`Cannot parse local storage value ${key}: ${str}`, e);
    }
    return result;
  },
};

const authStorage = decorateWithEvents({
  CHANGE: "change",
  CHANGE_COGNITO_AUTH: "changeCognitoAuth",
  AUTHENTICATE: "authenticate",
  CHANGE_REFRESH_TOKEN_FLOW_STATUS: "refreshTokenFlowStatus",
  token: storage.load("token"),
  isShowTerm: storage.load("isShowTerm"),
  access: storage.load("access"),
  cognitoAccessToken: storage.load("cognitoAccessToken"),
  refreshTokenFlowInProgress: storage.load("refreshTokenFlowInProgress"),

  authenticateCognito(cognitoToken = null) {
    this.cognitoAccessToken = cognitoToken;
    storage.save("cognitoAccessToken", cognitoToken);
    this.emit(this.CHANGE_COGNITO_AUTH, this.getCognitoState());
  },
  startRefreshTokenFlow() {
    storage.saveSync("refreshTokenFlowInProgress", true);
  },
  finishRefreshTokenFlow() {
    storage.saveSync("refreshTokenFlowInProgress", false);
  },
  getIsRefreshTokenFlowInProgress() {
    return this.refreshTokenFlowInProgress;
  },
  logoutCognito() {
    this.cognitoAccessToken = null;
    storage.save("cognitoAccessToken", null);
    this.emit(this.CHANGE, this.getCognitoState());
  },
  getCognitoState() {
    return this.cognitoAccessToken;
  },
  getCognitoToken() {
    return this.cognitoAccessToken;
  },
  authenticate(token = null, isShowTerm = null, access = null) {
    // this.token = token;
    // this.userDepts = userDepts;
    // this.access = access;
    storage.saveSync("token", token);
    storage.saveSync("isShowTerm", isShowTerm);
    storage.saveSync("access", access);
    this.finishRefreshTokenFlow();
    this.emit(this.CHANGE, this.getState());
    this.emit(this.AUTHENTICATE, this.getState());
  },
  getToken() {
    return storage.load("token");
  },
  getIsShowTerm() {
    return storage.load("isShowTerm");
  },
  getAccess() {
    return storage.load("access");
  },
  getRoleCode() {
    if (storage.load("access")) {
      return storage.load("access").userRoleCode;
    }
    else {
      return null;
    }
  },
  logout() {
    storage.saveSync("token", null);
    storage.saveSync("isShowTerm", null);
    storage.saveSync("access", null);
    this.emit(this.CHANGE, this.getState());
  },
  getState() {
    return {
      token: storage.load("token"),
      isShowTerm: storage.load("isShowTerm"),
      access: storage.load("access"),
    };
  },
  setIsShowTerm() {
    this.isShowTerm = "0";
    storage.saveSync("isShowTerm", "0");
  },
});

// function handleStorageChange(event) {
//   console.log("handleStorageChange");
//   console.log(event);
//   if (event.key === storage.key("refreshTokenFlowInProgress")) {
//     authStorage.refreshTokenFlowInProgress = JSON.parse(event.newValue);
//   } else if (event.key === storage.key("token")) {
//     authStorage.token = JSON.parse(event.newValue);
//   } else if (event.key === storage.key("userDepts")) {
//     authStorage.userDepts = JSON.parse(event.newValue);
//   } else if (event.key === storage.key("access")) {
//     authStorage.access = JSON.parse(event.newValue);
//   }
// }

// window.addEventListener("storage", handleStorageChange);

export default authStorage;
export { storage };
