import { useEffect } from "react";
import urlQuery from "utils/urlQuery";

const useUrlQueryState = <Type>(
  key: string,
  initialState: Type
): [Type, (value: Type) => void] => {
  useEffect(() => {
    const urlValue = urlQuery.getByKey(key);
    if (urlValue) {
      setQuery(urlValue);
    } else {
      setQuery(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuery = (newParams: Type) => {
    if (newParams) {
      urlQuery.push({
        [key]: JSON.stringify(newParams),
      });
    } else {
      urlQuery.deleteByKey(key);
    }
  };

  return [urlQuery.getByKey(key), setQuery];
};

export default useUrlQueryState;
