import React from "react";
import styled from "styled-components";

const defaultRootStyles = `
  margin: 17px 0 0;
  box-sizing: border-box;
  padding: 0 10px 0;
  width: 33.3333%;
  display: inline-flex;
  vertical-align: top;
  
  >div {
    width: 100%;
  }
`;

const StyledInputWrap = styled.div`
  && {
    ${defaultRootStyles}
    ${(props: { styles?: string }) => props.styles}
  }
`;

interface Props {
  children: JSX.Element[] | JSX.Element | string;
  rootStyles?: string;
}

const InputWrap = (props: Props) => {
  return (
    <StyledInputWrap styles={props.rootStyles}>
      {props.children}
    </StyledInputWrap>
  );
};

export default InputWrap;
