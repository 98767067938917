import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Grid } from "@material-ui/core";
import { getEnchancedWithValueByHashConfig } from "utils";
import {
  userInfoByHash,
  userInfoGroupsById,
  userInfoGroupsByHash,
} from "config/customer/patientModalConfig";
import { PaperWithTitle } from "components/uiComponents/papers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      flexGrow: 1,
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: `1px solid ${fade("#2C3367", 0.1)}`,
      fontFamily: "Josefin Sans",
      "&:last-child": {
        border: "none",
      },
      padding: "13.3px 5px",
    },
    fieldLabel: {
      textTransform: "uppercase",
      color: fade(theme.palette.primary2.main, 0.6),
      fontSize: "10px",
      fontWeight: 600,
      letterSpacing: "0.7px",
    },
    fieldValue: {
      fontWeight: 600,
      fontSize: "13px",
    },
  })
);

export default function PatientModalContent({
  patientDetail,
}: {
  patientDetail: { [index: string]: any } | null;
}) {
  const classes = useStyles();

  if (!patientDetail) return null;

  const userInfoByHashWithValues = getEnchancedWithValueByHashConfig(
    userInfoByHash,
    patientDetail
  );

  return (
    <Grid container className={classes.grid} spacing={2}>
      {userInfoGroupsById.map((groupId) => {
        const group = userInfoGroupsByHash[groupId];
        return (
          <Grid item xs={12} sm={6} key={groupId}>
            <PaperWithTitle title={group.title} icon={group.icon}>
              {group.ids.map((id) => {
                const field = userInfoByHashWithValues[id];
                return (
                  <div className={classes.row} key={id}>
                    <span className={classes.fieldLabel}>{field.label}</span>
                    <span className={classes.fieldValue}>{field.value}</span>
                  </div>
                );
              })}
            </PaperWithTitle>
          </Grid>
        );
      })}
    </Grid>
  );
}
