import React from "react";
import SmartInputProps from "interfaces/SmartInputInterface";
import StyledDateInput from "components/uiComponents/inputs/styledInputs/StyledDateInput";
import { Box, Grid } from "@material-ui/core";

interface Value {
  from: any;
  to: any;
}

export default function SmartDateRangeInput(props: SmartInputProps) {
  const { inputId, input, handleInputs } = props;
  const value = input.value as Value;
  const fromValue = value.from || null;
  const toValue = value.to || null;

  const onChangeFrom = (value: any) => {
    let newToValue = toValue;
    if (!toValue) {
      newToValue = new Date();
    }
    else if (toValue && (value > toValue)) {
      newToValue = value;
    }
    
    if (value && newToValue) {
      onChange(value, newToValue);
    }
  };

  const onChangeTo = (value: any) => {
    let newFromValue = fromValue;
    if (!fromValue || (fromValue && value < fromValue)) {
      newFromValue = value;
    }
    if (newFromValue && value) {
      onChange(newFromValue, value);
    }
  };

  const onChange = (from: any, to: any) => {
    handleInputs({ target: { name: inputId, value: { from: from, to: to } } });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StyledDateInput
            id={`${inputId}From`}
            label={"From"}
            value={fromValue}
            onChange={onChangeFrom}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledDateInput
            id={inputId}
            label={"To"}
            value={toValue}
            onChange={onChangeTo}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
