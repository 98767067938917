import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { UserIcon } from "components/icons";
import { FlagFilledIcon } from "components/icons";
import theme from "config/theme";
import { Can } from "components/permissions";
import { userRoles } from "config/userRoles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "13px 7px 12px 45px",
    background: fade(theme.palette.primary2.main, 0.02),
    borderBottom: `1px solid ${fade("#2C3367", 0.05)}`,
    cursor: "pointer",
    transition: "opacity .5s",
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "13px 7px 12px 10px",
    },
  },

  rootIsRead: {
    cursor: "inherit",
    "& $icon": {
      color: fade("#445369", 0.4),
      background: fade("#151C56", 0.02),
    },
    "& $name": {
      color: fade(theme.palette.primary2.main, 0.6),
    },
    "& $subject": {
      color: theme.palette.primary2.main,
    },
    "& $date": {
      color: fade(theme.palette.primary2.main, 0.4),
    },
  },

  rootIsArchived: {
    "& $archivedLabel": {
      opacity: 1,
    },
  },

  rootActive: {
    opacity: 1,
    background: theme.palette.primary2.main,
    cursor: "pointer",

    "& $icon": {
      color: "#fff",
      background: fade('#FFFFFF"', 0.1),
    },
    "& $name": {
      color: "#fff",
    },
    "& $subject": {
      color: fade("#fff", 0.6),
    },
    "& $date": {
      color: fade("#fff", 0.4),
    },
    "& $archivedLabel": {
      background: fade('#FFFFFF"', 0.1),
    },
  },

  icon: {
    width: "28px",
    height: "28px",
    background: fade(theme.palette.primary.main, 0.15),
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: 9,
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "16px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  name: {
    color: fade(theme.palette.primary2.main, 0.6),
    fontSize: "12px",
    padding: "0 10px 0 0",
  },
  subject: {
    color: theme.palette.primary.main,
    fontSize: "13px",
    padding: "0 10px 0 0",
    fontWeight: 700,
  },
  date: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  archivedLabel: {
    background: fade(theme.palette.primary2.main, 0.4),
    borderRadius: 4,
    textTransform: "uppercase",
    color: fade("#ffffff", 0.4),
    fontSize: "8px",
    padding: "4px 3px 1px",
    fontWeight: 600,
    margin: "0 0 3px",
    opacity: 0,
  },
}));

export default function ThreadListItem({
  name,
  date,
  isActive = false,
  isRead = false,
  isArchived = false,
  onClick = () => {},
  subject,
  isFollowUp,
}: {
  name: string;
  date: string;
  isActive?: boolean;
  isRead?: boolean;
  isArchived?: boolean;
  onClick?: () => void;
  subject: string;
  isFollowUp: boolean;
}) {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.rootActive]: isActive,
        [classes.rootIsRead]: isRead,
        [classes.rootIsArchived]: isArchived,
      })}
      onClick={onClick}
    >
      <Box className={classes.icon}>
        <UserIcon fontSize={"inherit"} />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Box className={classes.subject}>{subject}</Box>
          <Box className={classes.name}>{name}</Box>
        </Box>
        <Box display="flex" alignItems="flex-end" flexDirection="column">
          <Box display="flex">
            <Box className={classes.archivedLabel}>Archived</Box>
            <Can allowedRoles={[userRoles.admin]}>
              <React.Fragment>
                {isFollowUp && (
                  <Box
                    display="inline-flex"
                    color={theme.palette.error.main}
                    fontSize="12px"
                    ml="4px"
                  >
                    <FlagFilledIcon color="inherit" fontSize="inherit" />
                  </Box>
                )}
              </React.Fragment>
            </Can>
          </Box>
          <Box className={classes.date}>{date}</Box>
        </Box>
      </Box>
    </div>
  );
}
