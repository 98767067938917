import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {
  ListItem,
  List,
  ListItemText,
  IconButton,
  Box,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import history from "appHistory";
import { useAuthCenter } from "AuthCenter";
import PHDLogo from "components/uiComponents/PHDLogo";
import { LogOutIcon, CloseThinIcon, MenuIcon } from "components/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  ADMIN_USERS_PAGE_PATH,
  ADMIN_PATIENTS_PAGE_PATH,
  ADMIN_SYNC_PAGE_PATH,
  CUSTOMER_PROFILE_PAGE_PATH,
  ADMIN_MESSAGES_PAGE_PATH,
  CUSTOMER_MESSAGES_PAGE_PATH,
  ADMIN_PROFILE_PAGE_PATH,
} from "config/routes";
import { canByRoles } from "utils/permissions";
import { userRoles } from "config/userRoles";

type MenuItemType = {
  label: string;
  to: string;
};

const useDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      maxWidth: "360px",
      background: theme.palette.primary2.main,
      color: "#fff",
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      padding: "0 6px",
      "&> div": {
        borderTop: `1px solid ${fade("#ffff", 0.1)}`,
        fontWeight: 600,
        padding: "13px 16px",
      },
      "&> div:last-child": {
        borderBottom: `1px solid ${fade("#ffff", 0.1)}`,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 0px 10px 9px",
    },
    logOutButton: {
      background: "#596476",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      fontSize: "14px",
      padding: "17px 10px",
      fontWeight: 700,
    },
    logOutButtonIcon: {
      opacity: 0.6,
    },
    closeButton: {
      color: fade("#ffff", 0.8),
    },
    drawerInnerWrap: {
      justifyContent: "space-between",
      flexDirection: "column",
      flex: "1 0 auto",
      height: "100%",
      display: "flex",
    },
    listItem: {
      "&.active": {
        background: fade("#ffff", 0.06),
      },
    },
  })
);

export default function MobileToolbar() {
  const drawerClasses = useDrawerStyles();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const authCenter = useAuthCenter();

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const adminMenuConfig: MenuItemType[] = [
    {
      label: "Patients",
      to: ADMIN_PATIENTS_PAGE_PATH,
    },
    {
      label: "Messages",
      to: ADMIN_MESSAGES_PAGE_PATH,
    },
    {
      label: "Users",
      to: ADMIN_USERS_PAGE_PATH,
    },
    {
      label: "Data sync",
      to: ADMIN_SYNC_PAGE_PATH,
    },
    {
      label: 'Profile',
      to: ADMIN_PROFILE_PAGE_PATH,
    },
  ];

  const customerMenuConfig: MenuItemType[] = [
    {
      label: "Dashboard",
      to: "/",
    },
    {
      label: "Messages",
      to: CUSTOMER_MESSAGES_PAGE_PATH,
    },
    {
      label: "Profile",
      to: CUSTOMER_PROFILE_PAGE_PATH,
    },
  ];

  let menuConfig = canByRoles([userRoles.customer])
    ? customerMenuConfig
    : adminMenuConfig;

  return (
    <div>
      <React.Fragment>
        <IconButton component="span" onClick={toggleDrawer(true)}>
          <MenuIcon fontSize={"inherit"} />
        </IconButton>
        <SwipeableDrawer
          anchor={"left"}
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          classes={drawerClasses}
        >
          <div
            className={classes.drawerInnerWrap}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <Box>
              <Box className={classes.header}>
                <PHDLogo color="white" />

                <IconButton
                  className={classes.closeButton}
                  component="span"
                  onClick={toggleDrawer(false)}
                >
                  <CloseThinIcon fontSize={"inherit"} />
                </IconButton>
              </Box>

              <List className={classes.list}>
                {menuConfig.map((menuItem: MenuItemType) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      exact
                      component={RouterNavLink}
                      to={menuItem.to}
                    >
                      <ListItemText primary={menuItem.label} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
            <ListItem
              button
              className={classes.logOutButton}
              disabled={authCenter.logOutInProgress}
              onClick={() => {
                authCenter.logOut().then(() => {
                  history.push("/login");
                });
              }}
            >
              <Box display="flex" alignItems={"center"}>
                <Box mr={"5px"}>
                  {authCenter.logOutInProgress ? (
                    <CircularProgress
                      size={22}
                      className={classes.logOutButtonIcon}
                    />
                  ) : (
                    <LogOutIcon className={classes.logOutButtonIcon} />
                  )}
                </Box>
                <Box>Log-out</Box>
              </Box>
            </ListItem>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
