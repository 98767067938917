import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Typography, Box } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core";
import { CheckedIcon, DangerIcon } from "components/icons";
import clsx from "clsx";

export type FormMessageBarProps = {
  title: string | null;
  children?: JSX.Element[] | JSX.Element | string | null;
  type?: "success" | "error";
};

const useFormMesssageBar = () => {
  const [title, setTitle] = useState<null | string>(null);
  const [children, setChildren] = useState<null | string>(null);
  const [type, setType] = useState<"success" | "error">("success");

  const setMessage = (
    title: string | null = null,
    children: string | null = null,
    type: "success" | "error" = "success"
  ) => {
    setTitle(title);
    setChildren(children);
    setType(type);
  };

  return {
    title: title,
    children: children,
    type: type,
    setMessage: setMessage,
  };
};

const usePaperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "20px 25px 20px 44px",
      position: "relative",
      boxShadow: "0px 6px 26px -8px rgba(0,0,0,0.2) !important",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 15px 15px 44px",
      },
    },
    rounded: {
      borderRadius: "10px",
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperSuccess: {
      background: fade(theme.palette.secondary.main, 0.15),
      "& $icon": {
        background: theme.palette.secondary.main,
      },
    },
    paperError: {
      background: fade(theme.palette.error.main, 0.15),
      "& $icon": {
        background: theme.palette.error.main,
      },
    },
    icon: {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      left: "14px",
      top: "14px",
      fontSize: "12px",
    },
  })
);

export default function FormMessageBar({
  title,
  children,
  type = "success",
}: FormMessageBarProps) {
  const paperClasses = usePaperStyles();
  const classes = useStyles();

  const getColor = (type: "success" | "error") => {
    if (type === "success") {
      return "secondary.main";
    } else if (type === "error") {
      return "error.main";
    }
  };

  if (title === null && children === null) return null;

  return (
    <Paper
      classes={paperClasses}
      className={clsx({
        [classes.paperSuccess]: type === "success",
        [classes.paperError]: type === "error",
      })}
    >
      <Box className={classes.icon}>
        {type === "success" && <CheckedIcon fontSize={"inherit"}/>}
        {type === "error" && (
          <Box width={"1em"} height={"1em"} fontSize={16}>
            <DangerIcon fontSize={"inherit"} />
          </Box>
        )}
      </Box>
      <Box color={getColor(type)} fontWeight={700} fontSize={"13px"}>
        <Typography variant={"inherit"}>{title}</Typography>
      </Box>

      <Box color={getColor(type)}>{children}</Box>
    </Paper>
  );
}

export { useFormMesssageBar };
