import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function KeyboardArrowDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,17.2c-0.4,0-0.8-0.1-1.1-0.4L3.4,9.3c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0l6.4,6.4l6.4-6.4
        c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1l-7.5,7.5C12.8,17.1,12.4,17.2,12,17.2z"
      />
    </SvgIcon>
  );
}