import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import BaseModal from "components/modals/BaseModal";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Box, Typography } from "@material-ui/core";
import { ArrowLeftBoldIcon } from "components/icons";
import PaperControlButton from "components/uiComponents/buttons/PaperControlButton";
import AppBarSubMenuItem from "components/uiComponents/buttons/AppBarSubMenuItem";
import { ThreeDotsIcon } from "components/icons";
import theme from "config/theme";
import { Can } from "components/permissions";
import { userRoles } from "config/userRoles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "0 0 10px",
      width: 'calc(100vw - 20px)',
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 0px",
      borderBottom: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
      margin: "0 0 20px",
    },
  })
);

export default function ThreadMessageListModal({
  open,
  children,
  onClose,
  selectedSubjectTitle,
  selectedSubjectUserName,
  actions,
}: {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  onClose: () => void;
  selectedSubjectTitle: string;
  selectedSubjectUserName: string;
  actions: JSX.Element;
}) {
  const classes = useStyles();
  return (
    <BaseModal open={open} onClose={onClose} fullSize={false}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Box m="8px">
            <PaperControlButton
              onClick={onClose}
              icon={
                <Box fontSize={"12px !important"} display="flex">
                  <ArrowLeftBoldIcon fontSize="inherit" />
                </Box>
              }
            >
              Back
            </PaperControlButton>
          </Box>
          <Box textAlign="center">
            <Typography variant="h2">{selectedSubjectTitle}</Typography>
            <Box>{selectedSubjectUserName}</Box>
          </Box>
          <Box minWidth="80px">
            <Can allowedRoles={[userRoles.admin]}>
              <AppBarSubMenuItem
                disableIcon={true}
                label={
                  <Box display="flex" color={theme.palette.primary.main}>
                    <ThreeDotsIcon />
                  </Box>
                }
              >
                {actions}
              </AppBarSubMenuItem>
            </Can>
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </BaseModal>
  );
}
