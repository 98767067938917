import React from "react";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";
import guardianVerifyConfig from "config/registration/guardianVerifyConfig";

export default function GuardianVerifyForm({
  onSubmitValidForm,
  backButton,
  isDataLoading,
}: {
  onSubmitValidForm: (data: { [index: string]: any }) => void;
  backButton?: JSX.Element[] | JSX.Element | string;
  isDataLoading: boolean;
}) {
  return (
    <AuthFormLayout
      inputsById={guardianVerifyConfig.inputsById}
      inputsByHash={guardianVerifyConfig.inputsByHash}
      submitLabel={"Next"}
      onSubmitValidForm={onSubmitValidForm}
      backButton={backButton}
      isDataLoading={isDataLoading}
    />
  );
}
