import React from "react";
import AutocompleteInput from "components/uiComponents/inputs/AutocompleteInput";
import useProvider from "hooks/useProvider";
import { GET_LIST } from "providers/dataProvider";

export default function SmartAutocompleteInput(props: any) {
  const { input, inputId, handleInputs } = props;

  const { data } = useProvider(
    GET_LIST,
    input?.autocompleteTypeOptions?.resource || null
  );

  const options = data.map((data) => {
    return input.autocompleteTypeOptions.optionAccessor(data);
  });

  return (
    <AutocompleteInput
      inputProps={input.inputProps}
      value={input.value === null ? "" : input.value}
      name={inputId}
      options={options}
      onChange={handleInputs}
      disabled={input.disabled}
      textFieldProps={{
        label: input.label,
        error: input.error,
        helperText: input.error && input.errorMessage,
      }}
    />
  );
}
