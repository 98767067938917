import React from "react";
import { TableCellProps } from "@material-ui/core/TableCell";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import clsx from "clsx";
import styled from "styled-components";
import StyledTableCell from "components/uiComponents/table/cells/StyledTableCell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "inline-block",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    colorButtonPrimary: {
      color: theme.palette.primary.main,
    },
    colorButtonError: {
      color: theme.palette.error.main,
    },
  })
);

type ColorClasses = "colorButtonPrimary" | "colorButtonError";

const StyledButton = styled(Button)({
  fontWeight: 700,
});

export default function ActionTableCell({
  buttonProps,
  buttonLabel,
  tableCellProps,
  actionInProgress,
  buttonVariant = "primary",
  tableCellStyles,
  visibilityByValue = true,
}: {
  buttonLabel: string;
  buttonProps?: ButtonProps;
  tableCellProps?: TableCellProps;
  actionInProgress?: boolean;
  buttonVariant?: "primary" | "error";
  tableCellStyles?: string | undefined;
  visibilityByValue?: boolean;
}) {
  const classes = useStyles();

  const colorButtonClass = `colorButton${capitalizeFirstLetter(
    buttonVariant
  )}` as ColorClasses;

  return (
    <StyledTableCell
      styles={tableCellStyles}
      padding="none"
      {...tableCellProps}
    >
      {visibilityByValue &&
        <span className={classes.wrapper}>
          <StyledButton
            className={clsx(classes[colorButtonClass])}
            color={"inherit"}
            disabled={actionInProgress}
            {...buttonProps}
          >
            {buttonLabel}
          </StyledButton>

          {actionInProgress && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </span>
      }
    </StyledTableCell>
  );
}
