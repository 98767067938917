import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function SortDescIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.5,7.3H3.7C3.3,7.3,3,7,3,6.7S3.3,6,3.7,6h9.8c0.4,0,0.7,0.3,0.7,0.7S13.9,7.3,13.5,7.3z M18.7,17.1V7
        c0-0.4-0.3-0.7-0.7-0.7S17.3,6.6,17.3,7v10.1c0,0.4,0.3,0.7,0.7,0.7S18.7,17.5,18.7,17.1z M13.3,10.1c0-0.4-0.3-0.7-0.7-0.7H3.7
        C3.3,9.4,3,9.7,3,10.1c0,0.4,0.3,0.7,0.7,0.7h8.9C13,10.8,13.3,10.5,13.3,10.1z M11.6,13.5c0-0.4-0.3-0.7-0.7-0.7H3.7
        c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h7.2C11.3,14.2,11.6,13.9,11.6,13.5z M9.9,17c0-0.4-0.3-0.7-0.7-0.7H3.7
        C3.3,16.3,3,16.6,3,17c0,0.4,0.3,0.7,0.7,0.7h5.5C9.6,17.6,9.9,17.3,9.9,17z M18.5,17.8c0.3-0.3,0.3-0.7,0-1l-2.6-2.6
        c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1l2.6,2.6C17.7,18,17.9,18,18,18S18.4,18,18.5,17.8z M18.5,17.8l2.3-2.3
        c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-2.3,2.3c-0.3,0.3-0.3,0.7,0,1C17.7,18,17.9,18,18,18S18.4,18,18.5,17.8z"
      />
    </SvgIcon>
  );
}
