import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function PlaneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21,3.8c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1,0-0.1,0
        c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0L3.5,8.8C3.2,8.9,3,9.2,3,9.5c0,0.3,0.2,0.6,0.5,0.8l7.1,3.1l3.1,7.1
        c0.1,0.3,0.4,0.5,0.7,0.5c0,0,0,0,0,0c0.3,0,0.6-0.2,0.7-0.5L21,4.1C21,4,21,3.9,21,3.8C21,3.8,21,3.8,21,3.8z M17.1,5.8L11,11.8
        L6,9.6L17.1,5.8z M14.4,18l-2.2-5l6.1-6.1L14.4,18z"
      />
    </SvgIcon>
  );
}