import React from "react";
import SmartInputProps from "interfaces/SmartInputInterface";
import AutocompleteInput from "./AutocompleteInput";
import { Box, Grid, FormHelperText } from "@material-ui/core";

type Value = {
  month: { title: string; value: string };
  date: { title: string; value: string };
  year: { title: string; value: string };
} | null;

const monthNames = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const getDateOptions = () => {
  let options = [];
  for (let i = 1; i <= 31; i++) {
    options.push({
      title: i.toString(),
      value: i.toString(),
    });
  }
  return options;
};

const getMonthOptions = (monthNames: string[]) => {
  return monthNames.map((month, index) => {
    return {
      title: month.toString(),
      value: (index + 1).toString(),
    };
  });
};

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const endYear = currentYear - 150;
  let options = [];
  for (let i = currentYear; i >= endYear; i--) {
    options.push({
      title: i.toString(),
      value: i.toString(),
    });
  }
  return options;
};

const dateOptions = getDateOptions();
const monthOptions = getMonthOptions(monthNames);
const yearOptions = getYearOptions();

export default function BirthDayInput(props: SmartInputProps) {
  const { input, inputId, handleInputs } = props;
  const value = input.value as Value;

  const handleSeparateInputs = (event: any) => {
    let newDate = value ? value.date : null;
    let newMonth = value ? value.month : null;
    let newYear = value ? value.year : null;

    const name = event.target.name;
    const newValue = event.target.value;

    if (value === null) {
      handleInputs({
        target: { name: inputId, value: "" },
      });

      return;
    }

    switch (name) {
      case "date":
        newDate = newValue === null ? null : newValue;
        break;
      case "month":
        newMonth = newValue === null ? null : newValue;
        break;
      case "year":
        newYear = newValue === null ? null : newValue;
        break;
    }

    handleInputs({
      target: {
        name: inputId,
        value: { year: newYear, month: newMonth, date: newDate },
      },
    });
  };

  let monthValue = null;
  let dateValue = null;
  let yearValue = null;

  if (value !== null) {
    monthValue = value.month ? value.month : null;
    dateValue = value.date ? value.date : null;
    yearValue = value.year ? value.year : null;
  }

  return (
    <Box>
      <Box mb={1}>{input.label}</Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <AutocompleteInput
            textFieldProps={{
              error: input.error,
              label: "Month",
            }}
            options={monthOptions}
            onChange={handleSeparateInputs}
            name="month"
            value={monthValue}
            disabled={input.disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteInput
            textFieldProps={{
              error: input.error,
              label: "Date",
            }}
            name="date"
            options={dateOptions}
            onChange={handleSeparateInputs}
            value={dateValue}
            disabled={input.disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteInput
            textFieldProps={{
              error: input.error,
              label: "Year",
            }}
            options={yearOptions}
            onChange={handleSeparateInputs}
            name="year"
            value={yearValue}
            disabled={input.disabled}
          />
        </Grid>
      </Grid>
      <FormHelperText variant={"outlined"} error={input.error}>
        {input.errorMessage}
      </FormHelperText>
    </Box>
  );
}
