import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const helpRequestForm: InputByHashConfigInterface = {
  inputsById: ["msgSubject", "msgBody"],

  inputsByHash: {
    msgSubject: {
      label: "Subject",
      type: "autocomplete",
      required: true,
      resource: "countries",
      inputProps: { freeSolo: true },
      autocompleteTypeOptions: {
        resource: "msgs/preset",
        optionAccessor: (d) => {
          return {
            title: d.presetSubject,
            value: d.id,
            presetBody: d.presetBody,
          };
        },
      },
      relativeAutoFillInputs: ["msgBody"],
      submitValueAccessor: (d) => {
        return d.presetBody ? d.presetBody : d;
      },
    },
    msgBody: {
      type: "text",
      required: true,
      label: "Type message here",
      subType: "multiline",
      autoFillAccessor: (d) => {
        if (d && d.hasOwnProperty("presetBody")) {
          return d.presetBody;
        } else {
          return false;
        }
      },
      extraFields: {
        rows: 4,
      },
    },
  },
};

export default helpRequestForm;
