import React from "react";
import BaseModal from "components/modals/BaseModal";
import { Box, IconButton, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CloseThinIcon, DownloadIcon } from "components/icons";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import { fade } from "@material-ui/core/styles/colorManipulator";

interface AttachmentImagePreviewModalProps {
  imageUrl: undefined | string;
  open: boolean;
  closeModal: () => void;
  imageUploadingError: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootWrap: {
      borderRadius: "4px",
      margin: "auto",
      width: "100%",
      height: "100%",
      position: "relative",
      padding: "10px 10px 95px",
      backgroundColor: "#fff",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeButton: {
      position: "absolute",
      top: 14,
      right: 14,
      zIndex: 2,
      background: "white",
      boxShadow: `1px 1px 8px ${theme.palette.primary2.main}`,

      "&:hover": {
        background: "white",
      },
    },
    imageWrap: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    controls: {
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      margin: "15px 0 0",
      display: "flex",
      padding: "15px",
      justifyContent: "flex-end",
      borderTop: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
      "& > div": {
        "& > *": {
          margin: "0 20px",
        },
      },
    },
  })
);

const AttachmentImagePreviewModal = ({
  open,
  closeModal,
  imageUrl = undefined,
  imageUploadingError = false,
}: AttachmentImagePreviewModalProps) => {
  const classes = useStyles();

  return (
    <BaseModal open={open} onClose={closeModal} fullSize={true}>
      <Box className={classes.rootWrap}>
        <IconButton
          className={classes.closeButton}
          component="span"
          onClick={() => {
            closeModal();
          }}
        >
          <CloseThinIcon fontSize={"inherit"} />
        </IconButton>

        <Box className={classes.imageWrap}>
          {imageUploadingError && (
            <Box width="100vw" maxWidth="400px">
              <Box p="20px 0" textAlign="center">
                <Typography variant="h3">
                  Upload image preview failed
                </Typography>
              </Box>
            </Box>
          )}
          {imageUrl && (
            <img
              className={classes.image}
              src={imageUrl}
              alt="Attachment preview"
            />
          )}
        </Box>

        <Box className={classes.controls}>
          <Box margin="0 -20px" textAlign="right">
            <SubmitButton customIcon={<DownloadIcon />} href={imageUrl}>
              Download
            </SubmitButton>
          </Box>
        </Box>
      </Box>
    </BaseModal>
  );
};

export default AttachmentImagePreviewModal;
