import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { PaperProps } from "@material-ui/core/Paper";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Typography, Box } from "@material-ui/core";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

type ColorClasses = "colorPrimary" | "colorPrimary2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrap: {
      borderRadius: "50%",
      width: "34px",
      height: "34px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 7px 0 0",
    },
    title: {},
    colorPrimary: {
      "& $iconWrap": {
        background: fade(theme.palette.primary.main, 0.1),
      },
      "& $title": {
        color: theme.palette.primary.main
      },
    },
    colorPrimary2: {
      "& $iconWrap": {
        background: fade(theme.palette.primary2.main, 0.1),
      },
      "& $title": {
        color: theme.palette.primary2.main
      },
    },
  })
);

export default function PaperWithTitle({
  paperProps,
  children,
  icon,
  title,
  controls,
  color = "primary",
}: {
  paperProps?: PaperProps;
  children: JSX.Element[] | JSX.Element | string;
  controls?: JSX.Element[] | JSX.Element | string;
  icon?: JSX.Element;
  title?: string;
  color?: "primary" | "primary2";
}) {
  const classes = useStyles();

  const colorClass = `color${capitalizeFirstLetter(color)}` as ColorClasses;

  return (
    <BasePaper {...paperProps}>
      <Box className={classes[colorClass]}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant={"h3"} className={classes.title}>
            <Box
              fontWeight={600}
              fontSize={18}
              display="flex"
              alignItems="center"
              component="span"
            >
              {icon && <span className={classes.iconWrap}>{icon}</span>}
              <span>{title}</span>
            </Box>
          </Typography>
          <Box>{controls}</Box>
        </Box>
        <Box marginTop={icon && title ? { xs: 3, sm: 2 } : {}}>{children}</Box>
      </Box>
    </BasePaper>
  );
}
