import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ArrowLeftBoldIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.2,10.2H9L13.3,6c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0l-7.2,7.2c-0.2,0.2-0.3,0.4-0.4,0.6
        C3,11.5,3,11.8,3,12c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.6l7.2,7.2c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5
        c0.7-0.7,0.7-1.8,0-2.5L9,13.8h10.2c1,0,1.8-0.8,1.8-1.8S20.2,10.2,19.2,10.2z"
      />
    </SvgIcon>
  );
}