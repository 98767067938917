import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function CheckboxIndeterminateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.6,3H6.4C4.5,3,3,4.5,3,6.4v11.2C3,19.5,4.5,21,6.4,21h11.2c1.9,0,3.4-1.5,3.4-3.4V6.4C21,4.5,19.5,3,17.6,3
        z M15.4,12.6c0,0.3-0.3,0.6-0.6,0.6H9.2c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h5.6c0.3,0,0.6,0.3,0.6,0.6V12.6z"
      />
    </SvgIcon>
  );
}



