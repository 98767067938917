import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function MaleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.8,10.5c-2.5,2.5-2.4,6.6,0.3,9c2.3,2,5.7,2,8,0.1c2.7-2.2,2.9-6.1,0.8-8.6l5.8-5.8v3.1
        c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6V3.8C21,3.3,20.6,3,20.2,3h-4.4c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h3.1
        l-5.8,5.9C10.7,8.1,7.1,8.2,4.8,10.5z M12.7,18.4c-1.9,1.9-5.1,1.9-7,0s-1.9-5,0-7c1.9-1.9,5.1-1.9,7,0
        C14.6,13.3,14.6,16.4,12.7,18.4z"
      />
    </SvgIcon>
  );
}
