export default function getUniqFromArrayOfObjectsWithDates(
  objects: {}[],
  key: string,
  needle: 'year' | 'month' | 'day',
) {
  const availableValues = objects.map((item: any) => {
    const date = new Date(item[key]);

    const response = {
      year: () => date.getFullYear(),
      month: () => date.getMonth(),
      day: () => date.getDay(),
    };

    return response[needle]();
  });

  return availableValues.filter((v, i, a) => a.indexOf(v) === i);
}
