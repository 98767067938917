import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { BaseHeaderTableCellProps } from "components/uiComponents/table/Interfaces";
import { KeyboardArrowDownIcon } from "components/icons";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const useTableSortLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: "11px",
      margin: "0 0 3px 3px",
    },
  })
);

export default function HeaderTableCell({
  orderBy,
  column,
  order,
  tableCellProps,
  setOrderBy,
}: BaseHeaderTableCellProps) {
  const classesTableSortLabel = useTableSortLabelStyles();

  return (
    <TableCell
      {...tableCellProps}
      key={column.id}
      align={column.type === "number" ? "right" : "left"}
    >
      <TableSortLabel
        active={orderBy === column.id}
        classes={classesTableSortLabel}
        direction={orderBy === column.id ? order : "asc"}
        IconComponent={KeyboardArrowDownIcon}
        onClick={() => {
          setOrderBy(column.id);
        }}
      >
        {column.label}
      </TableSortLabel>
    </TableCell>
  );
}
