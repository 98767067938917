import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Footer from "components/templateParts/Footer";
import HeaderAppBar from "components/templateParts/HeaderAppBar";
import { Box } from "@material-ui/core";
import Div100vh from "react-div-100vh";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);

export default function MainLayout({ children }: { children: JSX.Element }) {
  const classes = useStyles();

  return (
    <Div100vh>
      <div className={classes.root}>
        <React.Fragment>
          <Box>
            <HeaderAppBar />
            <Box>{children}</Box>
          </Box>
          <Footer />
        </React.Fragment>
      </div>
    </Div100vh>
  );
}
