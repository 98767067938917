import React from "react";
import {
  currentPassword,
  newPassword,
  newPasswordConfirm,
} from "config/inputs";
import history from "appHistory";
import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import TextButton from "components/uiComponents/buttons/TextButton";
import { FORGOT_PASSWORD_PAGE_PATH } from "config/routes";

const loginFormConfig: InputByHashConfigInterface = {
  inputsById: ["username", "password"],

  inputsByHash: {
    username: {
      label: "User Name",
      autoComplete: "username",
      required: true,
    },
    password: {
      type: "password",
      label: "Password",
      autoComplete: "current-password",
      required: true,
      endAdornment: (
        <TextButton
          onClick={() => {
            history.push(FORGOT_PASSWORD_PAGE_PATH);
          }}
        >
          Forgot ?
        </TextButton>
      ),
    },
  },
};

const verifyCodeFormConfig: InputByHashConfigInterface = {
  inputsById: ["code"],

  inputsByHash: {
    code: {
      label: "Authentication Code",
    },
  },
};

const newPasswordFormConfig: InputByHashConfigInterface = {
  inputsById: ["newPwd", "newPwdConfirm"],

  inputsByHash: {
    newPwd: newPassword,
    newPwdConfirm: newPasswordConfirm,
  },
};

const changePasswordFormConfig: InputByHashConfigInterface = {
  inputsById: ["currentPwd", "newPwd", "newPwdConfirm"],

  inputsByHash: {
    currentPwd: currentPassword,
    newPwd: newPassword,
    newPwdConfirm: newPasswordConfirm,
  },
};

const newPasswordWithUserNameFormConfig: InputByHashConfigInterface = {
  inputsById: ["userName", "newPwd", "newPwdConfirm"],

  inputsByHash: {
    userName: {
      label: "User Name",
      autoComplete: "username",
      required: true,
    },
    newPwd: newPassword,
    newPwdConfirm: newPasswordConfirm,
  },
};

export {
  loginFormConfig,
  verifyCodeFormConfig,
  newPasswordFormConfig,
  newPasswordWithUserNameFormConfig,
  changePasswordFormConfig,
};
