import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import { fade } from "@material-ui/core";
import { NavLink as RouterNavLink } from "react-router-dom";

interface AppBarMenuItemProps extends ButtonProps {
  disabled?: boolean;
  showSpinner?: boolean;
  to: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "13px",
      fontWeight: 600,
      padding: "3px 9px",
      borderRadius: 18,
      margin: theme.spacing(1),
      textTransform: "none",
      color: theme.palette.primary2.main,
      borderColor: "transparent",
      "&:hover": {
        color: theme.palette.primary.main,
        borderColor: fade(theme.palette.primary.main, 0.4),
      },

      "&.active": {
        color: theme.palette.primary.main,
        borderColor: fade(theme.palette.primary.main, 0.4),
      },
    },
  })
);

const AppBarMenuLink = React.forwardRef<HTMLButtonElement, AppBarMenuItemProps>(
  ({ children, disabled = false, showSpinner = false, to, ...rest }, ref) => {
    const classes = useStyles();
    return (
      <Button
        variant="outlined"
        color="primary"
        className={clsx({
          [classes.root]: true,
        })}
        component={RouterNavLink}
        to={to}
        exact
      >
        {children}
      </Button>
    );
  }
);

export default AppBarMenuLink;
