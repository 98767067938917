import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { useAuthCenter } from "AuthCenter";
import useModal from "hooks/useModal";
import AddPostModal, { AddPostModalRef } from "components/modals/AddPostModal";
import helpRequestForm from "config/customer/helpRequestForm";
import { enhanceInputByHashConfig } from "utils";
import IconButton from "components/uiComponents/buttons/IconButton";
import { PlaneIcon } from "components/icons";
import usePostProvider from "hooks/usePostProvider";
import { useSnackbar } from "notistack";
import useProvider from "hooks/useProvider";
import { GET_LIST } from "providers/dataProvider";
import MessagesPageComponent from "components/pages/customer/MessagePage/MessagesPageComponent";
import { find, groupBy, isEmpty } from "lodash";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useUrlQueryState from "hooks/useUrlQueryState";
import useSort from "hooks/useSort";
import StyledFileInput from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import { useFileInput } from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import useUploader from "hooks/useUploader";

export default function MessagePage() {
  const [modalIsOpen, openModal, closeModal, modalId] = useModal();
  const authCenter = useAuthCenter();
  const addPostModalRef = useRef<AddPostModalRef>(null);
  const userId = authCenter.access.userId;
  const isMobile = useMediaQuery("(max-width:600px)");

  const { postData, dataLoading: createMessageInProgress } = usePostProvider(
    "post",
    `threads/new`
  );

  const [threadListSort, handleSetNewThreadListSort] = useSort({
    urlKey: "threadListSort",
  });

  const [queryFilter, setQueryFilter] = useUrlQueryState<{
    [index: string]: any;
  }>("threadFilter", {
    isArchived: 0,
  });

  const { postData: readThread } = usePostProvider("post");
  const {
    postData: archiveThread,
    dataLoading: archiveInProgress,
  } = usePostProvider("delete");

  const {
    postData: followUpThread,
    dataLoading: followUpThreadInProgress,
  } = usePostProvider("post");

  const { enqueueSnackbar } = useSnackbar();
  const [selectedSubjectId, setSelectedSubjectId] = useState<null | string>(
    null
  );
  const [selectedSubject, setSelectedSubject] = useState<null | {
    [index: string]: any;
  }>(null);

  const [
    threadMessagesGroupedByDate,
    setThreadMessagesGroupedByDate,
  ] = useState<{
    [index: string]: any;
  }>([]);

  const {
    data: threadMessages,
    loadData: loadTreadMessages,
    dataLoading: threadMessagesLoading,
  } = useProvider(
    GET_LIST,
    `threads/${selectedSubjectId}/msgs`,
    {
      sort: {
        field: "msgDate",
        order: "asc",
      },
    },
    onThreadMessagesLoaded,
    [selectedSubjectId]
  ) as {
    data: { toUserId: string }[];
    loadData: () => void;
    dataLoading: boolean;
  };

  const {
    data: threadList,
    loadData: loadThreadList,
    dataLoading: threadDataLoading,
    updateCurrentData: updateThreadCurrentData,
  } = useProvider(GET_LIST, `threads`, {
    filter: queryFilter,
    pagination: { perPage: -1 },
    sort: {
      field: threadListSort ? threadListSort[0] : null,
      order: threadListSort ? threadListSort[1] : null,
    },
  });

  const { files, setFiles, inputKey, clearInputFiles } = useFileInput({
    maxFileSize: 10 * 1024 * 1024,
  });

  const { uploadFiles, dataLoading: uploadingFilesInProgress } = useUploader();

  const inputsByHashWithActions = enhanceInputByHashConfig(
    helpRequestForm.inputsByHash,
    "msgBody",
    "endAdornment",
    <Box top={"17px"} left={"3px"} position={"relative"}>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          addPostModalRef?.current?.handleSubmit(event);
        }}
      >
        <PlaneIcon />
      </IconButton>
    </Box>
  );

  function onThreadMessagesLoaded(data: { [index: string]: any }[]) {
    const groups = groupBy(data, function (item) {
      return moment(item.msgDate).startOf("day").format();
    });
    setThreadMessagesGroupedByDate(groups);
  }

  const onSubmitValidForm = async (data: any) => {
    try {
      const responseData = (await postData(null, {
        data,
      })) as any;

      await uploadFiles(
        `msgs/${responseData.data.msgId}/upload`,
        `msgs/${responseData.data.msgId}/attach`,
        files
      );

      clearInputFiles();
      setFiles([]);

      closeModal();
      loadThreadList();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const onSubmitOkNewReply = async (data: any) => {
    loadTreadMessages();
  };

  const onClickArchive = async () => {
    await archiveThread(`threads/bulk_archive?id=${selectedSubjectId}`);
    updateThreadCurrentData(
      (item) => item.id === selectedSubjectId,
      () => {
        return { isArchived: 1 };
      }
    );
    // loadThreadList();
  };

  const onClickFollowUp = async () => {
    await followUpThread(`threads/${selectedSubjectId}/follow_up`);
    updateThreadCurrentData(
      (item) => item.id === selectedSubjectId,
      (item) => {
        return { isFollowUp: item.isFollowUp === 1 ? 0 : 1 };
      }
    );
    // loadThreadList();
  };

  const handleSelectSubject = async (subject: any) => {
    setSelectedSubjectId(subject.id);
    setSelectedSubject(subject);
    openModal("threadMessageList");
    if (subject.isRead === 0) {
      await readThread(`threads/${subject.id}/read`);
      updateThreadCurrentData(
        (item) => item.id === subject.id,
        () => {
          return { isRead: 1 };
        }
      );
    }
  };

  const handleFilter = (filter: { [index: string]: any }) => {
    setQueryFilter(filter);
  };

  useEffect(() => {
    const selectedItemExistInNewList =
      selectedSubjectId &&
      find(threadList, (item: any) => item.id === selectedSubjectId);

    if (selectedItemExistInNewList) {
      setSelectedSubject(selectedItemExistInNewList);
    } else {
      setSelectedSubjectId(null);
      setSelectedSubject(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadList, selectedSubjectId]);

  return (
    <React.Fragment>
      <AddPostModal
        ref={addPostModalRef}
        modalIsOpen={modalIsOpen && modalId === "newThread"}
        closeModal={closeModal}
        title={"Help Request"}
        inputsById={helpRequestForm.inputsById}
        inputsByHash={inputsByHashWithActions}
        showControls={false}
        onSubmitValidForm={onSubmitValidForm}
        isDataLoading={uploadingFilesInProgress || createMessageInProgress}
        afterContent={
          <Box>
            <StyledFileInput
              inputKey={inputKey}
              files={files}
              setFiles={setFiles}
              clearInputFiles={clearInputFiles}
            />
          </Box>
        }
      />

      <MessagesPageComponent
        threadList={threadList}
        selectedSubjectId={selectedSubjectId}
        selectedSubjectUserName={
          selectedSubject
            ? `${selectedSubject.fromFirstName} ${selectedSubject.fromlastName}`
            : ""
        }
        selectedSubjectTitle={selectedSubject ? selectedSubject.msgSubject : ""}
        handleSelectSubject={handleSelectSubject}
        threadDataLoading={threadDataLoading}
        openModal={openModal}
        threadMessages={threadMessages}
        userId={userId}
        onSubmitOkNewReply={onSubmitOkNewReply}
        threadMessagesLoading={threadMessagesLoading}
        onClickArchive={onClickArchive}
        archiveInProgress={archiveInProgress}
        selectedThreadArhived={
          selectedSubject ? selectedSubject.isArchived === 1 : false
        }
        isMobile={isMobile}
        onFilterChange={handleFilter}
        onClickFollowUp={onClickFollowUp}
        followUpInProgress={followUpThreadInProgress}
        isSelectedThreadFollowUp={
          selectedSubject ? selectedSubject.isFollowUp === 1 : false
        }
        threadMessagesGroupedByDate={threadMessagesGroupedByDate}
        threadMessageListModalIsOpen={
          modalIsOpen && modalId === "threadMessageList"
        }
        closeModal={closeModal}
        onClickRefreshThreads={loadThreadList}
        activeFilter={!isEmpty(queryFilter)}
        threadListSort={threadListSort}
        handleSetNewThreadListSort={handleSetNewThreadListSort}
      />
    </React.Fragment>
  );
}
