import React from "react";
import useForm from "hooks/useForm";
import { useHistory } from "react-router-dom";
import { getPatientEditFormInputByHashConfig } from "config/customer/patientEditForm";
import InputsList from "components/uiComponents/inputsList/InputsList";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import usePostProvider from "hooks/usePostProvider";
import useDeepEffect from "hooks/useDeepEffect";
import TextButton from "components/uiComponents/buttons/TextButton";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyIcon, CheckedIcon } from "components/icons";
import ChangePasswordModal from "components/modals/ChangePasswordModal";
import useModal from "hooks/useModal";
import { useAuthCenter } from "AuthCenter";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";
import { useSnackbar } from "notistack";
import { useStore } from "context/Store";
import { Link } from "react-router-dom";
import { canByRoles } from "utils/permissions";
import { userRoles } from "config/userRoles";
import {
  CUSTOMER_MESSAGES_PAGE_PATH,
  ADMIN_MESSAGES_PAGE_PATH, ADMIN_ROOT_PATH, CUSTOMER_ROOT_PATH,
} from "config/routes";

const useStyles = makeStyles((theme) => ({
  controls: {
    borderTop: "1px solid #e8e8e8",
    textAlign: "right",
    padding: "31px 0 0",
    margin: "81px 0 0",

    "& button": {
      margin: "0 20px",
    },
  },
  form: {
    position: "relative",
  },
}));

export default function PersonalInfoForm() {
  const patientEditFormConfig = getPatientEditFormInputByHashConfig();
  const history = useHistory();

  const {
    inputsById,
    inputsByHash,
    handleInputs,
    clearAllInputs,
    setInitValues,
    getSubmitData,
    isFormValid,
    resetErrors,
  } = useForm({
    inputsById: patientEditFormConfig.inputsById,
    inputsByHash: patientEditFormConfig.inputsByHash,
    type: "edit",
  });

  const { enqueueSnackbar } = useSnackbar();
  const authCenter = useAuthCenter();
  const [modalIsOpen, openModal, closeModal] = useModal();
  const classes = useStyles();
  const userId = authCenter.access.userId;
  const { userProvider } = useStore();
  const { data: initData, loadData } = userProvider;

  useDeepEffect(() => {
    if (initData) {
      setInitValues(initData);
    }
  }, [initData]);

  const { postData, dataLoading } = usePostProvider(
    "put",
    `mfa/profile/${userId}`
  );

  const handleSubmit = async (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    if (!isFormValid()) return;
    resetErrors();
    try {
      const data = (await postData(null, { data: getSubmitData() })) as {
        message: string;
      };
      enqueueSnackbar({ message: data.message });
      loadData();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const handleOpenModal = async (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    openModal();
  };

  const revertChanges = () => {
    clearAllInputs();
    if (initData) {
      setInitValues(initData);
    }
    const frontPagePath = canByRoles([userRoles.admin]) ? ADMIN_ROOT_PATH : CUSTOMER_ROOT_PATH;
    history.push(frontPagePath);
  };

  return (
    <React.Fragment>
      <form className={classes.form}>
        <Box pt="20px">
          <Typography variant="h4">
            Personal information can not be changed. If you need to change any
            personal information, please open a ticket in the{" "}
            <Link
              to={
                canByRoles([userRoles.customer])
                  ? CUSTOMER_MESSAGES_PAGE_PATH
                  : ADMIN_MESSAGES_PAGE_PATH
              }
            >
              Message Portal
            </Link>
            .
          </Typography>
        </Box>
        {!initData && <CircularProgressShadow />}
        <InputsList
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputWrapStyles={`
            width: 50%;
            @media(max-width: 600px) {width: 100%};
          `}
        />

        <Box p={"30px 0 0"}>
          <SubmitButton
            onClick={handleOpenModal}
            customIcon={<KeyIcon />}
            colorTheme={"light"}
            type={"button"}
          >
            Change Password
          </SubmitButton>
        </Box>

        <Box className={classes.controls}>
          <Box m={"0 -20px"}>
            <TextButton onClick={revertChanges}>Cancel</TextButton>

            <SubmitButton
              showSpinner={dataLoading}
              onClick={handleSubmit}
              customIcon={<CheckedIcon />}
            >
              Save Changes
            </SubmitButton>
          </Box>
        </Box>
      </form>

      <ChangePasswordModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </React.Fragment>
  );
}
