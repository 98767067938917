import React, { useState, useEffect } from "react";
import BaseModal, { BaseModalProps } from "components/modals/BaseModal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BasePaper from "components/uiComponents/papers/BasePaper";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import { CheckedIcon } from "components/icons";
import { fade } from "@material-ui/core/styles/colorManipulator";
import usePostProvider from "hooks/usePostProvider";
import { useAuthCenter } from "AuthCenter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        "&.modal-open": {
          "& #root": {
            filter: "blur(4px) grayscale(1)",
          },
        },
      },
    },
    formControls: {
      margin: "20px 0 0",
      display: "flex",
      padding: "28px 0 0",
      justifyContent: "flex-end",
      borderTop: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
      "& > div": {
        "& > *": {
          margin: "0 20px",
        },
      },
    },
  })
);

export default function TermsOfUseModal({
  open,
  closeModal,
}: BaseModalProps & {
  closeModal: () => void;
}) {
  const classes = useStyles();
  const [agree, setAgree] = useState(false);
  const authCenter = useAuthCenter();

  const { postData, dataLoading } = usePostProvider(
    "post",
    `users/${authCenter.access.userId}/agree`
  );

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  const handleSubmit = async () => {
    try {
      await postData(null, { data: {} });
      closeModal();
      authCenter.handleSubmitIsShowTerm();
    } catch (e) {}
  };

  return (
    <BaseModal open={open} onClose={() => {}}>
      <Box maxWidth={"571px"} margin={"auto"} width={"100%"}>
        <BasePaper>
          <Box padding="20px">
            <Box>
              <Typography variant={"h2"}>
                Please read and agree with our
                <Box>
                  <Link
                    underline={"always"}
                    target={'blank'}
                    href={
                      "/terms/ppTermsOfUse.pdf"
                    }
                  >
                    Terms and Conditions
                  </Link>
                </Box>
              </Typography>
            </Box>

            <Box margin={"20px 0 0"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agree}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="I agree with Terms and Conditions"
              />
            </Box>

            <Box className={classes.formControls}>
              <Box margin="0 -20px">
                <SubmitButton
                  customIcon={<CheckedIcon />}
                  onClick={handleSubmit}
                  disabled={!agree}
                  showSpinner={dataLoading}
                >
                  Confirm
                </SubmitButton>
              </Box>
            </Box>
          </Box>
        </BasePaper>
      </Box>
    </BaseModal>
  );
}
