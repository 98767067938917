import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function FemaleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17,7.8c0-2.9-2.4-5.2-5.3-5C9.2,2.9,7.2,4.9,7,7.3c-0.2,2.8,1.8,5.2,4.5,5.5v4.1h-2c-0.3,0-0.5,0.2-0.5,0.5
        c0,0.3,0.2,0.5,0.5,0.5h2v2.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-2.4h2c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5h-2
        v-4.1C15,12.5,17,10.4,17,7.8z M8,7.8c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C9.8,11.8,8,10,8,7.8z"
      />
    </SvgIcon>
  );
}
