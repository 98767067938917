import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import birthdayInputConfig from "config/inputs/birthdayInputConfig";
import genderInputConfig from "config/inputs/genderInputConfig";

const signUpForm: InputByHashConfigInterface = {
  inputsById: [
    "patientFirstName",
    "patientLastName",
    "patientGenderCode",
    "patientDob",
  ],

  inputsByHash: {
    patientFirstName: {
      label: "First Name",
      required: true,
    },
    patientLastName: {
      label: "Last Name",
      required: true,
    },
    patientGenderCode: genderInputConfig,
    patientDob: birthdayInputConfig,
  },
};

export default signUpForm;
