import React, { useRef, forwardRef, useImperativeHandle } from "react";
import BaseModal from "components/modals/BaseModal";
import TextButton from "components/uiComponents/buttons/TextButton";
import AuthFormLayout, {
  Ref,
  AuthFormLayoutProps,
} from "components/uiComponents/forms/AuthFormLayout";
import AuthPaper from "components/uiComponents/papers/AuthPaper";
import { Box, IconButton } from "@material-ui/core";
import { useFormMesssageBar } from "components/uiComponents/forms/FormMessageBar";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CloseThinIcon } from "components/icons";

interface AddPostModalProps extends AuthFormLayoutProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  title: string;
  submitLabel?: string;
  isDataLoading?: boolean;
  initData?: object;
  afterContent?: JSX.Element | null;
}

export interface AddPostModalRef {
  handleSubmit(event: React.MouseEvent<unknown>): void;
  setInitValues(initData: { [index: string]: any }): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      top: 14,
      right: 6,
      zIndex: 2,
    },
  })
);

const AddPostModal = forwardRef<AddPostModalRef, AddPostModalProps>(
  (
    {
      modalIsOpen,
      closeModal,
      title = "",
      inputsById,
      inputsByHash,
      showControls,
      onSubmitValidForm,
      submitLabel = "Submit",
      isDataLoading = false,
      initData,
      afterContent = null,
    },
    ref
  ) => {
    const { title: formMessageBarTitle, children, type } = useFormMesssageBar();
    const authFormLayoutRef = useRef<Ref>(null);
    const classes = useStyles();

    let formMessageBarProps = {
      title: formMessageBarTitle,
      children: children,
      type: type,
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: (event: React.MouseEvent<unknown>) => {
        authFormLayoutRef?.current?.handleSubmit(event);
      },
      setInitValues: (initData: { [index: string]: any }) => {
        authFormLayoutRef?.current?.setInitValues(initData);
      },
    }));

    return (
      <BaseModal open={modalIsOpen} onClose={closeModal}>
        <Box
          maxWidth={"571px"}
          margin={"auto"}
          width={"100%"}
          position={"relative"}
        >
          <IconButton
            className={classes.closeButton}
            component="span"
            onClick={closeModal}
          >
            <CloseThinIcon fontSize={"inherit"} />
          </IconButton>
          <AuthPaper
            title={title}
            formMessageBar={formMessageBarProps}
            showLogo={false}
          >
            <React.Fragment>
              <AuthFormLayout
                ref={authFormLayoutRef}
                inputsById={inputsById}
                inputsByHash={inputsByHash}
                onSubmitValidForm={onSubmitValidForm}
                backButton={
                  <TextButton onClick={closeModal}>Cancel</TextButton>
                }
                submitLabel={submitLabel}
                showControls={showControls}
                isDataLoading={isDataLoading}
                showCircularProgressShadow={isDataLoading}
                initData={initData}
                afterInputs={afterContent}
              />
            </React.Fragment>
          </AuthPaper>
        </Box>
      </BaseModal>
    );
  }
);

export default AddPostModal;
