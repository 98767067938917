import moment from 'moment-timezone';

const availableFormats = {
  date: 'MM/DD/YY',
  dateTime: 'MM/DD/YY HH:mm',
};

export default function formatDateTime(
  date: Date,
  format: 'date' | 'dateTime',
) {
  return moment(date).format(
    availableFormats[format] || availableFormats.date,
  );
}