import React, { useState } from "react";
import AuthPaper from "components/uiComponents/papers/AuthPaper";
import { Box } from "@material-ui/core";
import { useFormMesssageBar } from "components/uiComponents/forms/FormMessageBar";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";
import { newPasswordWithUserNameFormConfig } from "config/auth/authForm";
import { RouteComponentProps } from "react-router-dom";
import axios from "axios";
import config from "config";
import history from "appHistory";
import { useAuthCenter } from "AuthCenter";

interface RouterProps {
  token: string;
}
interface SignUpPageProps extends RouteComponentProps<RouterProps> {
  onLoginOk: () => void;
}

export default function ResetPasswordPage(props: SignUpPageProps) {
  const { title, children, type, setMessage } = useFormMesssageBar();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const authCenter = useAuthCenter();
  const onSubmitValidForm = async (data: { [index: string]: any }) => {
    setDataLoading(true);

    axios
      .request({
        method: "PUT",
        url: `${config.apiUrl}/mfa/confirm_pwd`,
        data: {
          verificationCode: props.match.params.token,
          ...data,
        }
      })
      .then((response) => {
        setDataLoading(false);
        authCenter.messageBar.setMessage(response.data.message)
        history.push("/login");
      })
      .catch((err) => {
        setDataLoading(false);
        setMessage(err.response.data.message, null, "error");
      });
  };

  return (
    <Box display={"flex"} minHeight={"100vh"}>
      <Box maxWidth={"571px"} margin={"auto"} width={"100%"}>
        <AuthPaper
          title={"New password"}
          formMessageBar={{
            title: title,
            children: children,
            type: type,
          }}
        >
          <AuthFormLayout
            inputsById={newPasswordWithUserNameFormConfig.inputsById}
            inputsByHash={newPasswordWithUserNameFormConfig.inputsByHash}
            onSubmitValidForm={onSubmitValidForm}
            isDataLoading={dataLoading}
            submitLabel={"Submit"}
          />
        </AuthPaper>
      </Box>
    </Box>
  );
}
