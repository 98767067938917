import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import SinglePatientContent from "components/pages/customer/SinglePatientContent";
import useGetOneProvider from "hooks/useGetOneProvider";
import usePostProvider from "hooks/usePostProvider";
import PatientModalContent from "components/modals/PatientModalContent";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import TextButton from "components/uiComponents/buttons/TextButton";
import { ArrowLeftBoldIcon } from "components/icons";
import { fade } from "@material-ui/core";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import RelatedPeopleTable from "components/pages/admin/pageComponents/RelatedPeopleTable";
import { useSnackbar } from "notistack";

interface ParamTypes {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: "1px solid #e8e8e8",
    padding: "0 0 18px",
    margin: "0 0 20px",
  },
  buttonsWrap: {
    margin: "-10px -5px 0",
    "& button": {
      margin: "10px 5px 0",
    },
  },
}));

export default function PatientsSinglePage() {
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const { data: patientDetail } = useGetOneProvider(`patients/${id}/latest`);
  const { data: patientInfo, dataLoaded: patientDataLoaded } = useGetOneProvider(`patients/${id}`);
  const [isInvitationSent, setIsInvitationSent] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { postData: sendInvitation, dataLoading } = usePostProvider(
    "post",
    `patients/${id}/invite`
  );

  useEffect(() => {
    if (patientDataLoaded && !patientInfo) {
      history.push('/404')
    }
  }, [patientDataLoaded, patientInfo, history]);


  const patientFullName = patientInfo
    ? `${patientInfo.patientFirstName}, ${patientInfo.patientLastName}`
    : "";

  if (!patientInfo) {
    return null;
  }

  const pagtientId = parseInt(id);

  const handleSubmitReSendInvitation = async () => {
    try {
      await sendInvitation(null, { data: {} });
      setIsInvitationSent(true);
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Box className={classes.header}>
          <Box m={"0 -8px 0"}>
            <TextButton
              onClick={() => {
                history.goBack();
              }}
            >
              <Box
                fontSize={12}
                color={fade("#445369", 0.4)}
                top={"1px"}
                position="relative"
              >
                <ArrowLeftBoldIcon fontSize="inherit" />
              </Box>
              Back
            </TextButton>
          </Box>
          <Box
            display="flex"
            flexWrap={"wrap"}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box mr={"10px"}>
              <Typography variant={"h1"}>{patientFullName}</Typography>
            </Box>
            <Box className={classes.buttonsWrap}>
              {patientInfo.patientRaceCode !== "CMP" && (
                <SubmitButton
                  disableEndIcon={true}
                  onClick={handleSubmitReSendInvitation}
                  disabled={isInvitationSent}
                  showSpinner={dataLoading}
                >
                  {isInvitationSent ? 'Invitation Sent!' : 'Re-send an invitation'}
                </SubmitButton>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <Container>
        <PatientModalContent patientDetail={patientDetail} />
      </Container>

      <Box m={"20px 0 0"}>
        <SinglePatientContent patientId={pagtientId} />
      </Box>

      <Box m={"20px 0 0"}>
        <Container>
          <RelatedPeopleTable patientId={pagtientId} patientFirstName={patientInfo.patientFirstName}/>
        </Container>
      </Box>
    </React.Fragment>
  );
}
