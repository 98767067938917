import React, { useEffect, useRef } from "react";
import {
  ClickAwayListener,
  Box,
  MenuList,
  Popper,
  Paper,
  Grow,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import AppBarMenuItem from "components/uiComponents/buttons/AppBarMenuItem";
import { KeyboardArrowDownIcon } from "components/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

const usePaperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      padding: "0",
      boxShadow: "0px 6px 26px -8px rgba(0,0,0,0.2) !important",
      minWidth: "150px",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 15px",
      },
    },
    rounded: {
      borderRadius: "12px",
    },
  })
);

export default function AppBarSubMenuItem({
  children,
  label,
  disableIcon = false,
}: {
  children: JSX.Element[] | JSX.Element;
  label: string | JSX.Element;
  disableIcon?: boolean;
}) {
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <AppBarMenuItem
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          endIcon={
            !disableIcon && (
              <Box>
                <Box fontSize={10} margin={"5px 0 0 -5px"}>
                  <KeyboardArrowDownIcon fontSize={"inherit"} />
                </Box>
              </Box>
            )
          }
        >

          {label}
        </AppBarMenuItem>

        <Popper
          style={{ zIndex: 1 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement={"bottom-end"}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper classes={paperClasses}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
