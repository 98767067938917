import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "16px",
      fontWeight: 600,
      padding: 7,
      minWidth: 49,
      borderRadius: 10,
      margin: theme.spacing(1),
    },
    selected: {
      background: `${theme.palette.primary2.main} !important`,
    },
  })
);

export default function DateButton({
  onClick = () => {},
  disabled = false,
  children = "",
  isSelected,
}: {
  onClick: (event: React.MouseEvent<unknown>) => void;
  disabled?: boolean;
  isSelected: boolean;
  children: JSX.Element[] | JSX.Element | string | number;
}) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={onClick}
      className={clsx({
        [classes.selected]: isSelected,
        [classes.root]: true,
      })}
    >
      {children}
    </Button>
  );
}
