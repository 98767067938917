import { useState } from "react";
import dataProvider, {
  DataProviderOptionsInterface,
} from "providers/dataProvider";
import { useSnackbar } from "notistack";

export default function useDownloader() {
  const [isDataDownloading, setIsDataDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const downloadData = async (
    type: string,
    resource: string,
    params = {},
    dataProviderOptions?: DataProviderOptionsInterface
  ) => {
    setIsDataDownloading(true);
    try {
      const response = await dataProvider(
        type,
        resource,
        params,
        dataProviderOptions
      );

      if (response.data !== null) {
        window.open(response.data);
        enqueueSnackbar({ variant: "download", url: response.data });
      } else {
        console.error(response.message);
      }
      setIsDataDownloading(false);
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
      setIsDataDownloading(false);
    }
  };

  return [isDataDownloading, downloadData] as [
    boolean,
    (
      type: string,
      resource: string,
      params?: {},
      dataProviderOptions?: DataProviderOptionsInterface
    ) => void
  ];
}
