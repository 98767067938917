import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function CheckboxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.6,3H6.4C4.5,3,3,4.5,3,6.4v11.3C3,19.5,4.5,21,6.4,21h11.3c1.9,0,3.4-1.5,3.4-3.4V6.4C21,4.5,19.5,3,17.6,3
        z M19.9,17.6c0,1.2-1,2.3-2.3,2.3H6.4c-1.2,0-2.3-1-2.3-2.3V6.4c0-1.2,1-2.3,2.3-2.3h11.3c1.2,0,2.3,1,2.3,2.3V17.6z"
      />
    </SvgIcon>
  );
}
