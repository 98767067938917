import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function AnchorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.3,11h-2.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.8c-0.2,1.8-0.9,3.5-2.2,4.8
        c-1.3,1.3-3,2.1-4.8,2.2V8.9c1.4-0.3,2.5-1.6,2.5-3.1c0-1.7-1.4-3.2-3.2-3.2S8.8,4.1,8.8,5.8c0,1.5,1.1,2.8,2.5,3.1v10.4
        c-1.8-0.2-3.5-0.9-4.8-2.2c-1.3-1.3-2.1-3-2.2-4.8h1.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H3.7C3.3,11,3,11.3,3,11.7
        c0,2.4,0.9,4.7,2.6,6.4s4,2.6,6.4,2.6c2.4,0,4.7-0.9,6.4-2.6c1.7-1.7,2.6-4,2.6-6.4C21,11.3,20.7,11,20.3,11z M10.2,5.8
        C10.2,4.8,11,4,12,4s1.8,0.8,1.8,1.8S13,7.7,12,7.7S10.2,6.8,10.2,5.8z"
      />
    </SvgIcon>
  );
}
