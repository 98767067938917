const FORGOT_PASSWORD_PAGE_PATH = "/forgot";
const LOGIN_PAGE_PATH = "/login";

const CUSTOMER_ROOT_PATH = "/";
const CUSTOMER_PROFILE_PAGE_PATH = "/profile";
const CUSTOMER_MESSAGES_PAGE_PATH = "/messages";

const ADMIN_ROOT_PATH = "/admin/users";
const ADMIN_PAGE_PATH = "/admin";
const ADMIN_USERS_PAGE_PATH = "/admin/users";
const ADMIN_PATIENTS_PAGE_PATH = "/admin/patients";
const ADMIN_SYNC_PAGE_PATH = "/admin/sync";
const ADMIN_SINGLE_PATIENT_PAGE_BASE_PATH = "/admin/patient";
const ADMIN_PROFILE_PAGE_PATH = "/admin/profile";
const ADMIN_MESSAGES_PAGE_PATH = "/admin/messages";

export {
  FORGOT_PASSWORD_PAGE_PATH,
  LOGIN_PAGE_PATH,
  ADMIN_PAGE_PATH,
  ADMIN_USERS_PAGE_PATH,
  ADMIN_PATIENTS_PAGE_PATH,
  ADMIN_SYNC_PAGE_PATH,
  ADMIN_SINGLE_PATIENT_PAGE_BASE_PATH,
  ADMIN_ROOT_PATH,
  ADMIN_PROFILE_PAGE_PATH,
  ADMIN_MESSAGES_PAGE_PATH,
  CUSTOMER_MESSAGES_PAGE_PATH,
  CUSTOMER_PROFILE_PAGE_PATH,
  CUSTOMER_ROOT_PATH,
};
