import React from "react";
import { useAuthCenter } from "AuthCenter";
import useGetOneProvider, {
  UseGetOneProviderInterface,
} from "hooks/useGetOneProvider";

type Context = {
  userProvider: UseGetOneProviderInterface;
};

const StoreContext = React.createContext<Context>(undefined!);

const StoreProvider = ({ children }: { children: JSX.Element }) => {
  const { access } = useAuthCenter();
  const userId = access ? access.userId : null;

  const value = {
    userProvider: useGetOneProvider(
      `users/${userId}`,
      null,
      null,
      [userId]
    ),
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

const useStore = () => {
  return React.useContext(StoreContext);
};

export { StoreProvider, useStore };
