import React from "react";
import { UserIcon, UsersIcon } from "components/icons";
import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const chooseRoleConfig: InputByHashConfigInterface = {
  inputsById: ["role"],

  inputsByHash: {
    role: {
      type: "buttonRadio",
      options: [
        {
          label: "Patient Self",
          value: "patient",
          icon: <UsersIcon />,
        },
        {
          label: "Guardian",
          value: "guardian",
          icon: <UserIcon />,
        },
      ],
      value: "patient",
      required: true,
    },
  },
};

export default chooseRoleConfig;
