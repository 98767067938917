import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function DangerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,21c-5,0-9-4-9-9s4-9,9-9c5,0,9,4,9,9S17,21,12,21z M12,4.7C8,4.7,4.7,8,4.7,12c0,4,3.3,7.3,7.3,7.3
        s7.3-3.3,7.3-7.3C19.3,8,16,4.7,12,4.7z M12.8,12V8.7c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8V12c0,0.5,0.4,0.8,0.8,0.8
        S12.8,12.5,12.8,12z M12.9,15.3c0-0.5-0.4-0.8-0.8-0.8h0c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8S12.9,15.7,12.9,15.3z"
      />
    </SvgIcon>
  );
}