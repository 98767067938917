import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import birthdayInputConfig from "config/inputs/birthdayInputConfig";

const forgoPasswordFormConfig: InputByHashConfigInterface = {
  inputsById: ["userName", "userFirstName", "userLastName", "userDob"],

  inputsByHash: {
    userName: {
      label: "User Name",
      autoComplete: "username",
      required: true,
    },
    userFirstName: {
      label: "First Name",
      autoComplete: "username",
      required: true,
    },
    userLastName: {
      label: "Last Name",
      autoComplete: "username",
      required: true,
    },
    userDob: birthdayInputConfig,
  },
};

export default forgoPasswordFormConfig;
