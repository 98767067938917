import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { BasePaper } from "components/uiComponents/papers";

const ErrorPage = ({ title }: { title: string }) => (
  <Container>
    <BasePaper>
      <Box minHeight={"100px"} display={"flex"} alignItems={"center"}>
        <Typography variant={"h1"}>{title}</Typography>
      </Box>
    </BasePaper>
  </Container>
);

export default ErrorPage;
