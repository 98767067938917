import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme: Theme) => {
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = fade(theme.palette.primary2.main, 0.4);
  return createStyles({
    root: {
      display: "flex",
      height: "6px",
      position: "relative",
      background: `repeating-linear-gradient(90deg, ${primaryColor} 0px, ${primaryColor} 1px, transparent 1px, transparent 104px)`,

      "&:after": {
        content: '""',
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "3px",
        background: `repeating-linear-gradient(90deg, ${secondaryColor} 25px, ${secondaryColor} 26px, transparent 26px, transparent 52px, ${secondaryColor} 52px, ${secondaryColor} 53px, transparent 53px, transparent 79px,  ${secondaryColor} 79px, ${secondaryColor} 80px, transparent 80px, transparent 129px)`,
      },
    },
  });
});

export default function TimelineScale() {
  const classes = useStyles();
  return <Box className={classes.root}></Box>;
}
