import React, { useState } from "react";
import { loginFormConfig } from "config/auth/authForm";
import { useAuthCenter } from "AuthCenter";
import TextButton from "components/uiComponents/buttons/TextButton";
import { Typography } from "@material-ui/core";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";
import { Box } from "@material-ui/core";

export default function LoginForm({
  onLoginOk,
  onSubmitError,
}: {
  onLoginOk: (data: any) => void;
  onSubmitError: (message: string) => void;
}) {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const { cognitoLogIn, messageBar } = useAuthCenter();

  const onSubmitValidForm = async (data: any) => {
    const { username, password } = data;
    messageBar.setMessage(null, null);

    setDataLoading(true);
    try {
      const cognitoLogInResponse = await cognitoLogIn(username, password);
      if (cognitoLogInResponse.loginStatus === "loginOk") {
        onLoginOk(cognitoLogInResponse.data.data);
      }
    } catch (e) {
      onSubmitError(e.message);
      setDataLoading(false);
    }
  };

  return (
    <AuthFormLayout
      inputsById={loginFormConfig.inputsById}
      inputsByHash={loginFormConfig.inputsByHash}
      onSubmitValidForm={onSubmitValidForm}
      isDataLoading={dataLoading}
      submitLabel={"Login"}
      afterInputs={
        <Box mt="13px">
          <Typography variant={"h4"}>
            By continuing, you agree to PHD’s
            <TextButton color={"inherit"} href="/terms/ppTermsOfUse.pdf">
              Conditions of Use and Privacy Notice.
            </TextButton>
          </Typography>
        </Box>
      }
    />
  );
}
