import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

interface StyledTabProps {
  label: string;
}

const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      marginRight: theme.spacing(4),
      fontWeight: 600,
      fontSize: "18px",
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$selected": {
        color: "#1890ff",
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab {...props} />);

export default CustomTab;
