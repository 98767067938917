import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.5,21c-0.3,0-0.6-0.3-0.6-0.6v-1.9c0-0.8-0.3-1.7-0.9-2.3c-0.6-0.6-1.4-0.9-2.3-0.9H8.2
        c-0.9,0-1.7,0.3-2.3,0.9c-0.6,0.6-0.9,1.4-0.9,2.3v1.9c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6v-1.9c0-1.2,0.4-2.2,1.3-3
        c0.8-0.8,1.9-1.3,3-1.3h7.5c1.1,0,2.2,0.5,3,1.3c0.8,0.8,1.3,1.9,1.3,3v1.9C20.1,20.7,19.8,21,19.5,21z M16.3,7.3
        C16.3,4.9,14.4,3,12,3C9.6,3,7.7,4.9,7.7,7.3s1.9,4.3,4.3,4.3C14.4,11.6,16.3,9.7,16.3,7.3z M15.2,7.3c0,1.8-1.4,3.2-3.2,3.2
        S8.8,9.1,8.8,7.3s1.4-3.2,3.2-3.2S15.2,5.6,15.2,7.3z"
      />
    </SvgIcon>
  );
}
