import React, { useRef } from "react";
import AuthFormLayout, {
  Ref,
} from "components/uiComponents/forms/AuthFormLayout";
import threadReplyMessageForm from "config/customer/threadReplyMessageForm";
import { enhanceInputByHashConfig } from "utils";
import { Box } from "@material-ui/core";
import IconButton from "components/uiComponents/buttons/IconButton";
import usePostProvider from "hooks/usePostProvider";
import { PlaneIcon } from "components/icons";
import { useSnackbar } from "notistack";
import StyledFileInput from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import { useFileInput } from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import useUploader from "hooks/useUploader";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

export default function AddThreadMessageForm({
  threadId,
  userId,
  toUserId,
  onSubmitOk,
}: {
  threadId: string | null;
  userId: string;
  toUserId: string;
  onSubmitOk: (data: any) => void;
}) {
  const authFormLayoutRef = useRef<Ref>(null);
  const { enqueueSnackbar } = useSnackbar();

  const {
    files,
    setFiles,
    inputKey,
    clearInputFiles,
    isValidInput,
  } = useFileInput({ maxFileSize: 10 * 1024 * 1024 });


  const { uploadFiles, dataLoading: uploadingFilesInProgress } = useUploader();

  const { postData, dataLoading: createMessageInProgress } = usePostProvider(
    "post",
    `threads/${threadId}/reply`
  );

  const onSubmitValidForm = async (data: any) => {
    if (!isValidInput()) return;
    try {
      const responseData = (await postData(null, {
        data: {
          fromUserId: userId,
          toUserId: toUserId,
          ...data,
        },
      })) as any;
      await uploadFiles(
        `msgs/${responseData.data.msgId}/upload`,
        `msgs/${responseData.data.msgId}/attach`,
        files
      );

      clearInputFiles();
      setFiles([]);

      authFormLayoutRef?.current?.clearAllInputs();
      onSubmitOk(responseData);
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const inputsByHashWithActions = enhanceInputByHashConfig(
    threadReplyMessageForm.inputsByHash,
    "msgBody",
    "endAdornment",
    <Box top={"10px"} right={"10px"} position={"absolute"}>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          authFormLayoutRef?.current?.handleSubmit(event);
        }}
      >
        <PlaneIcon />
      </IconButton>
    </Box>
  );

  return (
    <Box position="relative">
      {(createMessageInProgress || uploadingFilesInProgress) && (
        <CircularProgressShadow />
      )}
      <AuthFormLayout
        ref={authFormLayoutRef}
        inputsById={threadReplyMessageForm.inputsById}
        inputsByHash={inputsByHashWithActions}
        onSubmitValidForm={onSubmitValidForm}
        showControls={false}
        isDataLoading={true}
        validateForm={files.length === 0}
      />
      <Box>
        <StyledFileInput
          inputKey={inputKey}
          files={files}
          setFiles={setFiles}
          clearInputFiles={clearInputFiles}
        />
      </Box>
    </Box>
  );
}
