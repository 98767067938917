import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  helperText: {
    lineHeight: 1.1,
    letterSpacing: 0,
  },
}));

export default function StyledTextField(props: TextFieldProps) {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
    />
  );
}
