import React from "react";
import AreaChart from "components/uiComponents/charts/AreaChart";
import { fade } from "@material-ui/core";
import useProvider from "hooks/useProvider";
import { GET_LIST } from "providers/dataProvider";
import { formatDateTime } from "utils";
import { Typography, Box } from "@material-ui/core";
import { BasePaper } from "components/uiComponents/papers";
import Container from "@material-ui/core/Container";

export default function HistoryChart({
  selectedPatientId,
}: {
  selectedPatientId: null | number;
}) {
  const { data } = useProvider(
    GET_LIST,
    `patients/${selectedPatientId}/serial`,
    null,
    () => {},
    [selectedPatientId]
  );

  const getData = (data: any) => {
    const filteredData = data.filter(
      (item: any) =>
        item.reportableResult !== "N/A" &&
        item.reportableResult !== null &&
        item.processData !== null
    );
    return filteredData.map((item: any) => {
      const processData = Date.parse(item.processDate);
      return [processData, item.reportableResult];
    });
  };

  const getExtraData = (data: any) => {
    return data.map((item: any) => {
      return {
        result: item.reportableResult,
        isDiabetic: item.isDiabetic === 1 ? "Yes" : "No",
        isFamilyHistory: item.isFamilyHistory === 1 ? "Yes" : "No",
        processDate: formatDateTime(item.processDate, "date"),
        reqId: item.reqId,
      };
    });
  };

  const filteredData = data.filter(
    (item: any) => item.processDate !== false && item.processDate !== null
  );

  const series = [
    {
      name: "1003 Test Result",
      data: getData(filteredData),
      extraData: getExtraData(filteredData),
    },
  ];

  const tooltip = {
    shared: false,
    intersect: true,
    custom: function ({
      seriesIndex,
      dataPointIndex,
      w,
    }: {
      seriesIndex: any;
      dataPointIndex: any;
      w: any;
    }) {
      const extraData = w.config.series[seriesIndex].extraData[dataPointIndex];
      const reqLink = `<a href="/requisitions/${extraData.reqId}/show">${extraData.reqId}</a>`;
      return `
          <div>
          <div>
                PULS Result: ${extraData.result}
                </div>
                <div>
                Diabetic: ${extraData.isDiabetic}
                </div>
                <div>
                Family HX of MI: ${extraData.isFamilyHistory}
                </div>
                <div>
                Process Date: ${extraData.processDate}
                </div>
                <div>
                     Requisition ID: ${reqLink}
                </div>
        </div>
      `;
    },
  };

  const yaxis = {
    title: {
      text: "PULS Result",
    },
    labels: {
      style: {
        colors: fade("#2C3367", 0.4),
      },
    },
  };
  const xaxis = {
    type: "datetime",
    tickPlacement: "on",
    labels: {
      style: {
        colors: fade("#2C3367", 0.6),
      },
    },
    title: {
      text: "Process Date",
    },
  };

  // console.log(data.length);
  if (data.length < 1) return null;

  return (
    <Box mt={"20px"}>
      <Container>
        <BasePaper>
          <Typography variant={"h3"}>
            <Box color="primary2.main" component="span">
              PULS Results History
            </Box>
          </Typography>
          <AreaChart
            series={series}
            options={{ tooltip: tooltip, yaxis: yaxis, xaxis: xaxis }}
          />
        </BasePaper>
      </Container>
    </Box>
  );
}
