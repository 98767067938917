import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { fade } from "@material-ui/core/styles/colorManipulator";
import clsx from "clsx";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    monthPointItem: {
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
      minHeight: "77px",

      "&:before": {
        content: '""',
        display: "block",
        left: "-1px",
        bottom: "0",
        position: "absolute",
        width: "3px",
        height: "3px",
        background: theme.palette.primary.main,
        borderRadius: "50%",
        transition: "background .5s, transform .5s",
      },
      "&:after": {
        content: '""',
        display: "block",
        left: 0,
        bottom: 0,
        position: "absolute",
        width: "1px",
        height: "40px",
        background: theme.palette.primary.main,
        transition: "background .5s",
      },

      "&:hover": {
        "&:before": {
          background: theme.palette.secondary.main,
          transform: "scale(2)",
        },
        "&:after": {
          background: theme.palette.secondary.main,
        },
        "& $monthPointItemDate": {
          background: theme.palette.secondary.main,
        },
        "& $monthPointItemMonth": {
          background: theme.palette.secondary.main,
        },
        "& $helperText": {
          opacity: 1,
          visibility: "visible",
        },
      },
    },

    monthPointItemSelected: {
      cursor: "default",
      "&:before": {
        background: theme.palette.primary2.main,
      },
      "&:after": {
        background: theme.palette.primary2.main,
      },
      "& $monthPointItemDate": {
        background: theme.palette.primary2.main,
      },
      "& $monthPointItemMonth": {
        background: theme.palette.primary2.main,
      },
    },

    monthPointItemDateWrap: {
      bottom: "40px",
      display: "flex",
      height: "38px",
      color: "white",
      fontFamily: "Josefin Sans",
      fontWeight: 600,
      lineHeight: 1,
      borderRadius: "8px 0 8px 0",
      boxShadow: "0px 3px 11px rgba(68, 83, 105, 0.24)",
    },
    monthPointItemDate: {
      background: theme.palette.primary.main,
      borderRadius: "0 0 8px 0",
      fontSize: "23px",
      padding: "9px",
      transition: "background .5s",
    },
    monthPointItemMonth: {
      background: theme.palette.primary.main,
      borderRadius: "8px 0 0 0",
      textTransform: "uppercase",
      fontSize: "14px",
      padding: "13px 16px",
      transition: "background .5s",
      borderRight: `1px solid ${fade("#fff", 0.1)}`,
    },
    helperText: {
      position: "absolute",
      left: "calc(100% + 10px)",
      top: "0",
      height: "100%",
      color: fade(theme.palette.primary2.main, 0.8),
      fontSize: "11px",
      whiteSpace: "nowrap",
      lineHeight: "130%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: ".5s",
      visibility: "hidden",
      opacity: 0,
    },
  })
);

export default function TimelineItem({
  id,
  date,
  isSelected,
  onClick,
}: {
  id: number;
  date: Date;
  isSelected?: boolean;
  onClick: (id: number) => void;
}) {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.monthPointItem]: true,
        [classes.monthPointItemSelected]: isSelected,
      })}
    >
      <Box display={"inline-block"}>
        <Box
          className={classes.monthPointItemDateWrap}
          onClick={() => (isSelected ? () => {} : onClick(id))}
        >
          <Box className={classes.helperText}>
            <Box>
              Your reports <br></br> from this date
            </Box>
          </Box>

          <Box className={classes.monthPointItemMonth}>
            {moment(date).format("MMM")}
          </Box>
          <Box className={classes.monthPointItemDate}>
            {moment(date).format("D")}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
