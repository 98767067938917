import React from "react";
import { Column } from "components/uiComponents/table/Interfaces";
import { DeleteIcon, PencilIcon } from "components/icons";

const tableConfig: Column[] = [
  {
    id: "userName",
    label: "User Name",
    mobileConfig: {
      options: {
        visibleLabel: false,
      },
      gridProps: {
        xs: 12,
      },
      valueStyles: "font-size: 15px; font-weight: 700;",
    },
  },
  {
    id: "userName",
    label: "Name",
    accessor: (d) => `${d.userFirstName} ${d.userLastName}`,
    mobileConfig: {
      gridProps: {
        xs: 12,
      },
    },
  },
  {
    id: "userRoleDesc",
    label: "Role",
    mobileConfig: {
      gridProps: {
        xs: 6,
      },
    },
  },
  {
    id: "edit",
    type: "action",
    tableCellStyles: "width: 100px;",
    actionProps: {
      label: "Edit",
      icon: <PencilIcon />,
    },
  },
  {
    id: "remove",
    type: "action",
    tableCellStyles: "width: 100px;",
    actionProps: {
      label: "Remove",
      icon: <DeleteIcon />,
      buttonVariant: "error",
    },
  },
];

export default tableConfig;
