import React from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import MessageAttachmentsList from "components/pages/customer/MessagePage/parts/MessageAttachmentsList";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "10px 0 0",
    position: "relative",
    padding: "13px 7px 12px 45px",
    background: "#fff",
    border: `1px solid ${fade("#2C3367", 0.05)}`,
    color: theme.palette.primary2.main,
  },
  rootActive: {
    background: fade(theme.palette.primary2.main, 0.05),
  },
  name: {
    fontWeight: 700,
    fontSize: "13px",
  },
  role: {
    color: fade(theme.palette.primary2.main, 0.4),
    padding: "0 0 0 14px",
    position: "relative",
    "&:before": {
      content: '""',
      display: "block",
      left: "7px",
      top: "50%",
      position: "absolute",
      width: "3px",
      height: "3px",
      background: fade(theme.palette.primary2.main, 0.4),
      borderRadius: "50%",
      transform: "translateY(-50%)",
    },
  },
  time: {
    textTransform: "uppercase",
    color: fade(theme.palette.primary2.main, 0.6),
    fontSize: "11px",
  },
  message: {
    fontSize: "13px",
  },
  attachments: {},
}));

export default function ThreadMessage({
  name,
  role,
  time,
  message,
  isActive = false,
  attachments,
}: {
  name: string;
  role: string;
  time: string;
  message: string;
  isActive?: boolean;
  attachments: any;
}) {
  const classes = useStyles();
  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.rootActive]: isActive,
      })}
    >
      <Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"}>
            <Box className={classes.name}>{name}</Box>
            <Box className={classes.role}>{role}</Box>
          </Box>
          <Box className={classes.time}>{time}</Box>
        </Box>
        <Box>
          <Box className={classes.message}>{message}</Box>
        </Box>
        {attachments && (
          <Box className={classes.attachments}>
            <MessageAttachmentsList attachments={attachments} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
