import React from "react";
import InputWrap from "components/uiComponents/inputsList/InputWrap";
import SmartInput from "components/uiComponents/inputs/SmartInput";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { HandleInputType } from "interfaces/SmartInputInterface";

interface InputsListProps {
  inputsById: string[];
  inputsByHash: any;
  inputWrapStyles?: string;
  handleInputs: HandleInputType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "0 -10px",
    },
  })
);

const InputsList = (props: InputsListProps) => {
  const {
    inputsById = [],
    inputsByHash,
    handleInputs,
    inputWrapStyles = "",
  } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {inputsById.map((id) => {
        const input = inputsByHash[id];

        const inputWrapStylesMerged = `
          ${inputWrapStyles}
          ${input.inputWrapStyles && input.inputWrapStyles}
        `
        // const hideByLoadedData =
        //   input.hasOwnProperty("visibilityByLoadedData") &&
        //   !input.visibilityByLoadedData;
        // const hideByVisibility =
        //   input.hasOwnProperty("visibility") && !input.visibility;

        // if (hideByLoadedData || hideByVisibility) {
        //   return null;
        // }

        return (
          <InputWrap key={input.id} rootStyles={inputWrapStylesMerged}>
            <SmartInput
              input={input}
              inputId={input.id}
              handleInputs={handleInputs}
            />
          </InputWrap>
        );
      })}
    </div>
  );
};

export default InputsList;
