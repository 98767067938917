import React from "react";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";
import chooseRoleConfig from "config/registration/chooseRoleConfig";

export default function ChooseRoleForm({
  onSubmitValidForm,
  backButton,
}: {
  onSubmitValidForm: (data: { [index: string]: any }) => void;
  backButton?: JSX.Element[] | JSX.Element | string;
}) {
  return (
    <AuthFormLayout
      inputsById={chooseRoleConfig.inputsById}
      inputsByHash={chooseRoleConfig.inputsByHash}
      submitLabel={"Next"}
      onSubmitValidForm={onSubmitValidForm}
      backButton={backButton}
    />
  );
}
