import useUrlQueryState from "hooks/useUrlQueryState";

type OrderType = "asc" | "desc";
export type SortType = [string, OrderType] | null;

const useFilter = ({
  urlKey,
  initSort = null,
}: {
  urlKey: string;
  initSort?: SortType;
}) => {
  const [sort, setSort] = useUrlQueryState<SortType>(urlKey + "Sort", initSort);

  const handleSetNewOrderBy = (newOrderBy: string) => {
    if (sort) {
      const [orderBy, order] = sort;

      if (newOrderBy !== orderBy) {
        setSort([newOrderBy, "asc"]);
      } else {
        const newOrder = order === "asc" ? "desc" : "asc";
        setSort([newOrderBy, newOrder]);
      }
    } else {
      setSort([newOrderBy, "asc"]);
    }
  };

  return [sort, handleSetNewOrderBy] as const;
};

export default useFilter;
