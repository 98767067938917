import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ArrowLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.7,10.7H7.5l5.4-5.4c0.5-0.5,0.5-1.3,0-1.9s-1.3-0.5-1.9,0l-7.6,7.6C3.2,11.3,3,11.7,3,12
        c0,0.3,0.1,0.7,0.4,0.9l7.6,7.6c0.3,0.3,0.6,0.4,0.9,0.4s0.7-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.9l-5.4-5.4h12.2
        c0.7,0,1.3-0.6,1.3-1.3S20.5,10.7,19.7,10.7z"
      />
    </SvgIcon>
  );
}