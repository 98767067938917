import React from "react";
import SmartInputProps from "interfaces/SmartInputInterface";
import StyledDateInput from "components/uiComponents/inputs/styledInputs/StyledDateInput";

const SmartDateInput = (props: SmartInputProps) => {
  const { input, inputId, handleInputs } = props;

  const onChange = (value: any) => {
    handleInputs({ target: { name: inputId, value: value } });
  };
  
  return (
    <StyledDateInput
      id={inputId}
      label={input.label}
      value={input.value}
      onChange={onChange}
    />
  );
};

export default SmartDateInput;
