const imagesExtensions = ["png", "jpg", "jpeg"];

export default function isFileNameWithImageExtension(fileName: string) {
  const fileExtension = fileName.match(/.*\.(\w+)$/)![1] || false;

  if (fileExtension) {
    return imagesExtensions.includes(fileExtension);
  } else {
    return false;
  }
}
