import { Column } from "components/uiComponents/table/Interfaces";

const tableConfig: Column[] = [
  {
    id: "reportAlias",
    label: "Test Reports",
    tableCellStyles: "font-weight: 600;",
    mobileConfig: {
      options: {
        visibleLabel: false,
      },
      valueStyles: "font-size: 15px; text-transform: uppercase",
      gridProps: {
        xs: 6,
      },
    },
    type: "hyperlinkAction",
  },
  {
    id: "salesName",
    label: "Provider",
    accessor: (d) => `${d.clientName} (${d.physLastName}, ${d.physFirstName})`,
  },
];

export default tableConfig;
