import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { ArrowRightIcon } from "components/icons";
import { Box } from "@material-ui/core";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import clsx from "clsx";
import { fade } from "@material-ui/core";

type ColorClasses = "colorThemePrimary" | "colorThemeLight";
type SizeClasses = "sizeSmall" | "sizeMedium";

interface SubmitButtonProps extends ButtonProps {
  showSpinner?: boolean;
  customIcon?: JSX.Element;
  colorTheme?: "primary" | "light";
  size?: "small" | "medium";
  disableEndIcon?: boolean;
  type?: "submit" | "button";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: "none",
      fontWeight: 700,
      fontSize: 14,
      minWidth: "220px",
      borderRadius: "10px",
      border: "1px solid #61A1FF",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "inline-block",
    },

    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },

    sizeSmall: {
      padding: "7px 10px",
      minWidth: "140px",
    },
    sizeMedium: {
      padding: "14px 20px",
    },

    colorThemePrimary: {
      background: theme.palette.primary.main,

      "&.Mui-disabled": {
        background: fade(theme.palette.primary2.main, 0.6),
        borderColor: "transparent",
        color: "#FFFFFF",
      },
    },
    colorThemeLight: {
      color: theme.palette.primary.main,
      background: fade(theme.palette.primary.main, 0.15),
      border: fade(theme.palette.primary.main, 0.15),
      "&:hover": {
        color: theme.palette.primary.main,
        background: fade(theme.palette.primary.main, 0.15),
        border: fade(theme.palette.primary.main, 0.15),
      },
    },
  })
);

export default function SubmitButton({
  disabled,
  showSpinner,
  children,
  customIcon,
  colorTheme = "primary",
  disableEndIcon = false,
  size = "medium",
  type = "submit",
  onClick,
  ...rest
}: SubmitButtonProps) {
  const classes = useStyles();

  const colorThemeClass = `colorTheme${capitalizeFirstLetter(
    colorTheme
  )}` as ColorClasses;

  const sizeClass = `size${capitalizeFirstLetter(size)}` as SizeClasses;

  return (
    <Button
      className={clsx(
        classes.button,
        classes[colorThemeClass],
        classes[sizeClass]
      )}
      variant="contained"
      color="primary"
      disabled={disabled || showSpinner}
      onClick={onClick}
      type={type}
      size={"small"}
      endIcon={
        !disableEndIcon &&
        (showSpinner ? (
          <Box color={"#fff"} fontSize={"14px"} display={"flex"}>
            <CircularProgress size={15} color={"inherit"} />
          </Box>
        ) : (
          <Box fontSize={"14px!important"} display={"inherit"}>
            {customIcon ? customIcon : <ArrowRightIcon fontSize={"inherit"} />}
          </Box>
        ))
      }
      {...rest}
    >
      {children}
    </Button>
  );
}
