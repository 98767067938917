import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, IconButton, Box } from "@material-ui/core";
import useProvider from "hooks/useProvider";
import { GET_ONE } from "providers/dataProvider";
import { CloseThinIcon } from "components/icons";
import BaseModal from "components/modals/BaseModal";
import PatientModalContent from "components/modals/PatientModalContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      top: 14,
      right: 6,
    },
    container: {
      padding: "0",
    },
  })
);

export default function PatientModal({
  modalIsOpen,
  closeModal,
  patientInfoId,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  patientInfoId: number | null;
}) {
  const classes = useStyles();

  const { data: patientDetail } = useProvider(
    GET_ONE,
    `patients/${patientInfoId}/latest`
  );

  return (
    <BaseModal open={modalIsOpen} onClose={closeModal}>
      <Box maxWidth={"1210px"} width={"100vw"}>
        <Container className={classes.container}>
          <Box position={"relative"}>
            <IconButton
              className={classes.closeButton}
              component="span"
              onClick={closeModal}
            >
              <CloseThinIcon fontSize={"inherit"} />
            </IconButton>
            <PatientModalContent patientDetail={patientDetail} />
          </Box>
        </Container>
      </Box>
    </BaseModal>
  );
}
