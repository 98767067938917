import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function SmartSelectInput(props: any) {
  const { input, inputId, handleInputs } = props;

  return (
    <FormControl>
      <InputLabel shrink>{input.label}</InputLabel>
      <Select
        labelId={inputId}
        name={inputId}
        value={input.value}
        onChange={handleInputs}
        displayEmpty
      >
        {input.options.map((option: any) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
