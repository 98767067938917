import React from "react";
import { Box } from "@material-ui/core";
import PaperControlButton from "components/uiComponents/buttons/PaperControlButton";
import { FilterIcon } from "components/icons";
import useModal from "hooks/useModal";
import FilterModal from "components/uiComponents/Filter/FilterModal";
import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import { AppBarMenuItemProps } from "components/uiComponents/buttons/PaperControlButton";

export default function Filter({
  onSubmit,
  filterConfig,
  buttonProps,
  activeFilter = false,
}: {
  onSubmit: (data: { [index: string]: any }) => void;
  filterConfig: InputByHashConfigInterface;
  buttonProps?: AppBarMenuItemProps;
  activeFilter?: boolean;
}) {
  const [modalIsOpen, openModal, closeModal] = useModal();

  return (
    <React.Fragment>
      <FilterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        onSubmit={onSubmit}
        filterConfig={filterConfig}
      />
      <Box>
        <PaperControlButton
          startIcon={<FilterIcon />}
          status={activeFilter ? "active" : "normal"}
          {...buttonProps}
          onClick={() => {
            openModal();
          }}
        ></PaperControlButton>
      </Box>
    </React.Fragment>
  );
}
