import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { find, isObject } from "lodash";
interface OptionType {
  value: any;
  title: string;
}

const useStyles = makeStyles(() => ({
  input: {
    padding: "8px 0 !important",
  },
}));

export default function AutocompleteInput({
  name,
  options,
  textFieldProps,
  onChange,
  value,
  disabled = false,
  inputProps = {},
}: {
  name: string;
  options: { title: string }[];
  textFieldProps: TextFieldProps;
  onChange: (event: any) => void;
  value: string | null | object | undefined;
  disabled: boolean | undefined;
  inputProps?: { [index: string]: any };
}) {
  const classes = useStyles();

  let valueObj = value as OptionType;

  if (!isObject(value)) {
    valueObj = find(
      options,
      (option: OptionType) => option.value === value
    ) as OptionType;
  }

  return (
    <Autocomplete
      classes={classes}
      autoHighlight
      onInputChange={(e, value) => {
        if (inputProps.freeSolo) {
          onChange({
            target: { name: name, value: value },
          });
        }
      }}
      getOptionLabel={(option: any) => {
        if (typeof option === "object" && option.hasOwnProperty("title")) {
          return option.title;
        } else if (typeof option === "string") {
          return option;
        }
      }}
      options={options as OptionType[]}
      style={{ width: "100%" }}
      onChange={(e, value) => {
        onChange({ target: { name: name, value: value } });
      }}
      value={valueObj || null}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          label={textFieldProps!.label}
          variant="outlined"
        />
      )}
      {...inputProps}
    />
  );
}
