import React from "react";

type Context = {
  token: string;
  cognitoLogIn: (userName: string, password: string) => Promise<any>;
  logOut: () => Promise<any>;
  authStatus: string;
  confirmSignIn: (code: string) => Promise<any>;
  mfaChangePassword: (
    currentPassword: string,
    newPassword: string
  ) => Promise<any>;
  mfaLogin: (accessToken: string) => Promise<any>;
  changePassword: (newPassword: string) => Promise<any>;
  dataLoading: boolean;
  setDataLoading: (dataLoading: boolean) => void;
  setAuthStatus: (
    status: "login" | "smsMfa" | "changeTemporaryPassword"
  ) => void;
  isShowTerm: number;
  handleSubmitIsShowTerm: () => void;
  access: {
    userId: string;
    userRoleCode: string;
    isShowTerm: number;
  };
  messageBar: {
    title: string | null;
    children?: string | null;
    type?: "success" | "error";
    setMessage: (
      title: string | null,
      message?: string | null,
      type?: "success" | "error"
    ) => void;
  };
  logOutInProgress: boolean;
};

const AuthCenterContext = React.createContext<Context>(undefined!);
export default AuthCenterContext;
