import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function CheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.2,18.5c-0.4,0-0.7-0.1-1-0.4l-4.7-4.7c-0.6-0.6-0.6-1.5,0-2s1.5-0.6,2,0L9.2,15l9.4-9.4c0.6-0.6,1.5-0.6,2,0
        c0.6,0.6,0.6,1.5,0,2L10.2,18C9.9,18.3,9.5,18.5,9.2,18.5z"
      />
    </SvgIcon>
  );
}