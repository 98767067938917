import React from "react";
import { Column } from "components/uiComponents/table/Interfaces";
import StatusCellValue from "components/uiComponents/table/cells/StatusCellValue";
import { convertCodeToStatus } from "utils";

const statusConfig = {
  error: ["INCOMP"],
  warning: "VALID",
  success: "CMP",
};

const tableConfig: Column[] = [
  {
    id: "etlId",
    label: "Load Id",
    mobileConfig: {
      options: {
        visibleLabel: false,
      },
      gridProps: {
        xs: 12,
      },
      valueStyles: "font-size: 15px; font-weight: 700;",
    },
  },
  {
    id: "etlStartDate",
    label: "Load Date",
    mobileConfig: {
      gridProps: {
        xs: 6,
      },
    },
  },
  {
    id: "etlStatusDesc",
    label: "Status",
    mobileConfig: {
      gridProps: {
        xs: 6,
      },
    },
    accessor: (d) => {
      return (
        <StatusCellValue
          value={d.etlStatusDesc}
          status={
            convertCodeToStatus(statusConfig, d.etlStatusCode) as
              | "success"
              | "warning"
              | "error"
          }
        />
      );
    },
  },
];

export default tableConfig;
