import React, { useState } from "react";
import UserWidgetItem from "components/uiComponents/userWidgets/UserWidgetItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PatientModal from "components/modals/PatientModal";
import useModal from "hooks/useModal";
import DependentInterface from "interfaces/DependentInterface";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: "0 -10px 0",
      padding: "0 0 30px",
      overflow: "auto",
    },
  })
);

export default function UserWidgetList({
  dependentData,
  isDependentDataLoaded,
  selectedPatientId,
  setSelectedPatietId,
}: {
  dependentData: DependentInterface[];
  isDependentDataLoaded: boolean;
  selectedPatientId: number | null;
  setSelectedPatietId: (id: number) => void;
}) {
  const classes = useStyles();
  const [modalIsOpen, openModal, closeModal] = useModal();
  const [patientInfoId, setPatientInfoId] = useState<number | null>(null);

  const handleClickItemInfo = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    event.stopPropagation();
    setPatientInfoId(id);
    openModal();
  };

  const handleClickItem = (id: number) => {
    setSelectedPatietId(id);
  };

  return (
    <div className={classes.root}>
      {isDependentDataLoaded && patientInfoId && (
        <PatientModal
          patientInfoId={patientInfoId}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
        />
      )}
      {dependentData.map((item) => {
        const itemSelected = item.patientId === selectedPatientId;

        return (
          <UserWidgetItem
            key={item.id}
            data={item}
            dependentDataLength={dependentData.length}
            onClick={() => {
              handleClickItem(item.patientId);
            }}
            onClickInfo={handleClickItemInfo}
            isSelected={itemSelected}
          />
        );
      })}
    </div>
  );
}
