import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import BaseModal from "components/modals/BaseModal";
import useForm from "hooks/useForm";
import InputsList from "components/uiComponents/inputsList/InputsList";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import { fade } from "@material-ui/core/styles/colorManipulator";
import TextButton from "components/uiComponents/buttons/TextButton";
import PaperControlButton from "components/uiComponents/buttons/PaperControlButton";
import { CloseIcon, CheckedIcon } from "components/icons";
import { PaperProps } from "@material-ui/core/Paper";
import BasePaper from "components/uiComponents/papers/BasePaper";
import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControls: {
      margin: "44px 0 0",
      display: "flex",
      padding: "28px 0 0",
      justifyContent: "space-between",
      borderTop: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
    },
  })
);

export default function PatientModal({
  modalIsOpen,
  closeModal,
  onSubmit,
  paperProps,
  filterConfig,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
  onSubmit: (data: { [index: string]: any }) => void;
  paperProps?: PaperProps;
  filterConfig: InputByHashConfigInterface;
}) {
  const classes = useStyles();

  const {
    inputsById,
    inputsByHash,
    handleInputs,
    getSubmitData,
    resetErrors,
    isFormValid,
    clearAllInputs,
  } = useForm({
    inputsById: filterConfig.inputsById,
    inputsByHash: filterConfig.inputsByHash,
  });

  const handleSubmit = async (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    if (isFormValid()) {
      resetErrors();

      onSubmit(getSubmitData({ skipEmpty: true }));
      closeModal();
    }
  };

  return (
    <BaseModal open={modalIsOpen} onClose={closeModal}>
      <Box maxWidth={"400px"} width={"100%"} margin={"auto"}>
        <BasePaper {...paperProps}>
          <Box padding={{ md: "0 15px" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography color="inherit" variant={"h3"}>
                <Box
                  fontWeight={600}
                  fontSize={18}
                  display="flex"
                  alignItems="center"
                  component="span"
                >
                  <span>Filters</span>
                </Box>
              </Typography>
              <Box>
                <PaperControlButton
                  startIcon={
                    <Box
                      fontSize={"10px !important"}
                      display={"inline"}
                      mt={"2px"}
                    >
                      <CloseIcon fontSize={"inherit"} />
                    </Box>
                  }
                  onClick={() => {
                    clearAllInputs();
                  }}
                >
                  Clear
                </PaperControlButton>
              </Box>
            </Box>
            <Box marginTop={{ xs: 3, sm: 2 }}>
              <form>
                <InputsList
                  inputsById={inputsById}
                  inputsByHash={inputsByHash}
                  handleInputs={handleInputs}
                  inputWrapStyles={"width: 100%;"}
                />

                <Box className={classes.formControls}>
                  <TextButton onClick={closeModal}>Cancel</TextButton>
                  <SubmitButton
                    onClick={handleSubmit}
                    customIcon={<CheckedIcon />}
                  >
                    Apply
                  </SubmitButton>
                </Box>
              </form>
            </Box>
          </Box>
        </BasePaper>
      </Box>
    </BaseModal>
  );
}
