import InputByHashInterface from "interfaces/InputByHashInterface";
import authStorage from "providers/authStorage";

export function filterByHashConfigByPermissionsAndRoleCode(
  byId: string[],
  byHash: InputByHashInterface,
  roleCode: string
) {
  const filteredById = [...byId];
  const filteredByHash = { ...byHash };

  filteredById.forEach((id, index) => {
    const input = filteredByHash[id] as any;

    if ("hiddenForRoles" in input && input.hiddenForRoles.includes(roleCode)) {
      delete filteredByHash[id];
      filteredById.splice(index, 1);
    }
  });

  return [filteredById, filteredByHash];
}

export function filterByHashConfigByPermissions(
  byId: string[],
  byHash: InputByHashInterface
) {
  const userRoleCode = authStorage.getRoleCode();
  if (!userRoleCode) return [byId, byHash];

  return filterByHashConfigByPermissionsAndRoleCode(byId, byHash, userRoleCode);
}
