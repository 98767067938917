import React from "react";
import Lock from "@material-ui/icons/Lock";

const currentPasswordConfig = {
  label: "Current Password",
  type: "password",
  required: true,
  icon: <Lock />,
};

export default currentPasswordConfig;
