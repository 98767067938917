import React, { useState } from "react";
import {
  useTheme,
} from "@material-ui/core/styles";
import { Typography, Container, Box, Hidden } from "@material-ui/core";
import useProvider from "hooks/useProvider";
import { GET_LIST } from "providers/dataProvider";
import YearSelect from "components/uiComponents/inputs/YearSelect";
import MonthSelect from "components/uiComponents/inputs/MonthSelect";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateButton from "components/uiComponents/buttons/DateButton";
import DesktopTimeline from "components/uiComponents/timeline/DesktopTimeline";

import {
  getUniqFromArrayOfObjectsWithDates,
  filterArrayOfObjectsByDate,
  groupObjectsByYear,
} from "utils";

export default function Timeline({
  selectedPatientId,
  setSelectedReqId,
  selectedReqId,
}: {
  selectedPatientId: number | null;
  setSelectedReqId: (id: number) => void;
  selectedReqId: number | null;
}) {

  const theme = useTheme();
  const breakpointDownSm = useMediaQuery(theme.breakpoints.down("xs"));

  const [yearFilterValue, setYearFilterValue] = useState<number>(0);
  const [monthFilterValue, setMonthFilterValue] = useState<number>(0);

  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableMonths, setAvailableMonths] = useState<number[]>([]);

  const { data: timeLineData } = useProvider(
    GET_LIST,
    `patients/${selectedPatientId}/timeline`,
    null,
    onTimelineDataLoaded,
    [selectedPatientId]
  );

  const datesByYear = groupObjectsByYear(timeLineData, "dateCollectedTz");

  // console.log(datesByYear);
  // timeLineData.forEach((item) => {
  //   console.log(item);
  // });

  function onTimelineDataLoaded(data: { [key: string]: any }[]) {
    const uniqYears = getUniqFromArrayOfObjectsWithDates(
      data,
      "dateCollectedTz",
      "year"
    );

    const sortedUniqYears = uniqYears.sort(function (a, b) {
      return a - b;
    });

    const currentYear = sortedUniqYears[sortedUniqYears.length - 1];

    setAvailableYears(sortedUniqYears);
    setYearFilterValue(currentYear);
    refreshMonthSelect(data, currentYear);

    const filteredDatesByCurrentYear = filterArrayOfObjectsByDate(
      data,
      "dateCollectedTz",
      currentYear
    );

    setSelectedReqId(filteredDatesByCurrentYear[0].reqId);
  }

  const filteredDatesByCurrentYear = filterArrayOfObjectsByDate(
    timeLineData,
    "dateCollectedTz",
    yearFilterValue
  );

  const filteredDatesByCurrentMonth = filterArrayOfObjectsByDate(
    filteredDatesByCurrentYear,
    "dateCollectedTz",
    yearFilterValue,
    monthFilterValue
  );

  function refreshMonthSelect(data: {}[], year: number) {
    const filteredDatesByCurrentYear = filterArrayOfObjectsByDate(
      data,
      "dateCollectedTz",
      year
    );

    const uniqMonths = getUniqFromArrayOfObjectsWithDates(
      filteredDatesByCurrentYear,
      "dateCollectedTz",
      "month"
    );

    setAvailableMonths(uniqMonths);
    const currentMonth = uniqMonths[0];
    setMonthFilterValue(currentMonth);

    // const filteredDatesByCurrentMonth = filterArrayOfObjectsByDate(
    //   filteredDatesByCurrentYear,
    //   "dateCollectedTz",
    //   year,
    //   currentMonth
    // );

    // setSelectedReqId(filteredDatesByCurrentMonth[0].reqId);
  }

  const handleChangeYearFilter = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value: number = event.target.value as number;
    setYearFilterValue(value);

    if (breakpointDownSm) {
      refreshMonthSelect(timeLineData, value);
    }
  };

  const handleChangeMonthFilter = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setMonthFilterValue(event.target.value as number);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography variant="h1" component="h2">
          Date of Service
          <Hidden smUp>
            <Box component="span" ml={"9px"}>
              <YearSelect
                years={availableYears}
                selectProps={{
                  value: yearFilterValue ? yearFilterValue : "",
                  onChange: handleChangeYearFilter,
                  inputProps: { "aria-label": "Without label" },
                }}
              ></YearSelect>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box component="span" ml={"9px"}>
              <MonthSelect
                months={availableMonths}
                selectProps={{
                  value: monthFilterValue,
                  onChange: handleChangeMonthFilter,
                  inputProps: { "aria-label": "Without label" },
                }}
              ></MonthSelect>
            </Box>
          </Hidden>
        </Typography>

        <Hidden smUp>
          {filteredDatesByCurrentMonth.map((item) => {
            const date = new Date(item.dateCollectedTz);
            return (
              <DateButton
                key={item.reqId}
                isSelected={selectedReqId === item.reqId}
                onClick={() => {
                  setSelectedReqId(item.reqId);
                }}
              >
                {date.getDate()}
              </DateButton>
            );
          })}
        </Hidden>
      </Container>

      <Hidden xsDown>
        <DesktopTimeline
          selectedPatientId={selectedPatientId}
          setSelectedReqId={setSelectedReqId}
          selectedReqId={selectedReqId}
          datesByYear={datesByYear}
        />
      </Hidden>
    </React.Fragment>
  );
}
