import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "components/icons";
import { Box } from "@material-ui/core";
import theme from "config/theme";
import { HandleInputType } from "interfaces/SmartInputInterface";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const IconWrap = ({ children }: { children: JSX.Element }) => {
  return (
    <Box
      fontSize={18}
      color={theme.palette.primary.main}
      display="flex"
      justifyContent="center"
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-rounded": {
      padding: "0",
      boxShadow: "0px 6px 26px -8px rgba(0,0,0,0.2) !important",
      borderRadius: "18px",
      border: "1px solid #F0EFF4",
    },
    "& .MuiPickersCalendarHeader-switchHeader": {
      maxWidth: "289px",
      margin: "15px auto 8px",
      "& .MuiTypography-root": {
        fontSize: "18px",
        fontWeight: 600,
        color: theme.palette.primary2.main,
      },
    },

    "& .MuiPickersCalendarHeader-daysHeader": {
      borderTop: "1px solid #F0EFF4",
      borderBottom: "1px solid #F0EFF4",
      padding: "13px 0",
      maxHeight: "none",
    },
  },
}));

export default function StyledDateInput({
  id,
  label,
  value,
  onChange,
}: {
  id: string;
  label: string;
  value: string | object | null | undefined;
  onChange: HandleInputType;
}) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:500px)");
  const handleDateChange = (date: any) => {
    onChange(date);
  };

  return (
    <DatePicker
      autoOk
      disableToolbar
      variant={isMobile ? "dialog" : "inline"}
      format="MMMM D, yyyy"
      name={id}
      label={label}
      value={value}
      PopoverProps={{
        classes: classes,
      }}
      leftArrowIcon={
        <IconWrap>
          <ArrowLeftIcon fontSize={"inherit"} />
        </IconWrap>
      }
      rightArrowIcon={
        <IconWrap>
          <ArrowRightIcon fontSize={"inherit"} />
        </IconWrap>
      }
      onChange={handleDateChange}
      inputVariant="outlined"
    />
  );
}
