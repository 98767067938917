import { useState } from "react";

export default function useImageLoader() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const loadImage = async (imageUrl: string) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      const img = new Image();
      img.onload = () => {
        setIsLoaded(true);
        setIsLoading(false);
        resolve(true);
      };
      img.onerror = (e) => {
        reject(e);
        setIsLoading(false);
        setLoadingError(true);
      };
      img.src = imageUrl;
    });
  };

  return { loadImage, isLoading, isLoaded, loadingError };
}
