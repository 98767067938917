import TableCell from "@material-ui/core/TableCell";
import styled from "styled-components";

const defaultRootStyles = ``;

const StyledTableCell = styled(TableCell)`
  && {
    ${defaultRootStyles}
    ${(props: { styles?: string }) => props.styles}
  }
`;

export default StyledTableCell;
