import decorateWithEvents from "utils/decorateWithEvents.ts";
import { cancelCurrentCalls } from "./dataProvider";
import axios from "axios";

const errors = decorateWithEvents({
  status408: "status408",
  status403: "status403",
  status420: "status420",
  status490: "status490",
  handle(error) {
    if (axios.isCancel(error)) {
      return Promise.reject({ message: `Error: ${error.message}` });
    }

    let message = "";

    try {
      message = error.response.data.message || "Unknown Error";
    } catch (e) {
      message = "Server Error";
    }

    let status = "";

    try {
      status = error.response.status;
    } catch (e) {
      status = "500";
    }

    if (status === 408) {
      cancelCurrentCalls();
      this.emit(this.status408);
    } else if (status === 403) {
      this.emit(this.status403);
    } else if (status === 420) {
      cancelCurrentCalls();
      this.emit(this.status420);
    } else if (status === 490) {
      this.emit(this.status490);
    }

    return Promise.reject({ status: status, message: `Error: ${message}` }); // eslint-disable-line prefer-promise-reject-errors
  },
});

export default errors;
