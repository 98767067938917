import React, { useState } from "react";
import history from "appHistory";
import AuthPaper from "components/uiComponents/papers/AuthPaper";
import { Box } from "@material-ui/core";
import { useAuthCenter } from "AuthCenter";
import { userRoles } from "config/userRoles";
import LoginForm from "components/uiComponents/forms/AuthForm/LoginForm";
import SmsMfa from "components/uiComponents/forms/AuthForm/SmsMfaForm";
import NewPasswordForm from "components/uiComponents/forms/AuthForm/NewPasswordForm";
import Div100vh from "react-div-100vh";
import { canByRoles } from "utils/permissions";
import { ADMIN_ROOT_PATH, CUSTOMER_ROOT_PATH } from "config/routes";

const LoginPage = () => {
  const authCenter = useAuthCenter();
  const [mfaAuthInProgress, setMfaAuthInProgress] = useState(false);

  const handleLoginOk = (data: any) => {
    const frontPagePath = canByRoles([userRoles.admin]) ? ADMIN_ROOT_PATH : CUSTOMER_ROOT_PATH;
    history.push(frontPagePath);
  };

  const handleSmsConfirmOk = async (accessToken: string) => {
    try {
      setMfaAuthInProgress(true);
      const mfaLoginResponse = await authCenter.mfaLogin(accessToken);
      setMfaAuthInProgress(false);
      handleLoginOk(mfaLoginResponse.data);
    } catch (e) {
      setMfaAuthInProgress(false);
      authCenter.messageBar.setMessage(e.message, null, "error");
    }
  };

  const handleFormError = (message: string) => {
    authCenter.messageBar.setMessage(message, null, "error");
  };

  let formMessageBarProps = {
    title: authCenter.messageBar.title,
    children: authCenter.messageBar.children,
    type: authCenter.messageBar.type,
  };

  const getForm = (type: string) => {
    switch (type) {
      case "login":
        return {
          title: "Log In",
          component: (
            <LoginForm
              onSubmitError={handleFormError}
              onLoginOk={handleLoginOk}
            />
          ),
        };
      case "smsMfa":
        return {
          title: "Log In",
          component: (
            <SmsMfa
              onSubmitError={handleFormError}
              onConfirmOk={handleSmsConfirmOk}
            />
          ),
        };
      case "changeTemporaryPassword":
        return {
          title: "Change Password",
          component: <NewPasswordForm />,
        };
      default:
        return {
          title: "Log In",
          component: (
            <LoginForm
              onSubmitError={handleFormError}
              onLoginOk={handleLoginOk}
            />
          ),
        };
    }
  };

  const form = getForm(authCenter.authStatus);

  return (
    <Div100vh>
      <Box display={"flex"} height={"100vh"} padding={"0 10px"}>
        <Box maxWidth={"571px"} margin={"auto"} width={"100%"}>
          <AuthPaper
            title={form.title}
            formMessageBar={formMessageBarProps}
            showProgressBar={mfaAuthInProgress}
          >
            <React.Fragment>{form.component}</React.Fragment>
          </AuthPaper>
        </Box>
      </Box>
    </Div100vh>
  );
};

export default LoginPage;
