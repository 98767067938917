import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ListIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.1,7.6H8.4c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h11.6c0.5,0,0.9,0.4,0.9,0.9S20.6,7.6,20.1,7.6z M21,12
        c0-0.5-0.4-0.9-0.9-0.9H8.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h11.6C20.6,12.9,21,12.5,21,12z M21,17.4c0-0.5-0.4-0.9-0.9-0.9
        H8.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h11.6C20.6,18.3,21,17.9,21,17.4z M4.9,6.6c0-0.5-0.4-0.9-0.9-0.9h0
        C3.4,5.7,3,6.1,3,6.6s0.4,0.9,0.9,0.9S4.9,7.1,4.9,6.6z M4.9,12c0-0.5-0.4-0.9-0.9-0.9h0C3.4,11.1,3,11.5,3,12s0.4,0.9,0.9,0.9
        S4.9,12.5,4.9,12z M4.9,17.4c0-0.5-0.4-0.9-0.9-0.9h0c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9S4.9,17.9,4.9,17.4z"
      />
    </SvgIcon>
  );
}
