const yup = require('yup');

const emptyStringToNull = (value: string, originalValue: string) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

const stringValidation = (message: string) => yup
  .string()
  .required(`${message} is required`)
  .nullable(`${message} is required`);

const monthValidation = () => yup
  .number()
  .typeError('Month must be a number')
  .min(1, 'Invalid month')
  .max(12, 'Invalid month')
  .transform(emptyStringToNull);

const isValidDate = (date: string, month: string, year: string) => {
  const dateToCheck = parseInt(date);
  const monthToCheck = parseInt(month);
  const yearToCheck = parseInt(year);

  const dateObj = new Date(yearToCheck, monthToCheck, dateToCheck);
  return (
    dateObj.getFullYear() === yearToCheck
    && dateObj.getMonth() === monthToCheck
    && dateObj.getDate() === dateToCheck
  );
};

export { stringValidation, monthValidation, isValidDate };
