import React from "react";
import { RouteComponentProps } from "react-router-dom";
import RegistrationForm from "components/uiComponents/forms/RegistrationForm/RegistrationForm";
import { Box } from "@material-ui/core";

interface RouterProps {
  token: string;
}

interface SignUpPageProps extends RouteComponentProps<RouterProps> {
  onLoginOk: () => void;
}

export default function RegistrationPage({
  onLoginOk = () => {},
  ...props
}: SignUpPageProps) {
  return (
    <Box display={"flex"} minHeight={"100vh"}>
      <Box maxWidth={"571px"} margin={"auto"} width={"100%"}>
        <RegistrationForm token={props.match.params.token} />
      </Box>
    </Box>
  );
}
