import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Box } from "@material-ui/core";
import FilePreviewList from "components/uiComponents/inputs/styledInputs/StyledFileInput/FilePreviewList";
import { formatBytes } from "utils";
import { remove } from "lodash";
import { PaperClipIcon } from "components/icons";
import { fade } from "@material-ui/core/styles/colorManipulator";
import theme from "config/theme";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

const useFileInput = ({ maxFileSize }: { maxFileSize: number }) => {
  const [files, setFiles] = useState<FileItemType[]>([]);
  const [error, setErrorFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputKey, setInputKey] = useState(getKey());

  const resetError = () => {
    setErrorFlag(false);
    setErrorMessage("");
  };

  function getKey() {
    return (new Date().getTime() / 1000).toString();
  }

  const isValidInput = () => {
    let isValid = true;
    const updatedFiles = [...files];
    resetError();

    if (maxFileSize) {
      updatedFiles.forEach((item, index) => {
        console.log(item);
        if (!isValidBySize(item.size)) {
          if (isValid) {
            isValid = false;
          }
          updatedFiles[index].error = true;
          updatedFiles[index].errorMessage = "Max file size";
        } else {
          updatedFiles[index].error = false;
          updatedFiles[index].errorMessage = "";
        }
      });
    }
    setFiles(updatedFiles);
    return isValid;
  };

  const isValidBySize = (size: number) => {
    return maxFileSize >= size;
  };

  const clearInputFiles = () => {
    setInputKey(getKey());
  };

  return {
    files,
    setFiles,
    inputKey,
    error,
    errorMessage,
    clearInputFiles,
    isValidInput,
  };
};

const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "right",
      position: "relative",
    },
    input: {
      display: "none",
    },
    uploadButton: {
      textTransform: "none",
      color: fade(theme.palette.primary2.main, 0.6),
      fontWeight: 600,
      fontSize: 13,
    },
  })
);

export type FileItemType = {
  name: string;
  file: File;
  size: number;
  formattedSize: string;
  url: string;
  type: "image" | "other";
  error?: boolean;
  errorMessage?: string;
};

export default function StyledFileInput({
  files,
  setFiles,
  uploadingInProgress = false,
  inputKey,
  clearInputFiles,
}: {
  files: FileItemType[];
  setFiles: (files: FileItemType[]) => void;
  uploadingInProgress?: boolean;
  inputKey: string;
  clearInputFiles: () => void;
}) {
  const classes = useStyles();

  const addFiles = (newFiles: FileItemType[]) => {
    const currentFileNames = new Set(files.map((d) => d.name));
    const updatedFiles = [
      ...files,
      ...newFiles.filter((d) => !currentFileNames.has(d.name)),
    ];

    setFiles(updatedFiles);
  };

  const handleChange = (event: any) => {
    let newFiles = [] as FileItemType[];

    Array.from(event.target.files).forEach((file: any) => {
      newFiles.push({
        url: URL.createObjectURL(file),
        type: validImageTypes.includes(file.type) ? "image" : "other",
        name: file.name,
        file: file,
        size: file.size,
        formattedSize: formatBytes(file.size, 2),
      });
    });
    addFiles(newFiles);
  };

  const deleteFiles = (name: string) => {
    const updatedFiles = [...files];
    remove(updatedFiles, {
      name: name,
    });
    setFiles(updatedFiles);
    clearInputFiles();
  };

  return (
    <Box className={classes.root}>
      {uploadingInProgress && <CircularProgressShadow />}
      <input
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
        key={inputKey}
        data-max-size="100"
      />
      <FilePreviewList files={files} onDelete={deleteFiles} />

      <label htmlFor="contained-button-file">
        <Button
          color="primary"
          component="span"
          className={classes.uploadButton}
        >
          <Box color={fade(theme.palette.primary2.main, 0.4)}>
            <PaperClipIcon />
          </Box>
          Attachment
        </Button>
      </label>
    </Box>
  );
}

export { useFileInput };
