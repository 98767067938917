import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { PaperProps } from "@material-ui/core/Paper";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${fade("#2C3367", 0.1)}`,
      padding: "0 0 20px",
    },
    iconWrap: {
      background: fade(theme.palette.primary.main, 0.1),
      borderRadius: "50%",
      width: "34px",
      height: "34px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 7px 0 0",
    },
  })
);

export default function PaperWithTitle({
  paperProps,
  children,
  icon,
  title,
  controls,
}: {
  paperProps?: PaperProps;
  children: JSX.Element[] | JSX.Element | string;
  controls?: JSX.Element[] | JSX.Element | string;
  icon?: JSX.Element;
  title?: string;
}) {
  const classes = useStyles();

  return (
    <BasePaper {...paperProps}>
      <Box className={classes.root}>
        <Typography color="primary" variant={"h2"}>
          <Box
            fontWeight={600}
            display="flex"
            alignItems="center"
            component="span"
          >
            {icon && <span className={classes.iconWrap}>{icon}</span>}
            <span>{title}</span>
          </Box>
        </Typography>
        <Box>{controls}</Box>
      </Box>
      <Box marginTop={icon && title ? { xs: 3, sm: 2 } : {}}>{children}</Box>
    </BasePaper>
  );
}
