import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Container } from "@material-ui/core";
import PHDLogo from "components/uiComponents/PHDLogo";
import { Hidden } from "@material-ui/core";
import DesktopToolbar from "components/toolbars/DesktopToolbar";
import MobileToolbar from "components/toolbars/MobileToolbar";
import history from "appHistory";
import { Box } from "@material-ui/core";
import { canByRoles } from "utils/permissions";
import { userRoles } from "config/userRoles";
import { ADMIN_ROOT_PATH, CUSTOMER_ROOT_PATH } from "config/routes";
import { useStore } from "context/Store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
  })
);

const useAppBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "0 0 20px",
      boxShadow: "none",
      [theme.breakpoints.up("xs")]: {
        margin: "0 0 10px",
      },
    },
  })
);

export default function HeaderAppBar() {
  const classes = useStyles();
  const appBarClasses = useAppBarStyles();
  const frontPagePath = canByRoles([userRoles.admin])
    ? ADMIN_ROOT_PATH
    : CUSTOMER_ROOT_PATH;
  const { userProvider } = useStore();
  const userData = userProvider.data;

  const userFullName = userData
    ? `${userData?.userFirstName} ${userData?.userLastName}`
    : "";

  return (
    <AppBar position="static" color={"transparent"} classes={appBarClasses}>
      <Container>
        <Toolbar disableGutters={true}>
          <Typography variant="h6" className={classes.title}>
            <Box>
              <PHDLogo
                onClick={() => {
                  history.push(frontPagePath);
                }}
              />
            </Box>
          </Typography>

          <Hidden xsDown>
            <DesktopToolbar userFullName={userFullName} />
          </Hidden>
          <Hidden smUp>
            <MobileToolbar />
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
