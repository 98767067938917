import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function UsersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.3,14.9c0.7,0.7,1,1.6,1,2.5V19c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-1.6c0-0.7-0.3-1.4-0.8-1.9
        c-0.5-0.5-1.2-0.8-1.9-0.8H6.6c-0.7,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.2-0.8,1.9V19c0,0.3-0.2,0.5-0.5,0.5S3,19.2,3,19v-1.6
        c0-1,0.4-1.8,1-2.5c0.7-0.7,1.6-1,2.5-1h6.2C13.7,13.9,14.6,14.2,15.3,14.9z M6.1,8.1c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6
        s-1.6,3.6-3.6,3.6S6.1,10.1,6.1,8.1z M7,8.1c0,1.5,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6S7,6.7,7,8.1z M20.3,15.2
        c-0.5-0.6-1.2-1.1-1.9-1.3c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.2,0.1,0.5,0.3,0.6c0.6,0.1,1.1,0.5,1.4,0.9c0.4,0.5,0.6,1,0.6,1.6V19
        c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-1.6C21,16.6,20.7,15.9,20.3,15.2z M17.2,5.9c-0.5-0.6-1.2-1.1-1.9-1.3
        C15,4.6,14.7,4.8,14.7,5c-0.1,0.2,0.1,0.5,0.3,0.6c0.6,0.1,1.1,0.5,1.4,0.9C16.8,7,17,7.5,17,8.1s-0.2,1.2-0.6,1.6
        c-0.4,0.5-0.9,0.8-1.4,0.9c-0.2,0.1-0.4,0.3-0.3,0.6c0.1,0.2,0.2,0.4,0.5,0.4c0,0,0.1,0,0.1,0c0.8-0.2,1.5-0.6,1.9-1.3
        c0.5-0.6,0.7-1.4,0.7-2.2S17.6,6.6,17.2,5.9z"
      />
    </SvgIcon>
  );
}