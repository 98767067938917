import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.9,12.4c0.1-0.3,0.1-0.6,0-0.9c-0.1-0.1-0.1-0.3-0.2-0.4l-7.9-7.9c-0.4-0.4-1.2-0.4-1.6,0s-0.4,1.2,0,1.6
        l6,6h-13C3.5,10.9,3,11.4,3,12c0,0.6,0.5,1.1,1.1,1.1h13l-6,6c-0.4,0.4-0.4,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3
        l7.9-7.9C20.8,12.7,20.9,12.6,20.9,12.4z"
      />
    </SvgIcon>
  );
}