import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";
import ThreadMessageDateGroup from "components/pages/customer/MessagePage/parts/ThreadMessageDateGroup";

export default function ThreadMessageList({
  messagesGroupedByDate,
  userId,
  messagesLoading,
}: {
  messagesGroupedByDate: {}[];
  userId: string;
  messagesLoading: boolean;
}) {
  return (
    <Box position={"relative"}>
      {messagesLoading && <CircularProgressShadow />}

      {Object.entries(messagesGroupedByDate).map(([key, value]) => {
        const dayToday = moment(key).isSame(new Date(), "day");
        const currentYear = moment(key).isSame(new Date(), "year");
        return (
          <ThreadMessageDateGroup
            key={key}
            date={dayToday ? "Today" : moment(key).format(currentYear ? "dddd, MMMM D" : "dddd, MMMM D YYYY")}
            messages={value as []}
            userId={userId}
          />
        );
      })}
    </Box>
  );
}
