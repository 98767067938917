import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";

interface TextButtonProps extends ButtonProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: "none",
      textDecoration: "underline",
      fontSize: "13px",
      fontWeight: 700,
      whiteSpace: 'nowrap',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "inline-block",
    },
  })
);

export default function TextButton({
  children,
  onClick,
  ...rest
}: TextButtonProps) {
  const classes = useStyles();

  return (
    <Button
      color="primary"
      onClick={onClick}
      className={classes.button}
      {...rest}
    >
      {children}
    </Button>
  );
}
