import { useState, useLayoutEffect } from "react";
import { debounce } from "lodash";

export default function useWindowSize(debounceTime: number = 1000) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    const debounceResize = debounce(updateSize, debounceTime);
    window.addEventListener("resize", debounceResize);
    updateSize();
    return () => {
      window.removeEventListener("resize", debounceResize);
      debounceResize.cancel();
    };
  }, [debounceTime]);
  return size;
}
