import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import phdLogo from "images/PHD_logo.png";
import clsx from "clsx";
import { fade } from "@material-ui/core";

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type SizeClasses = "sizeSmall" | "sizeMedium";
type ColorClasses = "colorPrimary" | "colorSecondary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sizeSmall: {
      "& $text": {
        fontSize: "14px",
      },
      "& $logo": {
        height: "38px",
        width: "auto",
      },
    },
    sizeMedium: {
      "& $text": {
        fontSize: "15px",
      },
      "& $logo": {
        height: "50px",
        width: "auto",
      },
    },
    colorPrimary: {
      "& $text": {
        color: theme.palette.primary.main,
      },
    },
    colorSecondary: {
      "& $text": {
        color: fade(theme.palette.primary2.main, 0.6),
      },
    },
    colorWhite: {
      "& $text": {
        color: "#fff",
      },
    },
    text: {
      whiteSpace: "nowrap",
    },
    logo: {
      margin: "0 7px 0 0",
    },
    active: {
      cursor: "pointer",
      transition: ".5s",

      "&:hover": {
        opacity: ".5",
      },
    },
  })
);

export default function PHDLogo({
  size = "medium",
  color = "primary",
  onClick,
}: {
  size?: "small" | "medium";
  color?: "primary" | "secondary" | "white";
  onClick?: () => void;
}) {
  const classes = useStyles();
  const sizeClass = `size${capitalizeFirstLetter(size)}` as SizeClasses;
  const colorClass = `color${capitalizeFirstLetter(color)}` as ColorClasses;

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      className={clsx(
        classes[colorClass],
        classes[sizeClass],
        onClick && classes.active
      )}
      onClick={onClick}
    >
      <img src={phdLogo} alt="PHD" className={classes.logo} />
      <Typography color="primary" variant={"h6"}>
        <Box
          fontWeight={600}
          className={classes.text}
          // fontSize={sizeConfig[size]}
          display="flex"
          alignItems="center"
          component="span"
        >
          <Box>Patient Portal</Box>
        </Box>
      </Typography>
    </Box>
  );
}
