import history from "appHistory";

const qs = require("qs");

const urlQuery = {
  getHistory() {
    return history;
  },
  getLocation() {
    return history.location;
  },
  getQuery() {
    const location = this.getLocation();
    return qs.parse(location.search.slice(1));
  },
  deleteByKey(key: string) {
    const location = this.getLocation();
    const query = this.getQuery();
    if (Object.hasOwnProperty.bind(query)(key)) {
      delete query[key];
      history.push(`${location.pathname}?${qs.stringify(query)}`);
    }
  },
  push(params: object) {
    const location = this.getLocation();
    let query = this.getQuery();
    query = { ...query, ...params };
    history.push(`${location.pathname}?${qs.stringify(query)}`);
  },
  getByKey(key: string) {
    const query = this.getQuery();
    if (Object.hasOwnProperty.bind(query)(key)) {
      try {
        return JSON.parse(query[key]);
      } catch {
        return query[key];
      }
    } else {
      return null;
    }
  },
  getByKeyByQuery(key: string, query: object) {
    const parsedQuery = qs.parse(query);
    if (Object.hasOwnProperty.bind(parsedQuery)(key)) {
      try {
        return JSON.parse(parsedQuery[key]);
      } catch {
        return parsedQuery[key];
      }
    } else {
      return null;
    }
  },
};

export default urlQuery;
