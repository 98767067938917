import { findKey } from "lodash";

export default function convertCodeToStatus(
  statusConfig: { [index: string]: string[] | string },
  code: string
) {
  return findKey(statusConfig, (o) => {
    if (Array.isArray(o)) {
      return o.includes(code);
    } else {
      return o === code;
    }
  });
}
