import { isValidDate } from "utils/validation";
let yup = require("yup");

const getValue = (value: { value: string } | string | null) => {
  let returnValue = null;
  if (value === null) {
    return null;
  } else if (typeof value === "object" && "value" in value) {
    returnValue = value.value;
  } else if (typeof value === "string") {
    returnValue = value;
  }
  return returnValue;
};

const birthdayInputConfig = {
  label: "Date of Birth",
  type: "birthday",
  validation: yup
    .mixed()
    .test("validDate", "Invalid date", function (value: any) {
      const { date, month, year } = value;

      const dateValue = getValue(date);
      const monthValue = getValue(month);
      const yearValue = getValue(year);

      if (dateValue === null || monthValue === null || yearValue === null) {
        return false;
      }

      return isValidDate(dateValue, monthValue, yearValue);
    }),
  required: false,
  submitValueAccessor: (d: { [index: string]: any }) => {
      return d ? `${getValue(d.month)}/${getValue(d.date)}/${getValue(d.year)}` : null;
  },
};

export default birthdayInputConfig;
