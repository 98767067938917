import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FileItemType } from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import FilePreviewListItem from "components/uiComponents/inputs/styledInputs/StyledFileInput/FilePreviewListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
  })
);

export default function FilePreviewList({
  files,
  onDelete,
}: {
  files: FileItemType[];
  onDelete: (name: string) => void;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.root} margin="0 -3px">
      {files.map((fileItem) => {
        return (
          <Box margin="6px 3px 0" key={fileItem.name}>
            <FilePreviewListItem onDelete={onDelete} fileItem={fileItem} />
          </Box>
        );
      })}
    </Box>
  );
}
