import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function PencilIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.2,21c-0.3,0-0.6-0.1-0.8-0.3c-0.3-0.3-0.4-0.7-0.3-1.1l1.2-4.4c0.1-0.2,0.1-0.4,0.3-0.5L15.3,3.9l0,0
        c0.3-0.3,0.7-0.6,1.1-0.7c0.8-0.3,1.8-0.3,2.6,0c0.4,0.2,0.8,0.4,1.1,0.7c0.3,0.3,0.6,0.7,0.7,1.1c0.2,0.4,0.3,0.8,0.3,1.3
        c0,0.4-0.1,0.9-0.3,1.3c-0.2,0.4-0.4,0.8-0.7,1.1L9.4,19.4c-0.1,0.1-0.3,0.2-0.5,0.3l-4.4,1.2C4.4,20.9,4.3,21,4.2,21z M6.4,16
        l-0.6,2.2L8,17.7L18.5,7.1c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.4s0-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.2-0.2-0.4-0.2c-0.3-0.1-0.6-0.1-0.9,0c-0.1,0.1-0.3,0.1-0.4,0.2L6.4,16z"
      />
    </SvgIcon>
  );
}
