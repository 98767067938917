import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Button, { ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";

interface IconButtonProps extends ButtonProps {
  showSpinner?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: "12px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: 14,
      borderRadius: "10px",
      border: "1px solid #61A1FF",
      minWidth: 48,
      minHeight: 48,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "inline-block",
    },

    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default function SubmitButton({
  disabled,
  showSpinner,
  children,
  onClick,
  ...rest
}: IconButtonProps) {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button)}
      variant="contained"
      color="primary"
      disabled={disabled || showSpinner}
      onClick={onClick}
      type={"submit"}
      size={"small"}
      {...rest}
    >
      {showSpinner ? <CircularProgress size={24} /> : children}
    </Button>
  );
}
