import { GET_LIST } from "providers/dataProvider";
import useProvider from "hooks/useProvider";

export type UseGetOneProviderInterface = {
  data: { [index: string]: any } | null;
  dataLoaded: boolean;
  dataLoading: boolean;
  error: string;
  loadData: () => void;
};

export default function useGetOneProvider(
  resource: string | null,
  params?: {} | null,
  onDataLoaded?: ((data: []) => void) | null,
  dependencies?: any[] | null
) {
  const { data, dataLoaded, dataLoading, error, loadData } = useProvider(
    GET_LIST,
    resource,
    params || {},
    onDataLoaded,
    dependencies
  );

  return {
    data: data[0] ? data[0] : null,
    dataLoaded,
    dataLoading,
    error,
    loadData,
  } as UseGetOneProviderInterface
}