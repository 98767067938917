import React from "react";
import { MaleIcon, FemaleIcon } from "components/icons";

const genderInputConfig = {
  type: "buttonRadio",
  options: [
    {
      label: "Male",
      value: "MALE",
      icon: <MaleIcon />,
    },
    {
      label: "Female",
      value: "FEMALE",
      icon: <FemaleIcon />,
    },
  ],
  value: "MALE",
  required: true,
};

export default genderInputConfig;
