import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const helpRequestForm: InputByHashConfigInterface = {
  inputsById: ["msgBody"],

  inputsByHash: {
    msgBody: {
      type: "text",
      required: true,
      label: "Type message here",
      subType: "multiline",
      extraFields: {
        rows: 2,
      },
    },
  },
};

export default helpRequestForm;
