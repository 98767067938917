import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Select, { SelectProps } from "@material-ui/core/Select";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import { KeyboardArrowDownIcon } from "components/icons";
import { MenuItem } from "@material-ui/core";

const monthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: "110px",
    },
    select: {
      color: theme.palette.primary.main,
      "& .MuiSelect-root": {
        padding: "8px 11px",
        letterSpacing: 0,
        backgroundColor: "#D9E5F8",
        borderRadius: 8,
      },

      "& .MuiSelect-icon": {
        fontSize: "16px",
        top: "8px",
        color: theme.palette.primary.main,
      },
      "& fieldset": {
        border: "none",
      },
    },
  })
);

export default function YearSelect({
  formControlProps,
  selectProps,
  months,
}: {
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  months: number[];
}) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      {...formControlProps}
    >
      <Select
        IconComponent={KeyboardArrowDownIcon}
        className={classes.select}
        {...selectProps}
      >
        {months.map((monthIndex, index) => {
          return (
            <MenuItem key={index} value={monthIndex}>
              {monthLabels[monthIndex]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
