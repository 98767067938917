import { cloneDeep } from "lodash";

export default function enhanceInputByHashConfig(
  config: any,
  inputId: string,
  key: string,
  value: any
) {
  const updatedConfig = cloneDeep(config);

  return {
    ...updatedConfig,
    [inputId]: {
      ...updatedConfig[inputId],
      [key]: value,
    },
  };
}
