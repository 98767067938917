import React from "react";
import Lock from "@material-ui/icons/Lock";
let yup = require("yup");

const newPasswordConfirm = {
  label: "Confirm New Password",
  type: "password",
  required: true,
  validation: yup
    .string()
    .oneOf([yup.ref("newPwd"), null], "Passwords don't match")
    .required("Password confirm is required"),
  autocomplete: "new-password",
  icon: <Lock />,
  excludeSubmit: true,
};

export default newPasswordConfirm;
