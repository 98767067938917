import React from "react";
import AppBarMenuItem from "components/uiComponents/buttons/AppBarMenuItem";
import { useAuthCenter } from "AuthCenter";
import history from "appHistory";
import AppBarSubMenuItem from "components/uiComponents/buttons/AppBarSubMenuItem";
import { UserIcon, LogOutIcon } from "components/icons";
import AppBarDropdownMenuLink from "components/uiComponents/buttons/AppBarDropdownMenuLink";
import AppBarDropdownMenuButton from "components/uiComponents/buttons/AppBarDropdownMenuItem";
import AppBarMenuLink from "components/uiComponents/buttons/AppBarMenuLink";
import { canByRoles } from "utils/permissions";
import { userRoles } from "config/userRoles";
import Divider from "@material-ui/core/Divider";
import {
  ADMIN_USERS_PAGE_PATH,
  ADMIN_PATIENTS_PAGE_PATH,
  ADMIN_SYNC_PAGE_PATH,
  CUSTOMER_PROFILE_PAGE_PATH,
  ADMIN_PROFILE_PAGE_PATH,
  ADMIN_MESSAGES_PAGE_PATH,
  CUSTOMER_MESSAGES_PAGE_PATH,
} from "config/routes";

type MenuItemType =
  | {
      id: string;
      label: string;
      type: "link" | "button" | "submenu";
      to?: string;
      icon?: JSX.Element;
      showSpinner?: boolean;
      subMenuItems?: MenuItemType[];
      onClick?: () => void;
    }
  | {
      id: string;
      type: "divider";
    };

export default function DesktopToolbar({
  userFullName,
}: {
  userFullName: string;
}) {
  const authCenter = useAuthCenter();

  const adminMenuConfig: MenuItemType[] = [
    {
      id: "patients",
      label: "Patients",
      type: "link",
      to: ADMIN_PATIENTS_PAGE_PATH,
    },
    {
      id: "messages",
      label: "Messages",
      type: "link",
      to: ADMIN_MESSAGES_PAGE_PATH,
    },
    {
      id: "users",
      label: "Users",
      type: "link",
      to: ADMIN_USERS_PAGE_PATH,
    },
    {
      id: "dataSync",
      label: "Data sync",
      type: "link",
      to: ADMIN_SYNC_PAGE_PATH,
    },
    {
      id: "settings",
      label: userFullName,
      type: "submenu",
      subMenuItems: [
        {
          id: "profile",
          label: "Profile",
          icon: <UserIcon />,
          to: ADMIN_PROFILE_PAGE_PATH,
          type: "link",
        },
        {
          type: "divider",
          id: "divider",
        },
        {
          id: "logout",
          label: "Logout",
          icon: <LogOutIcon />,
          showSpinner: authCenter.logOutInProgress,
          type: "button",
          onClick: () => {
            authCenter.logOut().then(() => {
              history.push("/login");
            });
          },
        },
      ],
    },
  ];

  const customerMenuConfig: MenuItemType[] = [
    {
      id: "dashboard",
      label: "Dashboard",
      type: "link",
      to: "/",
    },
    {
      id: "messages",
      label: "Messages",
      type: "link",
      to: CUSTOMER_MESSAGES_PAGE_PATH,
    },
    {
      id: "settings",
      label: userFullName,
      type: "submenu",
      subMenuItems: [
        {
          id: "profile",
          label: "Profile",
          icon: <UserIcon />,
          to: CUSTOMER_PROFILE_PAGE_PATH,
          type: "link",
        },
        {
          type: "divider",
          id: "divider",
        },
        {
          id: "logout",
          label: "Logout",
          icon: <LogOutIcon />,
          showSpinner: authCenter.logOutInProgress,
          type: "button",
          onClick: () => {
            authCenter.logOut().then(() => {
              history.push("/login");
            });
          },
        },
      ],
    },
  ];

  function getMenuItems(menuItems: MenuItemType[]): any {
    return menuItems.map((menuItem: MenuItemType) => {
      if (menuItem.type === "link") {
        return (
          <AppBarMenuLink key={menuItem.id} to={menuItem.to || "/"}>
            {menuItem.label}
          </AppBarMenuLink>
        );
      } else if (menuItem.type === "button") {
        return (
          <AppBarMenuItem key={menuItem.id} onClick={() => {}}>
            {menuItem.label}
          </AppBarMenuItem>
        );
      } else if (menuItem.type === "submenu" && menuItem.subMenuItems) {
        return (
          <AppBarSubMenuItem key={menuItem.id} label={menuItem.label}>
            {getSubMenuItems(menuItem.subMenuItems)}
          </AppBarSubMenuItem>
        );
      } else {
        return null;
      }
    });
  }

  function getSubMenuItems(menuItems: MenuItemType[]): any {
    return menuItems.map((menuItem: MenuItemType) => {
      if (menuItem.type === "link") {
        return (
          <AppBarDropdownMenuLink
            key={menuItem.id}
            icon={menuItem.icon}
            to={menuItem.to || "/"}
          >
            {menuItem.label}
          </AppBarDropdownMenuLink>
        );
      } else if (menuItem.type === "button") {
        return (
          <AppBarDropdownMenuButton
            key={menuItem.id}
            icon={menuItem.icon}
            showSpinner={authCenter.logOutInProgress}
            onClick={menuItem.onClick}
          >
            {menuItem.label}
          </AppBarDropdownMenuButton>
        );
      } else if (menuItem.type === "divider") {
        return <Divider key={menuItem.id} />;
      } else {
        return null;
      }
    });
  }

  let menuConfig = canByRoles([userRoles.customer])
    ? customerMenuConfig
    : adminMenuConfig;

  return <React.Fragment>{getMenuItems(menuConfig)}</React.Fragment>;
}
