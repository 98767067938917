import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";
import PHDLogo from "components/uiComponents/PHDLogo";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#E7E7EE",
      margin: "56px 0 0",
      padding: "9px 0",
      [theme.breakpoints.up("xs")]: {
        margin: "10px 0 0",
      },
    },
    linksList: {
      "& a": {
        margin: "0 5px",
      },
    },
    content: {
      flexWrap: "wrap",
      justifyContent: "flex-end",
    },
  })
);

export default function MainLayout() {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <PHDLogo size={"small"} color={"secondary"} />

          <Box display={"flex"} ml={"10px"} className={classes.content}>
            <Box className={classes.linksList}>
              <Link href="/terms/ppTermsOfUse.pdf">Conditions of Use</Link>
              <Link href="#">Privacy Notice</Link>
              <Link href="#">Help</Link>
            </Box>
            <Box>
              Copyright 2021, Predictive Health Diagnostics or its affiliates
            </Box>
          </Box>
        </Box>
      </Container>
    </footer>
  );
}
