import React from "react";
import { newPasswordFormConfig } from "config/auth/authForm";
import { useAuthCenter } from "AuthCenter";
import TextButton from "components/uiComponents/buttons/TextButton";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";

export default function NewPasswordForm() {
  const authCenter = useAuthCenter();
  const { dataLoading, changePassword } = authCenter;

  const onSubmitValidForm = (data: any) => {
    changePassword(data.newPwd);
  };

  return (
    <AuthFormLayout
      inputsById={newPasswordFormConfig.inputsById}
      inputsByHash={newPasswordFormConfig.inputsByHash}
      onSubmitValidForm={onSubmitValidForm}
      isDataLoading={dataLoading}
      backButton={
        <TextButton
          onClick={() => {
            authCenter.setAuthStatus("login");
          }}
        >
          Back to login
        </TextButton>
      }
      submitLabel={"Login"}
    />
  );
}
