import React, { useState } from "react";
import { useAuthCenter } from "AuthCenter";
import { verifyCodeFormConfig } from "config/auth/authForm";
import { Typography } from "@material-ui/core";
import AuthFormLayout from "components/uiComponents/forms/AuthFormLayout";

export default function SmsMfaForm({
  onConfirmOk,
  onSubmitError = () => {},
}: {
  onConfirmOk: (accessToken: string) => void;
  onSubmitError?: (message: string) => void;
}) {
  const [dataLoading, setDataLoading] = useState(false);
  const { messageBar, confirmSignIn } = useAuthCenter();

  const onSubmitValidForm = async (data: any) => {
    messageBar.setMessage(null, null);
    setDataLoading(true);
    try {
      const accessToken = await confirmSignIn(data.code);
      onConfirmOk(accessToken);
      setDataLoading(false);
    } catch (e) {
      setDataLoading(false);
      onSubmitError(e.message);
    }
  };

  return (
    <AuthFormLayout
      inputsById={verifyCodeFormConfig.inputsById}
      inputsByHash={verifyCodeFormConfig.inputsByHash}
      onSubmitValidForm={onSubmitValidForm}
      isDataLoading={dataLoading}
      submitLabel={"Submit"}
      beforeInputs={
        <Typography>
          An authentication code has been sent to your phone.
          <br />
          Please enter the code below.
        </Typography>
      }
    />
  );
}
