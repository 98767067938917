import React from "react";
import { Switch } from "react-router-dom";
import MainLayout from "components/layouts/MainLayout.tsx";

import FrontPage from "components/pages/customer/FrontPage";
import PatientsPage from "components/pages/admin/PatientsPage";
import UsersPage from "components/pages/admin/UsersPage";
import MessagesPage from "components/pages/customer/MessagePage/MessagesPageContainer";
import LoginPage from "components/pages/auth/LoginPage";
import SignUpPage from "components/pages/auth/RegistrationPage";
import ForgotPasswordPage from "components/pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "components/pages/auth/ResetPasswordPage";
import ProfilePage from "components/pages/customer/ProfilePage/ProfilePage";
import SyncPage from "components/pages/admin/SyncPage";
import PatientSinglePage from "components/pages/admin/PatientSinglePage";
import AdminProfilePage from "components/pages/admin/ProfilePage";
import { userRoles } from "config/userRoles";

import SmartRoute from "./SmartRoute";
import GuestRoute from "./GuestRoute";

import Error404 from "components/pages/errors/Error404";
import Error403 from "components/pages/errors/Error403";

import {
  LOGIN_PAGE_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  ADMIN_PATIENTS_PAGE_PATH,
  ADMIN_USERS_PAGE_PATH,
  ADMIN_SYNC_PAGE_PATH,
  ADMIN_SINGLE_PATIENT_PAGE_BASE_PATH,
  ADMIN_PROFILE_PAGE_PATH,
  ADMIN_MESSAGES_PAGE_PATH,
  CUSTOMER_MESSAGES_PAGE_PATH,
  CUSTOMER_PROFILE_PAGE_PATH,
  CUSTOMER_ROOT_PATH,
} from "config/routes";

const Routes = () => (
  <Switch>
    <GuestRoute path={LOGIN_PAGE_PATH} component={LoginPage} />
    <GuestRoute path="/sign-up/:token" exact component={SignUpPage} />
    <GuestRoute
      path={FORGOT_PASSWORD_PAGE_PATH}
      exact
      component={ForgotPasswordPage}
    />
    <GuestRoute path={"/reset/:token"} exact component={ResetPasswordPage} />

    <GuestRoute
      path={FORGOT_PASSWORD_PAGE_PATH}
      exact
      component={ForgotPasswordPage}
    />

    <SmartRoute
      path={CUSTOMER_ROOT_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(FrontPage, {})}
      accessRoles={userRoles.customer}
    />

    <SmartRoute
      path={CUSTOMER_MESSAGES_PAGE_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(MessagesPage, {})}
      accessRoles={userRoles.customer}
    />

    <SmartRoute
      path={CUSTOMER_PROFILE_PAGE_PATH}
      layout={MainLayout}
      exact
      component={ProfilePage}
      accessRoles={userRoles.customer}
    />

    <SmartRoute
      path={ADMIN_PATIENTS_PAGE_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(PatientsPage, {})}
      accessRoles={userRoles.admin}
    />

    <SmartRoute
      path={ADMIN_USERS_PAGE_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(UsersPage, {})}
      accessRoles={userRoles.admin}
    />

    <SmartRoute
      path={ADMIN_SYNC_PAGE_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(SyncPage, {})}
      accessRoles={userRoles.admin}
    />

    <SmartRoute
      path={`${ADMIN_SINGLE_PATIENT_PAGE_BASE_PATH}/:id`}
      layout={MainLayout}
      exact
      component={() => React.createElement(PatientSinglePage, {})}
      accessRoles={userRoles.admin}
    />

    <SmartRoute
      path={ADMIN_PROFILE_PAGE_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(AdminProfilePage, {})}
      accessRoles={userRoles.admin}
    />

    <SmartRoute
      path={ADMIN_MESSAGES_PAGE_PATH}
      layout={MainLayout}
      exact
      component={() => React.createElement(MessagesPage, {})}
      accessRoles={userRoles.admin}
    />

    <SmartRoute path="/403" component={Error403} layout={MainLayout} />
    <SmartRoute component={Error404} layout={MainLayout} />
  </Switch>
);

export default Routes;
