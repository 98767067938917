import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";
import { FileItemType } from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { CloseIcon, ListIcon } from "components/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
      borderRadius: 5,
      padding: 4,
      display: "inline-flex",
    },
    rootError: {
      background: theme.palette.error.light ,
    },
    preview: {
      width: 33,
      height: 33,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: fade(theme.palette.primary2.main, 0.1),
      borderRadius: 5,
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: 5,
      },
    },
    fileDescription: {
      margin: "0 0 0 9px",
    },
    fileName: {
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.primary2.main,
    },
    fileSize: {
      fontSize: 11,
      color: `${fade(theme.palette.primary2.main, 0.6)}`,
    },
  })
);

export default function FilePreviewListItem({
  fileItem,
  onDelete,
}: {
  fileItem: FileItemType;
  onDelete: (name: string) => void;
}) {
  const classes = useStyles();
  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.rootError]: fileItem.error,
      })}
    >
      <Box>
        <Box className={classes.preview}>
          {fileItem.type === "image" ? (
            <img src={fileItem.url} alt="Download file preview" />
          ) : (
            <ListIcon />
          )}
        </Box>
      </Box>
      <Box className={classes.fileDescription}>
        <Box className={classes.fileName}>{fileItem.name}</Box>
        <Box className={classes.fileSize}>{fileItem.formattedSize}</Box>
      </Box>
      <Box ml={"4px"}>
        <IconButton
          onClick={() => {
            onDelete(fileItem.name);
          }}
        >
          <Box fontSize="11px" width={11} height={11}>
            <CloseIcon fontSize="inherit" />
          </Box>
        </IconButton>
      </Box>
    </Box>
  );
}
