import React from "react";
import Lock from "@material-ui/icons/Lock";
let yup = require("yup");

const newPasswordConfig = {
  label: "New Password",
  type: "password",
  required: true,
  validation: yup
    .string()
    .matches(/^(?=.*[0-9])/, "Password must contain at least 1 number.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])/,
      "Password must contain at least 1 uppercase and lowercase letter. "
    )
    .matches(
      /^(?=.*[!@#$%^&*()~])/,
      "Password must contain at least 1 special character !@#$%^&*()~"
    )
    .matches(/.{8}/, "8 characters minimum password length"),
  autocomplete: "new-password",
  icon: <Lock />,
};

export default newPasswordConfig;
