import React from "react";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { Column } from "components/uiComponents/table/Interfaces";
import ActionTableCell from "components/uiComponents/table/cells/ActionTableCell";

export default function HeaderTableCell({
  column,
  selectedRows,
  isActionActive,
  label,
  tableCellProps,
}: {
  column: Column;
  selectedRows: any;
  label: string;
  isActionActive: boolean;
  tableCellProps?: TableCellProps;
}) {
  return isActionActive ? (
    <ActionTableCell
      {...tableCellProps}
      actionInProgress={column!.actionProps!.inProgress!}
      buttonProps={{
        startIcon: column!.actionProps!.icon,
        onClick: (event) => {
          column!.actionProps!.onClick!(event, selectedRows);
        },
      }}
      buttonLabel={column!.actionProps!.bulkLabel!}
    />
  ) : (
    <TableCell {...tableCellProps}>{label}</TableCell>
  );
}
