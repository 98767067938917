export const userRoles = {
  admin: "ADM",
  customer: "CUST",
  guest: "GUEST",
};

export const customerSubRoles = {
  patientGuardian: "patientGuardian" as "patientGuardian",
  patientSelf: "patientSelf" as "patientSelf",
  guardianSelf: "guardianSelf" as "guardianSelf"
};

export const userGroups = {
  superAdmins: [userRoles.admin],
  allRegistered: [userRoles.admin, userRoles.customer],
  guests: [userRoles.guest],
};

export const userPermissions = {};
