import React from "react";
import { Typography, Avatar, Box, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ContentPasteIcon } from "components/icons";
import { fade } from "@material-ui/core/styles/colorManipulator";
import DependentInterface from "interfaces/DependentInterface";
import clsx from "clsx";
import BasePaper from "components/uiComponents/papers/BasePaper";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: "270px",

      margin: "0 10px",
      padding: "20px 10px 20px 25px",
      alignItems: "center",
      transition: "background-color 0.5s",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        "& $userName, & $status, & $infoButton": {
          color: "white",
        },
      },
    },
    rootSelected: {
      backgroundColor: theme.palette.primary.main,
      "& $userName, & $status, & $infoButton": {
        color: "white",
      },
    },
    userName: {
      fontSize: "18px",
      fontWeight: 600,
      color: theme.palette.primary2.main,
      transition: "color .5s",
    },
    status: {
      fontSize: "13px",
      fontWeight: 600,
      color: theme.palette.primary2.main,
      opacity: ".6",
      lineHeight: 1,
      transition: "color .5s",
    },
    infoButton: {
      background: fade(theme.palette.primary2.main, 0.5),
      transition: "color .5s background .5s",
      "&:hover": {
        background: fade(theme.palette.primary2.main, 0.3),
      },
    },
    infoButtonWrap: {
      margin: "0 0 0 auto",
    },
  })
);

export default function UserWidgetItem({
  data,
  isSelected = false,
  onClickInfo = () => {},
  onClick = () => {},
  dependentDataLength,
}: {
  data: DependentInterface;
  isSelected?: boolean;
  onClickInfo?: (event: React.MouseEvent<unknown>, id: number) => void;
  onClick?: () => void;
  dependentDataLength: number;
}) {
  const classes = useStyles();

  const getStatus = (status: "SELF", dependentDataLength: number) => {
    const config = {
      SELF: dependentDataLength > 1 ? "Guardian (You)" : "You",
      GUARDIAN: "Patient",
    };
    return config[status];
  };

  return (
    <BasePaper
      paperProps={{
        className: clsx({
          [classes.root]: true,
          [classes.rootSelected]: isSelected,
        }),
        onClick: onClick,
      }}
    >
      <React.Fragment>
        <Avatar aria-label="recipe">
          <Box top={"2px"} position={"relative"}>
            {data.patientFirstName.charAt(0)}
            {data.patientLastName.charAt(0)}
          </Box>
        </Avatar>
        <Box pl="10px">
          <Typography
            className={classes.userName}
          >{`${data.patientFirstName} ${data.patientLastName}`}</Typography>
          <Typography className={classes.status}>
            {getStatus(data.associationTypeCode, dependentDataLength)}{" "}
          </Typography>
        </Box>
        <Box className={classes.infoButtonWrap}>
          <Tooltip title="Patient Info" aria-label="add">
            <IconButton
              className={classes.infoButton}
              color="primary"
              aria-label="delete"
              onClick={(event) => {
                onClickInfo(event, data.patientId);
              }}
            >
              <ContentPasteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </React.Fragment>
    </BasePaper>
  );
}
