import React from "react";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Container } from "@material-ui/core";
import PersonalInfoForm from "components/pages/customer/ProfilePage/PersonalInfoForm";

export default function ProfilePage() {
  return (
    <Container>
      <BasePaper>
        <PersonalInfoForm />
      </BasePaper>
    </Container>
  );
}
