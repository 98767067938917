import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const patientFilterConfig: InputByHashConfigInterface = {
  inputsById: ["userName", "userFirstName", "userLastName"],

  inputsByHash: {
    userFirstName: {
      label: "First Name",
    },
    userLastName: {
      label: "Last Name",
    },
    userName: {
      label: "Username",
    },
    
  },
};

export default patientFilterConfig;
