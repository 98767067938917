import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { fade } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import clsx from "clsx";

export type SubVariantClasses = "subTypePrimary" | "subTypeIcon";
export type statusClasses = "statusActive" | "statusNormal";

export interface AppBarMenuItemProps extends ButtonProps {
  disabled?: boolean;
  showSpinner?: boolean;
  icon?: JSX.Element;
  disableLabel?: boolean;
  subVariant?: "primary" | "icon";
  status?: "active" | "normal";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "13px",
      fontWeight: 600,
      borderRadius: 18,
      textTransform: "none",
      borderColor: "transparent",
      "&:hover": {
        color: theme.palette.primary.main,
        borderColor: fade(theme.palette.primary.main, 0.4),
      },
    },

    subTypePrimary: {
      padding: "3px 9px",
      "& .MuiButton-startIcon": {
        marginRight: "3px",
      },
      "& .MuiButton-endIcon": {
        marginLeft: "3px",
      },
    },
    subTypeIcon: {
      padding: "3px 5px",
      minWidth: "auto",
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
    statusNormal: {
      "& .MuiButton-startIcon": {
        color: fade(theme.palette.primary2.main, 0.8),
        fontSize: "inherit",
      },
    },
    statusActive: {
      "& .MuiButton-startIcon": {
        color: fade(theme.palette.primary.main, 0.8),
      },
    },
  })
);

const PaperControlButton = React.forwardRef<
  HTMLButtonElement,
  AppBarMenuItemProps
>(
  (
    {
      children,
      disabled = false,
      showSpinner = false,
      disableLabel = false,
      icon,
      subVariant = "primary",
      status = "normal",
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const subVariantClass = `subType${capitalizeFirstLetter(
      subVariant
    )}` as SubVariantClasses;

    const statusClass = `status${capitalizeFirstLetter(
      status
    )}` as statusClasses;

    return (
      <Button
        ref={ref}
        variant="outlined"
        color="primary"
        disabled={disabled || showSpinner}
        className={clsx(
          classes.root,
          classes[subVariantClass],
          classes[statusClass]
        )}
        startIcon={(showSpinner && <CircularProgress size={20} />) || icon}
        {...rest}
      >
        {!disableLabel && (
          <Box position="relative" top="2px">
            {children}
          </Box>
        )}
      </Button>
    );
  }
);

export default PaperControlButton;
