import React, { useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import SmartTable, {
  SmartTableRef,
} from "components/uiComponents/table/SmartTable";
import patientListTableConfig from "config/admin/patients/patientListTableConfig";
import { Box } from "@material-ui/core";
import PaperTable from "components/uiComponents/papers/AdminTablePaper";
import SearchFilter from "components/uiComponents/Filter/SearchFilter";
import { enhanceActionConfig } from "utils";
import usePostProvider from "hooks/usePostProvider";
import { useSnackbar } from "notistack";
import filterConfig from "config/admin/patients/patientFilterConfig";
import { useHistory } from "react-router-dom";
import { ADMIN_SINGLE_PATIENT_PAGE_BASE_PATH } from "config/routes";

export default function PatientsPage() {
  const [filter, setFilter] = useState({});
  const tableRef = useRef<SmartTableRef>(null);
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const {
    postData: sendInvitation,
    dataLoading: sendInvitationInProgress,
  } = usePostProvider("post");

  const handleSubmitReSendInvitation = async (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    event.stopPropagation();
    try {
      await sendInvitation(`patients/${row.patientId}/invite`, { data: {} });
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const handleFilter = (filter: { [index: string]: any }) => {
    tableRef!.current!.resetCurrentPage();
    setFilter(filter);
  };

  const tableActionsConfig = {
    resend: {
      onClick: handleSubmitReSendInvitation,
      inProgress: sendInvitationInProgress,
    },
  };

  const tableConfigWithClickHandlers = enhanceActionConfig(
    patientListTableConfig,
    tableActionsConfig
  );

  const handleClickRow = (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    history.push(`${ADMIN_SINGLE_PATIENT_PAGE_BASE_PATH}/${row.id}`);
  };

  return (
    <Container>
      <PaperTable
        title={"Patient List"}
        controls={
          <Box display="flex">
            <SearchFilter
              onSubmitFilter={handleFilter}
              showSearch={false}
              filterConfig={filterConfig}
            />
          </Box>
        }
      >
        <SmartTable
          ref={tableRef}
          id="patientsList"
          resource={`patients`}
          onClickRow={handleClickRow}
          columns={tableConfigWithClickHandlers}
          showPagination={true}
          filter={filter}
        />
      </PaperTable>
    </Container>
  );
}
