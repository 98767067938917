import React from "react";
import TextInput from "components/uiComponents/inputs/TextInput";
import SmartInputProps from "interfaces/SmartInputInterface";
import ButtonRadio from "components/uiComponents/inputs/ButtonRadio";
import BirthdayInput from "components/uiComponents/inputs/BirthdayInput";
import PhoneInput from "components/uiComponents/inputs/PhoneInput";
import SmartAutocompleteInput from "components/uiComponents/inputs/SmartInputs/SmartAutocompleteInput";
import SmartDateInput from "components/uiComponents/inputs/SmartInputs/SmartDateInput";
import SmartDateRangeInput from "components/uiComponents/inputs/SmartInputs/SmartDateRangeInput";
import SmartCheckboxInput from "components/uiComponents/inputs/SmartInputs/SmartCheckboxInput";
import SmartSelectInput from "components/uiComponents/inputs/SmartInputs/SmartSelectInput";
import SmartPasswordInput from "components/uiComponents/inputs/SmartInputs/SmartPasswordInput";

const SmartInput = (props: SmartInputProps) => {
  switch (props.input.type) {
    case "birthday": {
      return <BirthdayInput {...props} />;
    }
    case "buttonRadio": {
      return <ButtonRadio {...props} />;
    }
    case "password": {
      return <SmartPasswordInput {...props} />;
    }
    case "phone": {
      return <PhoneInput {...props} />;
    }
    case "autocomplete": {
      return <SmartAutocompleteInput {...props} />;
    }
    case "date": {
      return <SmartDateInput {...props} />;
    }
    case "dateRange": {
      return <SmartDateRangeInput {...props} />;
    }
    case "checkbox": {
      return <SmartCheckboxInput {...props} />;
    }
    case "select": {
      return <SmartSelectInput {...props} />;
    }
    default:
      return <TextInput {...props} />;
  }
};

export default SmartInput;
