import React from "react";
import { Column } from "components/uiComponents/table/Interfaces";
import { convertCodeToStatus } from "utils";
import CachedIcon from "@material-ui/icons/Cached";
import formatDateTime from "utils/formatDateTime";
import StatusCellValue from "components/uiComponents/table/cells/StatusCellValue";

const statusConfig = {
  error: ["EXP", "UNSENT"],
  warning: "VALID",
  success: "CMP",
};

const tableConfig: Column[] = [
  {
    id: "patientLastName",
    label: "Patient Name",
    tableCellStyles: "font-weight: 600;",
    accessor: (d) => `${d.patientLastName}, ${d.patientFirstName}`,
    mobileConfig: {
      options: {
        visibleLabel: false,
      },
      gridProps: {
        xs: 12,
      },
      valueStyles: "font-size: 15px; font-weight: 700;",
    },
  },

  {
    id: "patientDob",
    label: "Date of birth",
  },

  {
    id: "registrationStatusCode",
    label: "Registration Status",
    accessor: (d) => {
      return (
        <StatusCellValue
          value={d.registrationStatusDesc}
          status={
            convertCodeToStatus(statusConfig, d.registrationStatusCode) as
              | "success"
              | "warning"
              | "error"
          }
        />
      );
    },
    mobileConfig: {
      gridProps: {
        xs: 8,
      },
    },
  },

  {
    id: "lastUpdateDate",
    label: "Last Update Date",
    accessor: (d) => formatDateTime(d.lastUpdateDate, "dateTime"),
    mobileConfig: {
      gridProps: {
        xs: 8,
      },
    },
  },
  {
    id: "resend",
    type: "action",
    actionProps: {
      label: "Re-send an invitation",
      icon: <CachedIcon />,
      visibilityAccessorByValue: (d) => d.registrationStatusCode !== "CMP",
    },
  },
];

export default tableConfig;
