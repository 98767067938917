import React from "react";
import { PaperProps } from "@material-ui/core/Paper";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Typography, Box } from "@material-ui/core";
import PHDLogo from "components/uiComponents/PHDLogo";
import FormMessageBar, {
  FormMessageBarProps,
} from "components/uiComponents/forms/FormMessageBar";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

export default function AuthPaper({
  paperProps,
  children,
  title,
  formMessageBar,
  showProgressBar = false,
  showLogo = true,
}: {
  paperProps?: PaperProps;
  children: JSX.Element[] | JSX.Element | string;
  icon?: JSX.Element;
  title?: string;
  formMessageBar?: FormMessageBarProps;
  showProgressBar?: boolean;
  showLogo?: boolean;
}) {
  return (
    <BasePaper {...paperProps}>
      <Box padding={{ xs: "10px 10px", sm: "10px 26px" }} position={"relative"}>
        {showLogo && <PHDLogo />}
        {showProgressBar && <CircularProgressShadow />}

        <Box color="primary2.main" pt={showLogo ? "0" : "12px"}>
          <Typography variant={"h1"}>{title}</Typography>
        </Box>

        <React.Fragment>
          {formMessageBar && (
            <FormMessageBar
              title={formMessageBar!.title}
              type={formMessageBar!.type}
            >
              {formMessageBar!.children}
            </FormMessageBar>
          )}
        </React.Fragment>

        <Box marginTop={{ xs: 3, sm: 2 }}>{children}</Box>
      </Box>
    </BasePaper>
  );
}
