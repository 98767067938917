import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function SortIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.5,6.8l3.1-3.1c0.4-0.4,0.9-0.4,1.3,0L11,6.8c0.5,0.5,0.4,1.3-0.2,1.6c-0.4,0.3-1,0.2-1.4-0.2L8.3,7v13.1
        c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1V7.1L5.1,8.2C4.7,8.6,4.1,8.7,3.7,8.4C3.1,8,3.1,7.2,3.5,6.8z M17.8,21
        c-0.4,0.4-0.9,0.4-1.3,0l-3.2-3.1c-0.5-0.5-0.4-1.3,0.2-1.6c0.4-0.3,1-0.2,1.4,0.2l1.2,1.2V4.4c0-0.6,0.5-1.1,1.1-1.1
        c0.6,0,1.1,0.5,1.1,1.1v13.2l1.2-1.2c0.4-0.4,0.9-0.4,1.4-0.2c0.6,0.4,0.6,1.2,0.2,1.6L17.8,21z"
      />
    </SvgIcon>
  );
}