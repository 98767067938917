import React, { useState } from "react";
import usePostProvider from "hooks/usePostProvider";
import useProvider from "hooks/useProvider";
import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthCenter } from "AuthCenter";
import { GET_LIST } from "providers/dataProvider";
import { fade } from "@material-ui/core/styles/colorManipulator";
import TextButton from "components/uiComponents/buttons/TextButton";
import { CloseIcon } from "components/icons";
import ConfirmModal from "components/modals/ConfirmModal";
import useModal from "hooks/useModal";

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${fade("#445369", 0.2)}`,
    fontSize: "13px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
    alignItems: "center",
  },
  buttonWrap: {
    color: theme.palette.error.main,
  },
  button: {
    display: "flex",
    textDecoration: "none",
    fontWeight: 700,
    fontSize: "11px",
  },
}));

export default function PatientsUnlinkList() {
  const authCenter = useAuthCenter();
  const classes = useStyles();
  const userId = authCenter.access.userId;
  const [patient, setPatient] = useState<null | { [index: string]: any }>(null);
  const [isModalOpen, openModal, closeModal] = useModal();

  const {
    data: patients,
    dataLoading: dependentDataLoading,
    loadData: loadDependentData,
  } = useProvider(GET_LIST, `users/${userId}/dependent`);

  const { postData, dataLoading: unlinkInProgress } = usePostProvider(
    "post",
    `patients/unlink`
  );

  const handleConfirmUnlink = async () => {
    if (patient) {
      console.log("postData");
      try {
        await postData(null, {
          data: {
            patientUserId: patient.patientUserId,
          },
        });
        closeModal();
        loadDependentData();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleClickUnlink = (patient: { [index: string]: any }) => {
    setPatient(patient);
    openModal();
  };

  return (
    <React.Fragment>
      <ConfirmModal
        open={isModalOpen}
        closeModal={closeModal}
        title={"Unlink Patient"}
        onClickYes={handleConfirmUnlink}
        controlPharase={patient && patient!.patientFirstName}
        controlPharaseLabel={"Patient First Name"}
      >
        <Box>Removing link between patient and guardian<br></br> cannot be undone.</Box>
      </ConfirmModal>
      <Box maxWidth={"460px"} padding={"13px 0 0"}>
        {dependentDataLoading && <CircularProgress />}
        {patients.map((patient: { [index: string]: any }) => {
          if (patient.associationTypeCode === "GUARDIAN") {
            return (
              <Box className={classes.listItem} key={patient.id}>
                <Box>
                  {patient.patientFirstName} {patient.patientLastName}
                </Box>
                <Box className={classes.buttonWrap}>
                  <TextButton
                    color={"inherit"}
                    className={classes.button}
                    onClick={() => handleClickUnlink(patient)}
                    disabled={unlinkInProgress}
                  >
                    <Box pr={"8px"} margin={"auto 0"} display={"flex"}>
                      <CloseIcon fontSize={"inherit"} />
                    </Box>
                    <Box top={"2px"} position={"relative"}>
                      UNLINK
                    </Box>
                  </TextButton>
                </Box>
              </Box>
            );
          }
          return null;
        })}
      </Box>
    </React.Fragment>
  );
}
