import React, { useEffect, useState } from "react";
import UserWidgetList from "components/uiComponents/userWidgets/UserWidgetList";
import useProvider from "hooks/useProvider";
import { GET_LIST } from "providers/dataProvider";
import { useAuthCenter } from "AuthCenter";
import SinglePatientContent from "components/pages/customer/SinglePatientContent";
import TermsOfUseModal from "components/modals/TermsOfUseModal";
import { Box, Container } from "@material-ui/core";
import useModal from "hooks/useModal";

const FrontPage = () => {
  const authCenter = useAuthCenter();
  const [selectedPatientId, setSelectedPatietId] = useState<null | number>(
    null
  );
  const [modalIsOpen, openModal, closeModal] = useModal();

  const {
    data: dependentData,
    dataLoaded: isDependentDataLoaded,
  } = useProvider(
    GET_LIST,
    `users/${authCenter.access.userId}/dependent`,
    {},
    onDependentLoadData
  );

  function onDependentLoadData(data: []) {
    data.forEach((item: { patientId: number }, index) => {
      if (index === 0) {
        setSelectedPatietId(item.patientId);
      } else {
        return;
      }
    });
  }

  const handleSelectPateint = (patientId: number) => {
    setSelectedPatietId(patientId);
  };

  useEffect(() => {
    if (authCenter.isShowTerm === 1) {
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCenter.isShowTerm]);


  return (
    <React.Fragment>
      <TermsOfUseModal open={modalIsOpen} closeModal={closeModal}>
        <Box></Box>
      </TermsOfUseModal>
      <div>
        <Container>
          <UserWidgetList
            selectedPatientId={selectedPatientId}
            setSelectedPatietId={handleSelectPateint}
            dependentData={dependentData}
            isDependentDataLoaded={isDependentDataLoaded}
          />
        </Container>

        <SinglePatientContent
          patientId={selectedPatientId}
        />
      </div>
    </React.Fragment>
  );
};

export default FrontPage;
