import React, {
  useEffect,
  useCallback,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";
import {
  Box,
} from "@material-ui/core";
import dataProvider, { GET_LIST } from "providers/dataProvider";
import axios from "axios";
import BaseTable from "components/uiComponents/table/BaseTable";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "components/uiComponents/table/TablePaginationActions";
import urlQuery from "utils/urlQuery";
import { SmartTableProps } from "components/uiComponents/table/Interfaces";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

const getFromUrl = (key: string, tableId: string) => {
  const currentTableParams = urlQuery.getByKey(tableId);

  try {
    return currentTableParams[key];
  } catch {
    return null;
  }
};

const pushToUrl = (object: object, tableId: string) => {
  const currentTableParams = urlQuery.getByKey(tableId);

  const newTableParams = { ...currentTableParams, ...object };

  urlQuery.push({
    [tableId]: JSON.stringify(newTableParams),
  });
};

export interface SmartTableRef {
  loadData(): void;
  resetCurrentPage(): void;
}

const SmartTable = forwardRef<SmartTableRef, SmartTableProps>(
  (
    {
      columns = [],
      showPagination = false,
      filter = null,
      rowSelect = false,
      rowIdByKey = "id",
      onClickRow,
      mobileConfig,
      ...props
    },
    ref
  ) => {
    const tableId = `table-${props.id}`;

    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    const initPage = parseInt(getFromUrl("page", tableId)) || 0;
    const initPerPage = parseInt(getFromUrl("perPage", tableId)) || 10;
    const initOrderBy = getFromUrl("orderBy", tableId) || "id";
    const initOrder = getFromUrl("order", tableId) || "asc";

    const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);
    const [rows, setRows] = useState(props.rows || []);
    const [isDataLoading, setDataLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(initPage);
    const [perPage, setPerPage] = useState(initPerPage);
    const [order, setOrder] = useState(initOrder);
    const [orderBy, setOrderBy] = useState(initOrderBy);

    const loadData = useCallback(() => {
      setDataLoading(true);
      if (props.resource === null) return;
      dataProvider(
        GET_LIST,
        props.resource,
        {
          filter: filter ? filter : {},
          pagination: {
            page: page + 1,
            perPage: perPage,
          },
          sort: {
            field: orderBy,
            order: order,
          },
        },

        {
          cancelToken: source.token,
        }
      )
        .then(({ data, total }) => {
          setDataLoading(false);
          setTotalRows(total);
          setRows(data);
        })
        .catch((e) => {});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resource, filter, page, perPage, orderBy, order]);

    useImperativeHandle(ref, () => ({
      loadData: () => {
        loadData();
      },
      resetCurrentPage: () => {
        resetCurrentPage();
      },
    }));

    useEffect(() => {
      loadData();
      return function () {
        source.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadData]);

    const resetCurrentPage = () => {
      setPage(0);
      pushToUrl({ page: "0" }, tableId);
    };

    const applyUrlQuery = useCallback(() => {
      const page = parseInt(getFromUrl("page", tableId)) || 0;
      const perPage = parseInt(getFromUrl("perPage", tableId)) || 10;
      const orderBy = getFromUrl("orderBy", tableId) || "id";
      const order = getFromUrl("order", tableId) || "asc";

      setPage(page);
      setPerPage(perPage);
      setOrderBy(orderBy);
      setOrder(order);
    }, [setPage, setPerPage, setOrderBy, setOrder, tableId]);

    const handlePopStateChange = useCallback(() => {
      applyUrlQuery();
    }, [applyUrlQuery]);

    useEffect(() => {
      window.addEventListener("popstate", handlePopStateChange);
      return () => window.removeEventListener("popstate", handlePopStateChange);
    }, [handlePopStateChange]);

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
      pushToUrl({ page: newPage.toString() }, tableId);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setPerPage(parseInt(event.target.value, 10));
      setPage(0);
      pushToUrl({ perPage: event.target.value }, tableId);
    };

    const handleSetOrderBy = (newOrderBy: string) => {
      const pushUrlObj = {} as { orderBy: string; order: string };
      if (newOrderBy !== orderBy) {
        setOrderBy(newOrderBy);
        setOrder("asc");

        pushUrlObj.orderBy = newOrderBy;
        pushUrlObj.order = "asc";
      } else {
        const newOrder = order === "asc" ? "desc" : "asc";
        setOrder(newOrder);
        pushUrlObj.order = newOrder;
      }

      pushToUrl(pushUrlObj, tableId);
    };

    const handleSelectRow = (
      event: React.ChangeEvent<HTMLInputElement>,
      id: string
    ) => {
      const selectedIndex = selectedRowIds.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRowIds, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRowIds.slice(1));
      } else if (selectedIndex === selectedRowIds.length - 1) {
        newSelected = newSelected.concat(selectedRowIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRowIds.slice(0, selectedIndex),
          selectedRowIds.slice(selectedIndex + 1)
        );
      }

      setSelectedRowIds(newSelected);
    };

    const handleSelectAllClick = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((row: { id: string }) => row.id);
        setSelectedRowIds(newSelecteds);
        return;
      }
      setSelectedRowIds([]);
    };

    return (
      <React.Fragment>
        <Box position="relative">
          {isDataLoading && <CircularProgressShadow />}
          <BaseTable
            rows={rows}
            rowIdByKey={rowIdByKey}
            columns={columns}
            isDataLoading={isDataLoading}
            order={order}
            orderBy={orderBy}
            setOrderBy={handleSetOrderBy}
            handleSelectRow={handleSelectRow}
            handleRowClick={onClickRow}
            onSelectAllClick={handleSelectAllClick}
            selectedRowIds={selectedRowIds}
            rowSelect={rowSelect}
            mobileConfig={mobileConfig}
          />
          {showPagination && rows.length > 0 && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={totalRows}
              rowsPerPage={perPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          )}
        </Box>
      </React.Fragment>
    );
  }
);

export default SmartTable;
