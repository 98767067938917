import React from "react";
import TableRow from "@material-ui/core/TableRow";
import { Column } from "components/uiComponents/table/Interfaces";
import ActionTableCell from "components/uiComponents/table/cells/ActionTableCell";
import HyperlinkActionTableCell from "components/uiComponents/table/cells/HyperlinkActionTableCell";
import CheckboxTableCell from "components/uiComponents/table/cells/CheckboxTableCell";
import StyledTableCell from "components/uiComponents/table/cells/StyledTableCell";

type BaseTableRowProps = {
  id: string;
  isSelected: boolean;
  row: { [key: string]: any };
  columns: Column[];
  handleRowClick?: ((
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => void) | null;
  handleSelectRow?: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  rowSelect: boolean;
};

const BaseTableRow = ({
  id,
  isSelected,
  columns,
  row,
  rowSelect,
  handleRowClick = null,
  handleSelectRow = () => {},
}: BaseTableRowProps) => {
  
  return (
    <TableRow
      onClick={(event) => handleRowClick && handleRowClick(event, row)}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      hover={handleRowClick ? true : false}
    >
      {rowSelect && (
        <CheckboxTableCell
          key={`${id}-checkbox`}
          checkboxProps={{
            checked: isSelected,
            onChange: (event: any) => {
              handleSelectRow(event, id);
            },
          }}
        />
      )}

      {columns.map((column) => {
        let rowValue = column.accessor ? column.accessor(row) : row[column.id];

        if (column.type === "action") {
          return (
            <ActionTableCell
              key={`${id}-${column.id}`}
              actionInProgress={column!.actionProps!.inProgress!}
              buttonLabel={column!.actionProps!.label!}
              buttonVariant={column!.actionProps!.buttonVariant!}
              tableCellStyles={column!.tableCellStyles}
              visibilityByValue={
                column!.actionProps!.visibilityAccessorByValue! &&
                column!.actionProps!.visibilityAccessorByValue!(row)
              }
              buttonProps={{
                startIcon: column!.actionProps!.icon,
                onClick: (event) => {
                  column!.actionProps!.onClick!(event, row);
                },
              }}
            />
          );
        } else if (column.type === "hyperlinkAction") {
          return (
            <HyperlinkActionTableCell
              key={`${id}-${column.id}`}
              buttonLabel={rowValue}
              actionInProgress={column!.actionProps!.inProgress!}
              buttonProps={{
                onClick: (event) => {
                  column!.actionProps!.onClick!(event, row);
                },
              }}
            />
          );
        } else {
          return (
            <StyledTableCell
              key={`${id}-${column.id}`}
              align="left"
              size="medium"
              styles={column.tableCellStyles}
            >
              {rowValue}
            </StyledTableCell>
          );
        }
      })}
    </TableRow>
  );
};

export default BaseTableRow;
