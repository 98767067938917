import React, { useState, useRef } from "react";
import { PaperWithTitle } from "components/uiComponents/papers";
import SmartTable, {
  SmartTableRef,
} from "components/uiComponents/table/SmartTable";
import relatedPeopleTableConfig from "config/admin/patients/relatedPeopleTableConfig";
import { enhanceActionConfig } from "utils";
import AddPostModal, { AddPostModalRef } from "components/modals/AddPostModal";
import relatedUserEditFormConfig from "config/admin/patients/relatedUserEditFormConfig";
import useModal from "hooks/useModal";
import usePostProvider from "hooks/usePostProvider";
import { useSnackbar } from "notistack";
import { Box } from "@material-ui/core";
import ConfirmModal from "components/modals/ConfirmModal";

export default function RelatedPeopleTable({
  patientId = null,
  patientFirstName = null,
}: {
  patientId: number | null;
  patientFirstName: string | null;
}) {
  const [modalIsOpen, openModal, closeModal, modalId] = useModal();
  const editPostModalRef = useRef<AddPostModalRef>(null);
  const tableRef = useRef<SmartTableRef>(null);
  const [patient, setPatient] = useState<null | { [index: string]: any }>(null);
  const {
    postData: editPatient,
    dataLoading: editUserInProgress,
  } = usePostProvider("put");
  const { postData, dataLoading: unlinkInProgress } = usePostProvider(
    "post",
    `patients/unlink`
  );
  const { enqueueSnackbar } = useSnackbar();
  const [editData, setEditData] = useState<{ [index: string]: any }>({});

  if (!patientId) return null;

  const handleClickEdit = (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    setPatient(row);
    openModal("edit");
    setEditData(row);
  };

  const handleClickUnlink = (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    setPatient(row);
    openModal("unlink");
  };

  const handleConfirmUnlink = async () => {
    try {
      await postData(null, {
        data: {
          patientUserId: patient!.patientUserId,
        },
      });
      closeModal();
      tableRef!.current!.loadData();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const tableActionsConfig = {
    edit: {
      onClick: handleClickEdit,
      inProgress: editUserInProgress,
    },
    unlink: {
      onClick: handleClickUnlink,
      inProgress: unlinkInProgress,
    },
  };

  const tableConfigWithClickHandlers = enhanceActionConfig(
    relatedPeopleTableConfig,
    tableActionsConfig
  );

  let relatedUserEditFormConfigWithPermissions = {
    ...relatedUserEditFormConfig.inputsByHash,
  };
  if (patient) {
    relatedUserEditFormConfigWithPermissions = {
      ...relatedUserEditFormConfig.inputsByHash,
      ...{
        userFirstName: {
          disabled: patient!.associationTypeCode !== "GUARDIAN",
        },
        userLastName: {
          disabled: patient!.associationTypeCode !== "GUARDIAN",
        },
      },
    };
  }

  const onSubmitValidEdit = async (data: any) => {
    try {
      await editPatient(`mfa/users/${editData.userId}`, {
        data,
      });
      closeModal();
      tableRef!.current!.loadData();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal
        open={modalIsOpen && modalId === "unlink"}
        closeModal={closeModal}
        title={"Unlink Patient"}
        onClickYes={handleConfirmUnlink}
        controlPharase={patient && patient!.patientFirstName}
        controlPharaseLabel={"Patient First Name"}
        showProgress={unlinkInProgress}
      >
        <Box>
          Removing link between patient and guardian<br></br> cannot be undone.
        </Box>
      </ConfirmModal>
      <AddPostModal
        ref={editPostModalRef}
        modalIsOpen={modalIsOpen && modalId === "edit"}
        closeModal={closeModal}
        title={"Edit User"}
        inputsById={relatedUserEditFormConfig.inputsById}
        inputsByHash={relatedUserEditFormConfigWithPermissions}
        onSubmitValidForm={onSubmitValidEdit}
        submitLabel={"Save"}
        isDataLoading={editUserInProgress}
        initData={editData}
      />
      <PaperWithTitle
        title={`Authorized Portal Users for ${patientFirstName}`}
        color="primary2"
      >
        <SmartTable
          ref={tableRef}
          id="clientList"
          rowIdByKey={"id"}
          resource={`patients/${patientId}/users`}
          columns={tableConfigWithClickHandlers}
        />
      </PaperWithTitle>
    </React.Fragment>
  );
}
