import React from "react";
import { TableCell, TableRow, Box } from "@material-ui/core";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableMobileActionButton from "components/uiComponents/buttons/TableMobileActionButton";
import HyperlinkActionTableCell from "components/uiComponents/table/cells/HyperlinkActionTableCell";
import { BaseTableRowProps } from "components/uiComponents/table/Interfaces";

import styled from "styled-components";

const defaultValueStyles = ``;
const StyledValue = styled.div`
  && {
    ${defaultValueStyles}
    ${(props: { styles?: string }) => props.styles}
  }
`;

const defaultGridInnerWrapperStyles = ``;
const StyledGridInnerWrapper = styled.div`
  && {
    ${defaultGridInnerWrapperStyles}
    ${(props: { styles?: string }) => props.styles}
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: "11px",
      textTransform: "uppercase",
      color: fade(theme.palette.primary2.main, 0.6),
      fontWeight: 600,
      borderBottom: "none",
    },
  })
);

export default function MobileTableRow({
  id,
  isSelected,
  columns,
  row,
  mobileConfig,
  handleRowClick = () => {},
  handleSelectRow = () => {},
}: BaseTableRowProps) {
  const classes = useStyles();
  const actions = [] as JSX.Element[];

  const actionsGridProps = {
    xs: 4,
    ...mobileConfig?.actionsCol?.gridProps,
  } as GridProps;

  return (
    <TableRow
      hover
      onClick={(event) => handleRowClick && handleRowClick(event, row)}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
    >
      <TableCell padding={"none"} align="left" size="medium">
        <Box style={{ overflow: "hidden", padding: "8px 0" }}>
          <Grid container spacing={1}>
            {columns.map((column) => {
              let rowValue = column.accessor
                ? column.accessor(row)
                : row[column.id];

              const mobileConfig = {
                valueStyles: `${column?.mobileConfig?.valueStyles}`,
                options: {
                  visibleLabel: true,
                  ...column?.mobileConfig?.options,
                },
                gridProps: {
                  item: true,
                  xs: 4,
                  ...column?.mobileConfig?.gridProps,
                } as GridProps,
              };

              if (column.type === "action") {
                actions.push(
                  <Box textAlign="right">
                    <TableMobileActionButton
                      icon={column!.actionProps!.icon}
                      buttonProps={{
                        onClick: (event) => {
                          column!.actionProps!.onClick!(event, row);
                        },
                      }}
                    />
                  </Box>
                );
                return null;
              } else if (column.type === "hyperlinkAction") {
                return (
                  <HyperlinkActionTableCell
                    key={`${id}-${column.id}`}
                    buttonLabel={
                      <StyledValue styles={mobileConfig.valueStyles}>
                        {rowValue}
                      </StyledValue>
                    }
                    actionInProgress={column!.actionProps!.inProgress!}
                    buttonProps={{
                      onClick: (event) => {
                        column!.actionProps!.onClick!(event, row);
                      },
                    }}
                  />
                );
              } else {
                return (
                  <Grid {...mobileConfig.gridProps}>
                    <StyledGridInnerWrapper
                      styles={column?.mobileConfig?.gridInnerWrapperStyles}
                    >
                      {mobileConfig.options.visibleLabel && (
                        <div className={classes.label}>{column.label}</div>
                      )}

                      <StyledValue styles={column?.mobileConfig?.valueStyles}>
                        {rowValue}
                      </StyledValue>
                    </StyledGridInnerWrapper>
                  </Grid>
                );
              }
            })}
            <Grid {...actionsGridProps}>
              <Grid container justify={"flex-end"}>
                {actions.map((action) => {
                  return action;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TableCell>
    </TableRow>
  );
}
