import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const helpRequestForm: InputByHashConfigInterface = {
  inputsById: [
    "userName",
    "userFirstName",
    "userLastName",
    "userPhone",
    "userEmail",
  ],

  inputsByHash: {
    userName: {
      label: "Username",
      required: true,
      disabled: true,
    },
    userFirstName: {
      label: "First Name",
      required: true,
    },
    userLastName: {
      label: "Last Name",
      required: true,
    },
    userPhone: {
      label: "Phone Number",
      type: "phone",
      required: true,
    },
    userEmail: {
      label: "Email",
      required: true,
    },
  },
};


export default helpRequestForm;
