import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { MagnifierIcon } from "components/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

let timeout: number;

const useStyles = makeStyles(() => ({
  helperText: {
    lineHeight: 1.1,
    letterSpacing: 0,
  },
  input: {
    padding: "11px 5px",
  },
}));

export default function Search({
  handleInput,
}: {
  handleInput: (value: string) => void;
}) {
  const classes = useStyles();

  const handleInputWithDelay = (e: any) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      handleInput(e.target.value);
    }, 1000);
  };

  return (
    <TextField
      variant="outlined"
      label={"Search"}
      type={"search"}
      onChange={handleInputWithDelay}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputProps={{
        classes: { input: classes.input },

        startAdornment: (
          <InputAdornment position="start">
            <MagnifierIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
