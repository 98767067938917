import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import moment from "moment";
import { userRoles } from "config/userRoles";

const threadListFilterConfiguration: InputByHashConfigInterface = {
  inputsById: [
    "isArchived",
    "isRead",
    "isFollowUp",
    "msgDate",
    "fromFirstName",
    "fromLastName",
  ],

  inputsByHash: {
    isArchived: {
      label: "Archived",
      type: "select",
      options: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Archived",
          value: 1,
        },
        {
          label: "Unarchived",
          value: 0,
        },
      ],
      value: 0,
    },
    isRead: {
      label: "Unread",
      type: "select",
      options: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Unread",
          value: 0,
        },
        {
          label: "Read",
          value: 1,
        },
      ],
    },
    isFollowUp: {
      label: "Follow Up",
      type: "select",
      options: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Follow up",
          value: 1,
        },
        {
          label: "None follow up",
          value: 0,
        },
      ],
      hiddenForRoles: [userRoles.customer],
    },
    fromFirstName: {
      label: "First Name",
    },
    fromLastName: {
      label: "Last Name",
    },
    msgDate: {
      type: "dateRange",
      label: "From",
      submitValueAccessor: (d) => {
        return `[${moment(d.from).format("MM/DD/YYYY")}-${moment(d.to).format(
          "MM/DD/YYYY"
        )}]`;
      },
    },
  },
};

export default threadListFilterConfiguration;
