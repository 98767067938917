import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function SortAscIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.9,17.8c-0.4,0-0.7-0.3-0.7-0.7V8.8l-1.1,1.1c-0.3,0.3-0.7,0.3-0.9,0s-0.3-0.7,0-0.9l2.2-2.2l0,0l0,0v0
        c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0.1,0,0.1-0.1l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0
        l0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0.1,0,0.1-0.1,0.2-0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
        c0.2,0,0.4,0,0.6,0.2l0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
        c0,0,0,0,0,0v0l0,0l0,0l2.5,2.5c0.3,0.3,0.3,0.7,0,0.9s-0.7,0.3-0.9,0l-1.3-1.3v8.3C18.5,17.5,18.3,17.8,17.9,17.8z M8.9,17.4H3.7
        c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h5.2c0.4,0,0.7,0.3,0.7,0.7S9.3,17.4,8.9,17.4z M10.5,14.1H3.7c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.4,0.3-0.7,0.7-0.7h6.9c0.4,0,0.7,0.3,0.7,0.7C11.2,13.8,10.9,14.1,10.5,14.1z M12.2,10.8H3.7c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.4,0.3-0.7,0.7-0.7h8.5c0.4,0,0.7,0.3,0.7,0.7C12.8,10.5,12.5,10.8,12.2,10.8z M13,7.6H3.7C3.3,7.6,3,7.3,3,6.9
        c0-0.4,0.3-0.7,0.7-0.7H13c0.4,0,0.7,0.3,0.7,0.7C13.7,7.3,13.4,7.6,13,7.6z"
      />
    </SvgIcon>
  );
}