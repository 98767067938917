import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ThreeDotsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,13.1,13.1,14,12,14z M21,12c0-1.1-0.9-2-2-2
        s-2,0.9-2,2c0,1.1,0.9,2,2,2S21,13.1,21,12z M7,12c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,1.1,0.9,2,2,2S7,13.1,7,12z"
      />
    </SvgIcon>
  );
}
