interface ConfigInterface {
  [key: string]: {
    label: string;
    accessor?: () => string;
    value?: string | number;
  };
}

export default function getEnchancedWithValueByHashConfig(
  configByHash: ConfigInterface,
  data: { [key: string]: any },
): ConfigInterface {
  const enchancedObject = { ...configByHash };

  for (const [key] of Object.entries(configByHash)) {
    const currentObject = enchancedObject[key];
    const value = currentObject.accessor !== undefined
      ? currentObject.accessor()
      : data[key];

    enchancedObject[key].value = value;
  }

  return enchancedObject;
}
