import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.6,3H6.4C4.5,3,3,4.5,3,6.4v11.2C3,19.5,4.5,21,6.4,21h11.2c1.9,0,3.4-1.5,3.4-3.4V6.4C21,4.5,19.5,3,17.6,3
	z M16.3,9.9l-5.5,5.5c-0.2,0.2-0.6,0.2-0.8,0L7.7,13c-0.2-0.2-0.2-0.6,0-0.8l0.4-0.4c0.2-0.2,0.6-0.2,0.8,0l1.2,1.2
	c0.2,0.2,0.6,0.2,0.8,0l4.3-4.3c0.2-0.2,0.6-0.2,0.8,0l0.4,0.4C16.5,9.3,16.5,9.6,16.3,9.9z"
      />
    </SvgIcon>
  );
}
