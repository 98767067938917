import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Fade, Backdrop } from "@material-ui/core";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { use100vh } from "react-div-100vh";

export interface BaseModalProps extends ModalProps {
  fullSize?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    childrenWrap: {
      padding: "10px 10px",
      outline: "none",
      overflow: "auto",
      maxHeight: "100vh",
    },
    childrenWrapFullSize: {
      padding: "10px 10px",
      outline: "none",
      overflow: "auto",
      width: "100vw",
      height: "100vh",
    },
  })
);

export default function PatientModal({
  open,
  onClose,
  children,
  fullSize,
}: BaseModalProps) {
  const classes = useStyles();

  const height = use100vh();
  // const halfHeight = height ? height / 2 : "50vh";

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      
      <Fade in={open}>
        <Box
          className={clsx({
            [classes.childrenWrap]: !fullSize,
            [classes.childrenWrapFullSize]: fullSize,
          })}
          style={{ maxHeight: `${height}px` }}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
