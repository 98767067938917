import authStorage from "providers/authStorage";
import { find } from "lodash";

export type CustomerSubRoles =
  | "patientGuardian"
  | "patientSelf"
  | "guardianSelf";

const canByDepartments = (departments: string[] = []) => {
  if (departments.length === 0) return true;
  const userDepts = authStorage.getUserDepts();

  if (
    find(userDepts, (o: { deptCode: string }) => {
      return departments.includes(o.deptCode);
    })
  ) {
    return true;
  } else {
    return false;
  }
};

const canByRoles = (roles: string[] = []) => {
  if (roles.length === 0) return true;

  const access = authStorage.getAccess();
  if (access && access.hasOwnProperty("userRoleCode")) {
    return roles.includes(access.userRoleCode);
  } else return false;
};

const canCustomerSubRoles = (roles: CustomerSubRoles[] = []) => {
  if (roles.length === 0) return true;

  return roles.includes(getCustomerSubRole());
};

const canNotCustomerSubRoles = (roles: CustomerSubRoles[] = []) => {
  return !roles.includes(getCustomerSubRole());
};

const getCustomerSubRole = () => {
  let isPatientSelf = false;
  let isGuardian = false;
  let currentUserRole = "" as CustomerSubRoles;

  const access = authStorage.getAccess();
  if (access && access.hasOwnProperty("isPatientSelf")) {
    isPatientSelf = access.isPatientSelf === 1;
  }
  if (access && access.hasOwnProperty("isGuardian")) {
    isGuardian = access.isGuardian === 1;
  }

  if (isPatientSelf && isGuardian) {
    currentUserRole = "patientGuardian";
  } else if (isPatientSelf) {
    currentUserRole = "patientSelf";
  } else if (isGuardian) {
    currentUserRole = "guardianSelf";
  }
  return currentUserRole;
};

export {
  canByDepartments,
  canByRoles,
  canCustomerSubRoles,
  canNotCustomerSubRoles,
};
