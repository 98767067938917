import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SmartInputProps from "interfaces/SmartInputInterface";
import StyledTextField from "components/uiComponents/inputs/styledInputs/StyledTextField";

const TextInput = (props: SmartInputProps) => {
  const { input, inputId, handleInputs } = props;

  return (
    <StyledTextField
      value={input.value === null ? "" : input.value}
      error={input.error}
      name={inputId}
      type={input.type}
      variant="outlined"
      multiline={input.subType ? input.subType === 'multiline' : false}
      label={input.label}
      onChange={handleInputs}
      disabled={input.disabled}
      helperText={input.error && input.errorMessage}
      InputProps={{
        startAdornment: input.icon && (
          <InputAdornment position="start">{input.icon}</InputAdornment>
        ),
        endAdornment: input.endAdornment,
      }}
      {...input.extraFields}
    />
  );
};

export default TextInput;
