import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Container, Grid, Box, Typography } from "@material-ui/core";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import ThreadList from "components/pages/customer/MessagePage/parts/ThreadList";
import ThreadMessageList from "components/pages/customer/MessagePage/parts/ThreadMessageList";
import AddThreadMessageForm from "components/pages/customer/MessagePage/parts/AddThreadMessageForm";

import { fade } from "@material-ui/core/styles/colorManipulator";
import { Can } from "components/permissions";
import { userRoles } from "config/userRoles";
import { ArchiveIcon, FlagIcon, FlagFilledIcon } from "components/icons";
import PaperControlButton from "components/uiComponents/buttons/PaperControlButton";
import SearchFilter from "components/uiComponents/Filter/SearchFilter";
import threadsListFilterConfig from "config/admin/messages/threadsListFilterConfig";
import ThreadMessageListModal from "components/modals/ThreadMessageListModal";
import AppBarDropdownMenuButton from "components/uiComponents/buttons/AppBarDropdownMenuItem";
import FullHeight from "components/FullHeight";
import theme from "config/theme";
import CachedIcon from "@material-ui/icons/Cached";
import Sort from "components/uiComponents/Sort/Sort";
import { SortConfigType } from "components/uiComponents/Sort/Sort";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      borderBottom: `1px solid ${fade("#2C3367", 0.1)}`,
      display: "flex",
      justifyContent: "space-between",
      alignContent: "flex-end",
      "& > div:first-child": {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
          borderRight: `1px solid ${fade("#2C3367", 0.1)}`,
        },
      },
    },
    leftHeader: {
      padding: "15px 0",
    },
    rightHeader: {
      padding: "15px 20px",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    body: {
      "& > div:first-child": {
        [theme.breakpoints.up("sm")]: {
          borderRight: `1px solid ${fade("#2C3367", 0.1)}`,
        },
      },
    },
    newRequestButtonWrap: {
      textAlign: "center",
      padding: "30px 10px",
      background: fade(theme.palette.primary2.main, 0.02),
      borderTop: `1px solid ${fade("#2C3367", 0.1)}`,
      [theme.breakpoints.up("xs")]: {
        padding: "10px 10px",
      },
      "& button": {
        width: "100%",
        maxWidth: 220,
        minWidth: "auto",
      },
    },
    subjectTitle: {
      fontSize: "18px",
      fontWeight: 600,
    },
    subjectUserName: {
      fontSize: "13px",
      color: fade(theme.palette.primary2.main, 0.75),
    },
    threadControls: {
      textAlign: "right",

      margin: "0 -5px",
      "& >*": {
        margin: "5px 5px 0",
      },
    },
    threadListContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    threadListWrap: {
      paddingBottom: "20px",
      height: "100%",
      overflow: "auto",
    },

    threadMessagesWrapper: {
      overflow: "auto",
      display: "flex",
      "& >*": {
        position: "relative",
        margin: "auto 0 0",
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        height: "100%",
      },
    },
    addMessageFormWrap: {
      overflow: "auto",
    },
  })
);

const LeftColumnWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Box maxWidth={{ sm: "calc(355px - 10px)" }} margin="0 0 0 auto">
      {children}
    </Box>
  );
};

export default function MessagePageComponent({
  threadList,
  selectedSubjectId,
  handleSelectSubject,
  threadDataLoading,
  openModal,
  threadMessages,
  threadMessagesGroupedByDate,
  userId,
  onSubmitOkNewReply,
  threadMessagesLoading,
  selectedSubjectTitle,
  selectedSubjectUserName,
  onClickArchive,
  archiveInProgress = false,
  selectedThreadArhived = false,
  onFilterChange,
  followUpInProgress = false,
  isSelectedThreadFollowUp = false,
  onClickFollowUp,
  isMobile,
  threadMessageListModalIsOpen,
  closeModal,
  onClickRefreshThreads,
  activeFilter,
  threadListSort,
  handleSetNewThreadListSort,
}: {
  threadList: any;
  selectedSubjectId: string | null;
  handleSelectSubject: any;
  threadDataLoading: boolean;
  openModal: (id: string) => void;
  threadMessages: any;
  threadMessagesGroupedByDate: any;
  userId: string;
  onSubmitOkNewReply: (data: any) => void;
  threadMessagesLoading: boolean;
  selectedSubjectTitle: string;
  selectedSubjectUserName: string;
  onClickArchive: () => void;
  archiveInProgress: boolean;
  selectedThreadArhived: boolean;
  onFilterChange: (filter: { [index: string]: any }) => void;
  followUpInProgress: boolean;
  isSelectedThreadFollowUp: boolean;
  onClickFollowUp: () => {};
  isMobile: boolean;
  threadMessageListModalIsOpen: boolean;
  closeModal: () => void;
  onClickRefreshThreads: () => void;
  activeFilter: boolean;
  threadListSort: [string, "asc" | "desc"] | null;
  handleSetNewThreadListSort: (sort: string) => void;
}) {
  const classes = useStyles();

  const threadMessageListWithForm = (
    <Box p="0 10px" width="100%">
      <Box maxWidth={"600px"} margin="0 auto 0">
        <FullHeight
          disabled={isMobile}
          reduceElementHeight={[
            "header",
            "footer",
            "#message-center-header",
            "#message-center-add-message-form-wrap",
          ]}
        >
          <Box className={classes.threadMessagesWrapper} padding="0 0 20px">
            <ThreadMessageList
              userId={userId}
              messagesLoading={threadMessagesLoading}
              messagesGroupedByDate={threadMessagesGroupedByDate}
            />
          </Box>
        </FullHeight>
        <Box>
          {threadMessages.length > 0 && !selectedThreadArhived ? (
            <Box id="message-center-add-message-form-wrap">
              <AddThreadMessageForm
                toUserId={threadMessages[0].toUserId}
                threadId={selectedSubjectId}
                userId={userId}
                onSubmitOk={onSubmitOkNewReply}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Container>
      <BasePaper disablePadding>
        <Grid container className={classes.header} id="message-center-header">
          <Grid item xs={12} sm={4} className={classes.leftHeader}>
            <Box padding="0 0 0 10px">
              <LeftColumnWrapper>
                <Grid container>
                  <Grid item xs={5}>
                    <Box pr={"10px"}>
                      <Typography variant="h2">Messages</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container justify="flex-end">
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="flex-end"
                        padding="0 10px"
                      >
                        <SearchFilter
                          onSubmitFilter={onFilterChange}
                          showSearch={false}
                          filterConfig={threadsListFilterConfig}
                          buttonProps={{ subVariant: "icon" }}
                          activeFilter={activeFilter}
                        />
                        <Box margin="0 5px">
                          <PaperControlButton
                            icon={<CachedIcon />}
                            showSpinner={threadDataLoading}
                            onClick={onClickRefreshThreads}
                            subVariant={"icon"}
                          ></PaperControlButton>
                        </Box>
                        <Sort
                          sort={threadListSort}
                          handleSortBy={handleSetNewThreadListSort}
                          sortConfig={
                            [
                              { id: "msgDate", label: "Date" },
                              { id: "fromlastName", label: "Sender" },
                              { id: "msgSubject", label: "Subject" },
                            ] as SortConfigType
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </LeftColumnWrapper>
            </Box>
          </Grid>
          <Grid item sm={8} className={classes.rightHeader}>
            <Grid container>
              <Grid item sm={6}>
                <Box className={classes.subjectTitle}>
                  {selectedSubjectTitle}
                </Box>
                <Box className={classes.subjectUserName}>
                  {selectedSubjectUserName}
                </Box>
              </Grid>
              <Grid
                item
                sm={6}
                container
                alignContent="center"
                justify="flex-end"
              >
                <Can allowedRoles={[userRoles.admin]}>
                  <Box className={classes.threadControls}>
                    <PaperControlButton
                      icon={<ArchiveIcon />}
                      showSpinner={archiveInProgress}
                      disabled={selectedThreadArhived}
                      onClick={() => {
                        onClickArchive();
                      }}
                    >
                      {selectedThreadArhived ? "Archived" : "Archive"}
                    </PaperControlButton>
                    <PaperControlButton
                      icon={
                        isSelectedThreadFollowUp ? (
                          <Box display="flex" color={theme.palette.error.main}>
                            <FlagFilledIcon color="inherit" />
                          </Box>
                        ) : (
                          <FlagIcon />
                        )
                      }
                      disabled={selectedThreadArhived}
                      showSpinner={followUpInProgress || threadDataLoading}
                      onClick={() => {
                        onClickFollowUp();
                      }}
                    >
                      Follow Up
                    </PaperControlButton>
                  </Box>
                </Can>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.body}>
          <Grid item xs={12} sm={4}>
            <LeftColumnWrapper>
              <FullHeight
                reduceElementHeight={[
                  "header",
                  "footer",
                  "#message-center-header",
                  "#message-center-new-request",
                ]}
              >
                <Box className={classes.threadListWrap}>
                  <ThreadList
                    list={threadList}
                    selectedSubjectId={selectedSubjectId}
                    onSelectSubject={handleSelectSubject}
                    dataLoading={threadDataLoading}
                  />
                </Box>
              </FullHeight>
            </LeftColumnWrapper>

            <Can allowedRoles={[userRoles.customer]}>
              <Box
                className={classes.newRequestButtonWrap}
                id="message-center-new-request"
              >
                <SubmitButton
                  onClick={() => {
                    openModal("newThread");
                  }}
                  disableEndIcon={true}
                >
                  New Request
                </SubmitButton>
              </Box>
            </Can>
          </Grid>
          <Grid container item sm={8} alignItems="flex-end">
            {!isMobile && threadMessageListWithForm}
          </Grid>
        </Grid>
      </BasePaper>
      {isMobile && (
        <ThreadMessageListModal
          selectedSubjectTitle={selectedSubjectTitle}
          selectedSubjectUserName={selectedSubjectUserName}
          open={threadMessageListModalIsOpen}
          onClose={closeModal}
          actions={
            <Can allowedRoles={[userRoles.admin]}>
              <React.Fragment>
                <AppBarDropdownMenuButton
                  showSpinner={archiveInProgress}
                  disabled={selectedThreadArhived}
                  onClick={() => {
                    onClickArchive();
                  }}
                  icon={<ArchiveIcon />}
                >
                  Archive
                </AppBarDropdownMenuButton>
                <AppBarDropdownMenuButton
                  showSpinner={followUpInProgress}
                  onClick={() => {
                    onClickFollowUp();
                  }}
                  icon={<FlagIcon />}
                >
                  Mark Flagged
                </AppBarDropdownMenuButton>
              </React.Fragment>
            </Can>
          }
        >
          {threadMessageListWithForm}
        </ThreadMessageListModal>
      )}
    </Container>
  );
}
