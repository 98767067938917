import { stringify } from 'query-string';
import config from 'config';
import errors from 'providers/handleErrors';
import axios, { CancelToken as CancelTokenInterface } from 'axios';
import apiErrorSimulator from 'providers/apiErrorSimulator';
import authStorage from 'providers/authStorage';

const API_URL = config.apiUrl;
const GET_LIST = 'get_list';
const GET_ONE = 'get_one';
const DOWNLOAD_FILE = 'download_file';
const CREATE = 'post';
const UPDATE = 'put';
const DELETE = 'delete'

const { CancelToken } = axios;
let sourceAll = CancelToken.source();

type ApiEndointTypeInterface = 'primary' | 'secondary';

type DataProviderParamsInterface = {
  id?: string;
  filter?: {
    [index: string]: any;
  };
  sort?: {
    field?: string;
    order?: string;
  };
  pagination?: {
    page?: string | number;
    perPage?: string | number;
  };
  data?: { [index: string]: any };
};

export interface DataProviderOptionsInterface {
  debug?: {
    responseWithErrorStatus: number;
  };
  apiEndointType?: ApiEndointTypeInterface;
  cancelToken?: CancelTokenInterface;
}

const getApiEndpoint = (
  apiEndpointType: ApiEndointTypeInterface = 'primary',
) => {
  const endpoints = {
    primary: API_URL,
    secondary: 'test',
  };

  return endpoints[apiEndpointType];
};

const httpClient = (url: string, options: { [index: string]: any }) => {
  const enchancedOptions = { ...options };
  if (options.headers === undefined) {
    enchancedOptions.headers = {};
  }

  enchancedOptions.headers.token = authStorage.getToken();
  enchancedOptions.url = url;
  return axios.request(enchancedOptions);
};

const convertDataProviderRequestToHTTP = (
  type: string,
  resource: string,
  params?: DataProviderParamsInterface,
  apiEndointType?: ApiEndointTypeInterface,
) => {
  const options: { [index: string]: any } = {};
  const apiEndpoint = getApiEndpoint(apiEndointType);

  switch (type) {
    case GET_ONE:
      const url = `${API_URL}/${resource}`;
      return {
        url,
        options,
      };
    case GET_LIST: {
      let filter = {};
      let sort = {};
      let pagination = {};

      if (params !== undefined) {
        if (params.pagination) {
          const { page, perPage } = params.pagination;
          pagination = {
            page: JSON.stringify(page),
            rows: JSON.stringify(perPage),
          };
        }
        if (
          params.filter !== undefined
          && Object.keys(params.filter).length > 0
        ) {
          const prepare_filter = Object.keys(params.filter).map((field) => `${field}:${params!.filter![field]}`);
          filter = { filter: `[${prepare_filter}]` };
        }
        if (params.sort && params.sort.field) {
          const { field, order } = params.sort;
          sort = { sort: `[${field},${order}]` };
        }
      }

      const query = {
        ...sort,
        ...pagination,
        ...filter,
      };

      return {
        url: `${apiEndpoint}/${resource}?${stringify(query)}`,
        options,
      };
    }
    case DOWNLOAD_FILE:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { method: 'POST', data: params!.data },
      };
    case CREATE:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { ...options, method: 'POST', data: params!.data },
      };
    case UPDATE:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { ...options, method: 'PUT', data: params!.data },
      };
    case DELETE:
      return {
        url: `${apiEndpoint}/${resource}`,
        options: { ...options, method: 'DELETE', data: params!.data },
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

const convertHTTPResponseToDataProvider = (
  type: string,
  resource: string,
  params: DataProviderParamsInterface = {},
  response: any,
) => {
  switch (type) {
    case GET_LIST:
      return {
        data: response.data.data || [],
        message: response.data.message,
        total: response.data.total,
      };
    case UPDATE:
      return { data: response.data.data, message: response.data.message };
    case DOWNLOAD_FILE:
      return { data: response.data.data, message: response.data.message };
    default:
      const data = (response.data.data !== null && response.data.data.length) > 0
        ? response.data.data[0]
        : false;
      return { data };
  }
};

const cancelCurrentCalls = () => {
  sourceAll.cancel('All requests canceled');
  sourceAll = CancelToken.source();
};

const dataProvider = async (
  type: string,
  resource: string,
  params: DataProviderParamsInterface = {},
  dataProviderOptions: DataProviderOptionsInterface = {},
) => {
  const {
    debug = null,
    apiEndointType = 'primary',
    cancelToken = null,
  } = dataProviderOptions;

  if (
    debug
    && debug.hasOwnProperty('responseWithErrorStatus')
    && window.confirm(`Simulate server error - ${debug.responseWithErrorStatus} ?`)
  ) {
    return apiErrorSimulator(debug)
      .then((response) => convertHTTPResponseToDataProvider(
        type,
        resource,
        params,
        response,
      ))
      .catch((err) => errors.handle(err));
  }
  const { options, url } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params,
    apiEndointType,
  );

  if (cancelToken) {
    options.cancelToken = cancelToken;
  } else {
    options.cancelToken = sourceAll.token;
  }

  return httpClient(url, options)
    .then((response: any) => convertHTTPResponseToDataProvider(
      type,
      resource,
      params,
      response,
    ))
    .catch((err: any) => errors.handle(err));
};

export default dataProvider;
export {
  GET_LIST, GET_ONE, DOWNLOAD_FILE, CREATE, UPDATE, cancelCurrentCalls,
};
