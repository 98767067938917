import React, { useRef } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import TimelineItem from "./TimelineItem";
import { Container, Box, Button } from "@material-ui/core";
import TimelineScale from "components/uiComponents/timeline/TimelineScale";
import { TweenLite } from "gsap";
import clsx from "clsx";
import { ArrowLeftIcon, ArrowRightIcon } from "components/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      margin: "15px 0 40px",
    },

    month: {
      width: "8.333%",
      display: "flex",
      fontFamily: "Josefin Sans",
      position: "relative",
      height: "12px",
      borderRight: `1px solid ${theme.palette.primary.main}`,
      "&:first-child": {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
      },
    },
    monthQuartersGroup: {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
    },
    monthQuarters: {
      width: "25%",
      borderRight: `1px solid #445369`,
      opacity: "0.6",
      "&:nth-child(3), &:nth-child(1)": {
        height: "4px",
      },
      "&:nth-child(2)": {
        height: "7px",
      },
    },
    monthPointsGroup: {
      position: "absolute",
      left: 0,
      bottom: 0,
      display: "block",
      width: "100%",
      height: "1px",
    },
    yearLabel: {
      background: "white",
      borderRadius: "5px",
      display: "inline-block",
      padding: "1px 7px",
      fontSize: "10px",
      margin: "0 6px 0 0",
      zIndex: 1,
      top: "8px",
      position: "relative",
    },
    scrollable: {
      overflowX: "scroll",
      display: "flex",
      margin: "0 50px -10px",
      padding: "0 0 10px",
      scrollbarWidth: "none",

      "&::-webkit-scrollbar": {
        display: "none", // Safari + Chrome
      },
    },

    scrollContent: {
      display: "flex",
      position: "relative",
    },

    controlButton: {
      position: "absolute",
      bottom: 10,
      transform: "translateY(50%)",
      right: "100%",
      background: "#fff",
      color: theme.palette.primary.main,
      padding: "10px",
      minWidth: "0",
      fontSize: "18px",
      borderRadius: "8px",
      "&:hover": {
        background: theme.palette.primary.main,
        color: "#fff",
      },
    },

    controlButtonLeft: {
      left: 0,
    },

    controlButtonRight: {
      right: 0,
    },
  })
);

export default function Timeline({
  selectedPatientId,
  setSelectedReqId,
  selectedReqId,
  datesByYear,
}: {
  selectedPatientId: number | null;
  setSelectedReqId: (id: number) => void;
  selectedReqId: number | null;
  datesByYear: { [index: string]: {}[] };
}) {
  const classes = useStyles();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);

  let scrollContentWidth = 0;
  let scrollableWidth = 0;
  let timeScaleWidth = 0;

  if (scrollContentRef && scrollContentRef.current) {
    scrollContentWidth = scrollContentRef.current.clientWidth;
  }

  if (scrollableRef && scrollableRef.current) {
    scrollableWidth = scrollableRef.current.clientWidth;
  }

  timeScaleWidth =
    scrollContentWidth > scrollableWidth ? scrollContentWidth : scrollableWidth;

  const itemsByYears = [];

  for (const [key, value] of Object.entries(datesByYear)) {
    itemsByYears.push(
      <Box component={"span"} display={"flex"} alignItems={"flex-end"} key={key}>
        <Box className={classes.yearLabel}>{key}</Box>
        <Box display="flex">
          {value.map((item: any) => {
            const objDate = new Date(item.dateCollectedTz);
            return (
              <Box minWidth={"200px"} key={item.id}>
                <TimelineItem
                  id={item.id}
                  date={objDate}
                  onClick={setSelectedReqId}
                  isSelected={selectedReqId === item.reqId}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  const handleClickLeft = () => {
    if (scrollableRef && scrollableRef.current) {
      TweenLite.to(scrollableRef.current, 0.5, {
        scrollLeft: scrollableRef.current.scrollLeft - 300,
      });
    }
  };

  const handleClickRight = () => {
    if (scrollableRef && scrollableRef.current) {
      TweenLite.to(scrollableRef.current, 0.5, {
        scrollLeft: scrollableRef.current.scrollLeft + 300,
      });
    }
  };

  return (
    <Box pb={"5px"}>
      <Container
        maxWidth={false}
        disableGutters={true}
        classes={{ root: classes.rootContainer }}
      >
        <Container>
          <Box position={"relative"}>
            <Button
              className={clsx(classes.controlButton, classes.controlButtonLeft)}
              onClick={handleClickLeft}
            >
              <ArrowLeftIcon fontSize={"inherit"} />
            </Button>

            <Button
              className={clsx(
                classes.controlButton,
                classes.controlButtonRight
              )}
              onClick={handleClickRight}
            >
              <ArrowRightIcon fontSize={"inherit"} />
            </Button>

            <div
              className={classes.scrollable}
              ref={scrollableRef}
            >
              <div className={classes.scrollContent} ref={scrollContentRef}>
                {itemsByYears}
                <Box
                  position={"absolute"}
                  left={0}
                  bottom={0}
                  minWidth={`${timeScaleWidth}px`}
                >
                  <TimelineScale />
                </Box>
              </div>
            </div>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}
