import { Column, ActionProps } from "components/uiComponents/table/Interfaces";

export default function enhanceActionConfig(
  config: Column[],
  actionsConfig: { [index: string]: ActionProps }
) {
  const enhancedConfig = config.map((col) => {
    let updatedCol = col;

    if (col.type === "action" || col.type === "hyperlinkAction") {
      updatedCol = {
        ...col,
        actionProps: {
          ...col.actionProps,
          ...actionsConfig[col.id],
        },
      };
    }

    return updatedCol;
  });
  return enhancedConfig;
}
