import React from "react";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "20px 25px",
      boxShadow: "0px 6px 26px -8px rgba(0,0,0,0.2) !important",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 15px",
      },
    },
    rounded: {
      borderRadius: "18px",
    },
  })
);

const useCustomClassesStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootPaddingDisabled: {
      boxShadow: "0px 6px 26px -8px rgba(0,0,0,0.2) !important",
    },
  })
);

export default function BasePaper({
  paperProps,
  children,
  disablePadding = false,
}: {
  paperProps?: PaperProps;
  children: JSX.Element[] | JSX.Element | string;
  disablePadding?: boolean;
}) {
  const classes = useStyles();
  const customClasses = useCustomClassesStyles();

  return (
    <Paper
      {...paperProps}
      classes={{
        ...classes,
        root: disablePadding ? customClasses.rootPaddingDisabled : classes.root,
      }}
    >
      {children}
    </Paper>
  );
}
