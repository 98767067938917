import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import birthdayInputConfig from "config/inputs/birthdayInputConfig";
import genderInputConfig from "config/inputs/genderInputConfig";

const guardianVerifyForm: InputByHashConfigInterface = {
  inputsById: [
    "guardianFirstName",
    "guardianMiddleName",
    "guardianLastName",
    "guardianDob",
    "guardianGenderCode",
  ],

  inputsByHash: {
    guardianFirstName: {
      label: "First Name",
      required: true,
    },
    guardianMiddleName: {
      label: "Middle Name",
    },
    guardianLastName: {
      label: "Last Name",
      required: true,
    },
    guardianGenderCode: genderInputConfig,
    guardianDob: birthdayInputConfig,
  },
};

export default guardianVerifyForm;
