import React, { useState, useEffect } from "react";
import Amplify, { Auth } from "aws-amplify";
import axios from "axios";
import apiError from "providers/handleErrors.jsx";
import config from "config";
import authStorage from "providers/authStorage";
import history from "appHistory";
import AuthCenterContext from "./AuthCenterContext";
import { useFormMesssageBar } from "components/uiComponents/forms/FormMessageBar";

Amplify.configure({
  Auth: {
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
  },
});

const AuthCenterProvider = (props: any) => {
  const [token, setToken] = useState(authStorage.getToken());
  const [isShowTerm, setIsShowTerm] = useState(authStorage.getIsShowTerm());
  const [access, setAccess] = useState(authStorage.getAccess());
  const [cognitoToken, setCognitoToken] = useState(
    authStorage.getCognitoToken()
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<null | { challengeName: any }>(
    null
  );
  const [authStatus, setAuthStatus] = useState<
    "login" | "smsMfa" | "changeTemporaryPassword"
  >("login");
  const [refreshTokenInProgress, setRefreshTokenInProgress] = useState(
    authStorage.getIsRefreshTokenFlowInProgress()
  );
  const { title, children, type, setMessage } = useFormMesssageBar();
  const [logOutInProgress, setLogOutInProgress] = useState(false);

  useEffect(() => {
    apiError.on(apiError.status420, () => {
      console.log('handle420');
      console.log(refreshTokenInProgress);
      if (!refreshTokenInProgress) {
        authStorage.startRefreshTokenFlow();
        authStorage.logout();
        refreshToken();
      }
    });
    return () => apiError.off(apiError.status420);
  });

  useEffect(() => {
    authStorage.on(authStorage.CHANGE_COGNITO_AUTH, setAuthCognitoState);
    return () => authStorage.off(setAuthCognitoState);
  });

  useEffect(() => {
    apiError.on(apiError.status408, () => {
      authStorage.logout();
      history.push("/login");
    });
    return () => apiError.off(apiError.status408);
  });

  const setAuthCognitoState = (cognitoAccessToken: string) => {
    setCognitoToken(cognitoAccessToken);
  };

  const refreshToken = async () => {
    console.log('refreshToken');
    console.log(authStorage.getIsRefreshTokenFlowInProgress())
    setRefreshTokenInProgress(true); // update flag directly, because handling storage won't work on the same page that is making the changes
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: any, session: any) => {
          const { jwtToken } = session.accessToken;
          authStorage.authenticateCognito(jwtToken);
          mfaLogin(jwtToken).then(() => {
            authStorage.finishRefreshTokenFlow();
            setRefreshTokenInProgress(false); // update flag directly, because handling storage won't work on the same page that is making the changes
          });
        }
      );
    } catch (e) {
      history.push("/login");
      authStorage.finishRefreshTokenFlow();
      setRefreshTokenInProgress(false); // update flag directly, because handling storage won't work on the same page that is making the changes
      console.log("Unable to refresh Token", e);
    }
  };

  const cognitoLogIn = async (
    userName: string,
    password: string
  ): Promise<any> => {
    try {
      const user = await Auth.signIn(userName, password);
      setCognitoUser(user);

      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setAuthStatus("smsMfa");
        return {
          loginStatus: "smsMfa",
        };
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setAuthStatus("changeTemporaryPassword");
        return {
          loginStatus: "changeTemporaryPassword",
        };
      } else if (user.authenticationFlowType === "USER_SRP_AUTH") {
        const { accessToken } = user.signInUserSession;
        authStorage.authenticateCognito(accessToken.jwtToken);
        const mfaLoginResponse = await mfaLogin(accessToken.jwtToken);

        return {
          loginStatus: "loginOk",
          data: mfaLoginResponse,
        };
      }
    } catch (err) {
      throw err;
    }
  };

  async function confirmSignIn(code: string) {
    try {
      // const cognitoUser = await Auth.currentAuthenticatedUser();
      const loggedUser = await Auth.confirmSignIn(
        cognitoUser, // Return object from Auth.signIn()
        code, // Confirmation code
        cognitoUser!.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );

      const { jwtToken } = loggedUser.signInUserSession.accessToken;

      authStorage.authenticateCognito(jwtToken);

      return jwtToken;
    } catch (err) {
      if (err.code === "NotAuthorizedException") {
        setAuthStatus("login");
      }
      throw err;
    }
  }

  const changePassword = async (newPassword: string) => {
    setDataLoading(true);
    try {
      const loggedUser = await Auth.completeNewPassword(
        cognitoUser,
        newPassword
      );
      setDataLoading(false);
      if (
        loggedUser.challengeName === "SMS_MFA" ||
        loggedUser.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        setAuthStatus("smsMfa");
      } else if (loggedUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setAuthStatus("changeTemporaryPassword");
      }

      return loggedUser;
    } catch (err) {
      setDataLoading(false);
      if (err.message) {
        setMessage(err.message, null, "error");
      }
      throw err;
    }
  };

  const mfaChangePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      const response = await axios.request({
        method: "PUT",
        url: `${config.apiUrl}/mfa/profile/chg_pwd`,
        headers: {
          token: token,
        },
        data: {
          currentPwd: currentPassword,
          newPwd: newPassword,
          accessToken: cognitoToken,
        },
      });
      return response.data;
    } catch (err) {
      apiError.handle(err);
      throw err;
    }
  };

  const logOutOk = () => {
    authStorage.logout();
    authStorage.logoutCognito();
    return Promise.resolve();
  };

  const logOut = async () => {
    setLogOutInProgress(true);
    try {
      await axios.request({
        method: "PUT",
        url: `${config.apiUrl}/mfa/logout/${access.userId}`,
        headers: {
          token: authStorage.getToken(),
        },
        data: {},
      });
    } catch (e) {
      setLogOutInProgress(false);
    }
    setLogOutInProgress(false);
    return logOutOk();
  };

  const mfaLogin = (accessToken: string) =>
    axios
      .request({
        method: "POST",
        url: `${config.apiUrl}/mfa/login`,
        data: {
          accessToken,
        },
      })
      .then((response) => {
        const { data } = response.data;
        authStorage.authenticate(data.token, data.access.isShowTerm, data.access);

        // update token directly, because handling storage won't work on the same page that is making the changes
        setToken(data.token);
        setIsShowTerm(data.access.isShowTerm);
        setAccess(data.access);

        setAuthStatus("login");

        return response.data;
      })
      .catch((err) => apiError.handle(err));

  const handleSubmitIsShowTerm = () => {
    authStorage.setIsShowTerm();
    setIsShowTerm(0);
  }

  const value = {
    setToken,
    setAuthStatus,
    cognitoLogIn,
    confirmSignIn,
    changePassword,
    logOut,
    mfaLogin,
    mfaChangePassword,
    setDataLoading,
    dataLoading,
    token,
    isShowTerm,
    handleSubmitIsShowTerm,
    access,
    authStatus,
    logOutInProgress,
    messageBar: {
      title,
      children,
      type,
      setMessage,
    },
  };

  return (
    <AuthCenterContext.Provider value={value}>
      {props.children}
    </AuthCenterContext.Provider>
  );
};

export default AuthCenterProvider;
