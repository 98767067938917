import React, { useState } from "react";
import Filter from "components/uiComponents/Filter/Filter";
import { Box } from "@material-ui/core";
import SearchInput from "components/uiComponents/inputs/SearchInput";
import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import { AppBarMenuItemProps } from "components/uiComponents/buttons/PaperControlButton";

function clean(obj: any) {
  for (var propName in obj) {
    if (obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj;
}

const getFilter = (filter: { [index: string]: any }, search: string) => {
  const mergedFilter = {
    ...filter,
    search: search,
  };

  return clean(mergedFilter);
};

export default function SearchFilter({
  onSubmitFilter,
  showSearch = true,
  filterConfig,
  buttonProps,
  activeFilter = false
}: {
  onSubmitFilter: (data: { [index: string]: any }) => void;
  showSearch?: boolean;
  filterConfig: InputByHashConfigInterface;
  buttonProps?: AppBarMenuItemProps;
  activeFilter?: boolean;
}) {
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");

  const handleSubmitFilter = (newfilter: { [index: string]: any }) => {
    setFilter(newfilter);
    onSubmitFilter(getFilter(newfilter, search));
  };

  const handleSubmitSearch = (newSearchString: string) => {
    setSearch(newSearchString);
    onSubmitFilter(getFilter(filter, newSearchString));
  };

  return (
    <Box display="flex" alignItems="center">
      <Box margin={"0 5px"}>
        <Filter
          onSubmit={handleSubmitFilter}
          filterConfig={filterConfig}
          buttonProps={buttonProps}
          activeFilter={activeFilter}
        />
      </Box>
      {showSearch && (
        <Box margin={"0 5px"}>
          <SearchInput handleInput={handleSubmitSearch} />
        </Box>
      )}
    </Box>
  );
}
