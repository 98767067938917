import React from "react";
import { Column } from "components/uiComponents/table/Interfaces";
import { PencilIcon, CloseIcon } from "components/icons";

const tableConfig: Column[] = [
  {
    id: "name",
    label: "Name",
    accessor: (d) => `${d.userFirstName}, ${d.userLastName}`,
  },
  {
    id: "userName",
    label: "UserName",
  },
  {
    id: "userDob",
    label: "Date of Birth",
  },
  {
    id: "userPhone",
    label: "Phone number",
  },
  {
    id: "userEmail",
    label: "Email",
  },
  {
    id: "associationTypeDesc",
    label: "Relationship",
  },
  {
    id: "edit",
    type: "action",
    tableCellStyles: "width: 100px;",
    actionProps: {
      label: "Edit",
      icon: <PencilIcon />,
    },
  },
  {
    id: "unlink",
    type: "action",
    tableCellStyles: "width: 100px;",
    actionProps: {
      label: "Unlink",
      icon: <CloseIcon />,
      buttonVariant: "error",
      visibilityAccessorByValue: (d) => d.associationTypeCode === 'GUARDIAN'
    },
  },
];

export default tableConfig;
