import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function FilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.6,20.2c-0.1,0-0.3,0-0.4-0.1L10,18.5c-0.3-0.1-0.5-0.4-0.5-0.8v-5L3.2,5.2C3,4.9,2.9,4.6,3.1,4.3
        C3.2,4,3.5,3.8,3.8,3.8h16.3c0.3,0,0.6,0.2,0.8,0.5c0.1,0.3,0.1,0.6-0.1,0.9l-6.3,7.5v6.7c0,0.3-0.2,0.6-0.4,0.7
        C13.9,20.1,13.8,20.2,13.6,20.2z M11.2,17.2l1.6,0.8v-5.6c0-0.2,0.1-0.4,0.2-0.5l5.4-6.3H5.6l5.4,6.3c0.1,0.2,0.2,0.3,0.2,0.5V17.2z"
      />
    </SvgIcon>
  );
}
