export default function groupObjectsByYear(
  objects: {}[],
  key: string
): { [index: string]: {}[] } {
  const groupedByYear: { [index: number]: {}[] } = {};

  objects.forEach((obj: { [key: string]: any }) => {
    const objDate = new Date(obj[key]);
    const objDateYear = objDate.getFullYear();

    if (!groupedByYear.hasOwnProperty(objDateYear)) {
      groupedByYear[objDateYear] = [];
    }

    groupedByYear[objDateYear].push(obj);
  });

  return groupedByYear;
}
