import React from "react";
import { InfoIcon, AnchorIcon } from "components/icons";

const userInfoByHash: {
  [key: string]: {
    label: string;
    accessor?: () => string;
    value?: string | number;
  };
} = {
  insuranceCompany: {
    label: "Insurance name",
  },
  policyHolder: {
    label: "Policy holder",
  },
  policyNo: {
    label: "Policy number",
  },
  groupNo: {
    label: "Group number",
  },
  patientAddr1: {
    label: "Address",
  },
  patientCity: {
    label: "City",
  },
  patientState: {
    label: "State ",
  },
  patientZip: {
    label: "Zip",
  },
  patientCountry: {
    label: "Country",
  },
  patientHomePhone: {
    label: "Phone",
  },
  patientEmail: {
    label: "Email",
  },
};

const userInfoGroupsById = ["group1", "group2"];
const userInfoGroupsByHash: {
  [key: string]: { title: string; icon: JSX.Element; ids: string[] };
} = {
  group1: {
    title: "Insurance Info",
    icon: <AnchorIcon />,
    ids: ["insuranceCompany", "policyHolder", "policyNo", "groupNo"],
  },
  group2: {
    title: "Patient Contact Info",
    icon: <InfoIcon />,
    ids: [
      "patientAddr1",
      "patientCity",
      "patientState",
      "patientZip",
      "patientCountry",
      "patientHomePhone",
      "patientEmail",
    ],
  },
};

export { userInfoByHash, userInfoGroupsById, userInfoGroupsByHash };
