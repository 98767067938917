import React from "react";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { ButtonProps } from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextButton from "components/uiComponents/buttons/TextButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      fontWeight: 700,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
      display: "inline-block",
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default function HyperlinkActionTableCell({
  buttonProps,
  buttonLabel,
  tableCellProps,
  actionInProgress,
}: {
  buttonLabel: string | JSX.Element;
  buttonProps?: ButtonProps;
  tableCellProps?: TableCellProps;
  actionInProgress?: boolean;
}) {
  const classes = useStyles();

  return (
    <TableCell padding="none" {...tableCellProps}>
      <span className={classes.wrapper}>
        <TextButton disabled={actionInProgress} {...buttonProps}>
          {buttonLabel}
        </TextButton>

        {actionInProgress && (
          <CircularProgress size={24} className={classes.progress} />
        )}
      </span>
    </TableCell>
  );
}
