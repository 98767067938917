import React, { useState } from "react";
import axios from "axios";
import config from "config";
import ChooseRoleForm from "components/uiComponents/forms/RegistrationForm/Steps/ChooseRoleForm";
import PatientVerifyForm from "components/uiComponents/forms/RegistrationForm/Steps/PatientVerifyForm";
import GuardianVerifyForm from "components/uiComponents/forms/RegistrationForm/Steps/GuardianVerifyForm";
import CompleteAccountForm from "components/uiComponents/forms/RegistrationForm/Steps/CompleteAccountForm";
import TextButton from "components/uiComponents/buttons/TextButton";
import history from "appHistory";
import AuthPaper from "components/uiComponents/papers/AuthPaper";
import FormMessageBar from "components/uiComponents/forms/FormMessageBar";
import { useAuthCenter } from "AuthCenter";

interface stepsByHash {
  [index: string]: { title: string };
}

const patientSteps = ["patientVerify", "completeAccount"];

const patientStepsByHash: stepsByHash = {
  patientVerify: {
    title: "Register as Patient",
  },
  completeAccount: {
    title: "Complete Account",
  },
};

const guardianSteps = ["patientVerify", "guardianVerify", "completeAccount"];

const guardianStepsByHash: stepsByHash = {
  patientVerify: {
    title: "Patient Info",
  },
  guardianVerify: {
    title: "You Info (Guardian)",
  },
  completeAccount: {
    title: "Complete Account",
  },
};

const getSteps = (registrationRole: string) => {
  if (registrationRole === "patient") {
    return patientSteps;
  } else {
    return guardianSteps;
  }
};

export default function RegistrationForm({ token }: { token: string }) {
  const [registrationRole, setRegistrationRole] = useState("guardian");
  const [registrationStep, setRegistrationStep] = useState<null | string>(null);
  const [isSelectRoleStep, setIsSelectRoleStep] = useState(true);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [showFormError, setShowFormError] = useState(false);
  const [patientVerifyData, setPatientVerifyData] = useState({});
  const [guardianVerifyData, setGuardianVerifyData] = useState({});
  const authCenter = useAuthCenter();

  const [isDataLoading, setDataLoading] = useState<boolean>(false);

  const onSubmitChooseRole = (data: { [index: string]: any }) => {
    setRegistrationRole(data.role);
    setIsSelectRoleStep(false);

    if (registrationRole === "patient") {
      setRegistrationStep(patientSteps[0]);
    } else {
      setRegistrationStep(guardianSteps[0]);
    }
  };

  const onSubmitPatientVerify = (data: { [index: string]: any }) => {
    setDataLoading(true);
    return axios
      .request({
        method: "POST",
        url: `${config.apiUrl}/users/register/patient_exist`,
        data: {
          ...data,
          registrationCode: token,
        },
      })
      .then((response) => {
        setDataLoading(false);
        clearFormError();
        setPatientVerifyData(data);
        goNextStep();
        return response.data;
      })
      .catch((err) => {
        setDataLoading(false);
        setFormError(err.response.data.message);
      });
  };

  const onSubmitGuardianVerify = (data: { [index: string]: any }) => {
    clearFormError();
    setGuardianVerifyData(data);
    goNextStep();
  };

  const clearFormError = () => {
    setShowFormError(false);
    setFormErrorMessage("");
  };

  const setFormError = (message: string) => {
    setShowFormError(true);
    setFormErrorMessage(message);
  };

  const onSubmitCompleteAccount = (data: { [index: string]: any }) => {
    setDataLoading(true);

    let requestData = {
      ...data,
      ...patientVerifyData,
    };

    if (registrationRole === "guardian") {
      requestData = {
        ...requestData,
        ...guardianVerifyData,
      };
    }

    return axios
      .request({
        method: "POST",
        url: `${config.apiUrl}/users/register/${
          registrationRole === "guardian" ? "guardian" : "self"
        }/${token}`,
        data: requestData,
      })
      .then((response) => {
        setDataLoading(false);
        history.push("/login");
        authCenter.messageBar.setMessage(
          "Your registration has been processed.",
          "If this is the first time you’ve registered on this website, please check your email for your temporary password."
        );
        return response.data;
      })
      .catch((err) => {
        setDataLoading(false);
        setFormError(err.response.data.message);
      });
  };

  const goBackStep = () => {
    clearFormError();
    let steps = getSteps(registrationRole);
    const currentIndex = steps.findIndex((item) => item === registrationStep);

    if (currentIndex > 0) {
      setRegistrationStep(steps[currentIndex - 1]);
    } else {
      setIsSelectRoleStep(true);
      setRegistrationStep(null);
    }
  };

  const goNextStep = () => {
    let steps = getSteps(registrationRole);

    const currentIndex = steps.findIndex((item) => item === registrationStep);

    if (currentIndex < steps.length - 1) {
      setRegistrationStep(steps[currentIndex + 1]);
    }
  };

  const BackButton = () => {
    return (
      <TextButton
        onClick={() => {
          goBackStep();
        }}
      >
        Back
      </TextButton>
    );
  };

  const LoginButton = () => {
    return (
      <TextButton
        onClick={() => {
          history.push("/login");
        }}
      >
        Log in
      </TextButton>
    );
  };

  const getForm = (step: string | null) => {
    switch (step) {
      case "patientVerify":
        return (
          <PatientVerifyForm
            onSubmitValidForm={onSubmitPatientVerify}
            backButton={<BackButton />}
            isDataLoading={isDataLoading}
          />
        );
      case "guardianVerify":
        return (
          <GuardianVerifyForm
            onSubmitValidForm={onSubmitGuardianVerify}
            backButton={<BackButton />}
            isDataLoading={isDataLoading}
          />
        );
      case "completeAccount":
        return (
          <CompleteAccountForm
            onSubmitValidForm={onSubmitCompleteAccount}
            backButton={<BackButton />}
            isDataLoading={isDataLoading}
          />
        );
    }
  };

  const getTitle = (role: string, step: string | null) => {
    if (step === null) return "";
    let stepInfo = null;
    if (role === "patient") {
      stepInfo = patientStepsByHash[step];
    } else {
      stepInfo = guardianStepsByHash[step];
    }
    return stepInfo.title;
  };

  let title = "Create your Account!";

  if (registrationStep !== null) {
    title = getTitle(registrationRole, registrationStep);
  }

  return (
    <React.Fragment>
      <AuthPaper title={title}>
        <React.Fragment>
          {showFormError && (
            <FormMessageBar
              type="error"
              title={formErrorMessage}
            ></FormMessageBar>
          )}

          {isSelectRoleStep ? (
            <ChooseRoleForm
              onSubmitValidForm={onSubmitChooseRole}
              backButton={<LoginButton />}
            />
          ) : (
            getForm(registrationStep)
          )}
        </React.Fragment>
      </AuthPaper>
    </React.Fragment>
  );
}
