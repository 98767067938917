import React from "react";

import { Router } from "react-router-dom";
import { AuthCenterProvider } from "AuthCenter";
import Routes from "routes/Routes";
import history from "appHistory";
import theme from "config/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import Snackbar, { SnackbarDataProps } from "components/uiComponents/Snackbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { StoreProvider } from "context/Store";

function App() {
  return (
    <React.Fragment>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            content={(key, message) => (
              <Snackbar id={key} data={message as SnackbarDataProps} />
            )}
          >
            <CssBaseline />
            <AuthCenterProvider>
              <StoreProvider>
                <Router history={history}>
                  <Routes />
                </Router>
              </StoreProvider>
            </AuthCenterProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
}

export default App;
