import React, { useState, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import { Box } from "@material-ui/core";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import clsx from "clsx";
import { DangerIcon } from "components/icons";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";

type VariantClasses = "variantInfo" | "variantError";

export type SnackbarDataProps = {
  message: string;
  variant: "info" | "error" | "download";
  url: string;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      maxWidth: "100vw",
      [theme.breakpoints.up("sm")]: {
        minWidth: 580,
      },
    },
    variantInfo: {
      "& $card": {
        backgroundColor: theme.palette.primary2.main,
        border: "1px solid #5E7FAF",
      },
    },
    variantDownload: {
      "& $card": {
        backgroundColor: theme.palette.primary2.main,
        border: "1px solid #5E7FAF",
      },
    },
    variantError: {
      "& $card": {
        backgroundColor: theme.palette.error.main,
        border: "1px solid #E88585",
      },
    },
    card: {
      borderRadius: "14px",
      boxShadow: "0px 8px 44px -20px #29445D",
      width: "100%",
      color: "#fff",
    },
    typography: {
      fontWeight: 400,
      fontSize: "13px",
    },
    actionRoot: {
      padding: "20px 14px 20px 44px",
      justifyContent: "space-between",
    },
    icon: {
      top: "50%",
      left: 14,
      color: "#fff",
      width: 24,
      height: 24,
      display: "flex",
      position: "absolute",
      alignItems: "center",
      borderRadius: "50%",
      justifyContent: "center",
      transform: "translateY(-50%)",
      backgroundColor: fade("#FFFFFF", 0.15),
    },
    downloadButton: {
      textTransform: "none",
      textDecoration: "underline",
      fontSize: "13px",
      fontWeight: 600,
      color: "#fff",
    },
  };
});

const SnackMessage = forwardRef<
  HTMLDivElement,
  { id: string | number; data: SnackbarDataProps }
>((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const { variant = "info", message = null, url = null } = props.data;
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const variantClass = `variant${capitalizeFirstLetter(
    variant
  )}` as VariantClasses;

  return (
    <div ref={ref} className={clsx(classes.root, classes[variantClass])}>
      <Box className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Box className={classes.icon}>
            {(variant === "info" ||
              variant === "error" ||
              variant === "download") && (
              <Box width={"1em"} height={"1em"} fontSize={16}>
                <DangerIcon fontSize={"inherit"} />
              </Box>
            )}
          </Box>

          <Typography className={classes.typography}>
            {message ? message : "If download failed, please click here"}
          </Typography>

          {url && (
            <Button
              target={"_blank"}
              className={classes.downloadButton}
              href={url}
            >
              Download
            </Button>
          )}

          {variant === "error" && message && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied to clipboard"
                >
                  <CopyToClipboard onCopy={handleTooltipOpen} text={message}>
                    <FileCopyIcon />
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
        </CardActions>
      </Box>
    </div>
  );
});

export default SnackMessage;
