import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const completeAccountConfig: InputByHashConfigInterface = {
  inputsById: ["userName", "userEmail", "userPhone"],

  inputsByHash: {
    userName: {
      label: "User Name",
      required: true,
    },
    userEmail: {
      label: "Email",
      required: true,
    },
    userPhone: {
      label: "Phone",
      required: true,
      type: 'phone',
    },
  },
};

export default completeAccountConfig;
