import React from "react";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Container } from "@material-ui/core";
import useTabs from "hooks/useTabs";
import CustomTab from "components/uiComponents/tabs/CustomTab";
import CustomTabs from "components/uiComponents/tabs/CustomTabs";
import PersonalInfoForm from "components/pages/customer/ProfilePage/PersonalInfoForm";
import PatientsUnlinkList from "components/pages/customer/ProfilePage/PatientsUnlinkList";
import { canNotCustomerSubRoles } from "utils/permissions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function ProfilePage() {
  const [tabIndex, setTabIndex] = useTabs();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Container>
      <BasePaper>
        <CustomTabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="simple tabs example"
          textColor="primary"
          indicatorColor="primary"
        >
          <CustomTab label="Personal Info" />
          {canNotCustomerSubRoles(["patientSelf"]) && (
            <CustomTab label="Patients" />
          )}
        </CustomTabs>

        <TabPanel value={tabIndex} index={0}>
          <PersonalInfoForm />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <PatientsUnlinkList />
        </TabPanel>
      </BasePaper>
    </Container>
  );
}
