import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { BaseTableHeaderProps } from "components/uiComponents/table/Interfaces";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CheckboxTableCell from "components/uiComponents/table/cells/CheckboxTableCell";
import HeaderTableCell from "components/uiComponents/table/cells/HeaderTableCell";
import ActionHeaderTableCell from "components/uiComponents/table/cells/ActionHeaderTableCell";
import { filterArrayOfObjectsByArray } from "utils";

const useHeaderTableCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: "11px",
      textTransform: "uppercase",
      color: fade("#445369", 0.6),
      fontWeight: 600,
      borderBottom: "none",
    },
  })
);


const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
  })
);

export default function BaseTableHeader({
  columns,
  order,
  orderBy,
  selectedRowIds = [],
  rowCount,
  rows,
  rowSelect,
  setOrderBy,
  onSelectAllClick = () => {},
}: BaseTableHeaderProps) {
  const classes = useToolbarStyles();
  const headerTableCellClasses = useHeaderTableCellStyles();
  const numSelected = selectedRowIds.length;
  const selectedRows = filterArrayOfObjectsByArray(rows, selectedRowIds, "id");

  return (
    <TableHead>
      <TableRow>
        {rowSelect && (
          <CheckboxTableCell
            tableCellProps={{
              classes: classes,
            }}
            checkboxProps={{
              checked: rowCount > 0 && numSelected === rowCount,
              onChange: onSelectAllClick,
              inputProps: { "aria-label": "select all desserts" },
            }}
          />
        )}

        {columns.map((column) => {
          if (column.type === "action") {
            return (
              <ActionHeaderTableCell
                key={column.id}
                column={column}
                selectedRows={selectedRows}
                label={column.label!}
                isActionActive={numSelected > 0}
                tableCellProps={{
                  classes: headerTableCellClasses,
                }}
              />
            );
          } else {
            return (
              <HeaderTableCell
                key={column.id}
                rows={rows}
                column={column}
                orderBy={orderBy}
                order={order}
                setOrderBy={setOrderBy}
                selectedRowIds={selectedRowIds}
                tableCellProps={{
                  classes: headerTableCellClasses,
                }}
              />
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}
