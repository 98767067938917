import React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import { fade } from "@material-ui/core";

export default function CircularProgressShadow() {
  return (
    <Box
      position={"absolute"}
      left={0}
      top={0}
      width={"100%"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      zIndex={2}
      bgcolor={fade("#ffffff", 0.7)}
    >
      <CircularProgress />
    </Box>
  );
}
