import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.8,11c-0.4-0.4-0.4-1.2,0-1.6S8,9,8.4,9.5l2.5,2.5V4.1C10.9,3.5,11.4,3,12,3s1.1,0.5,1.1,1.1v7.8l2.5-2.5
        C16,9,16.7,9,17.2,9.5s0.4,1.2,0,1.6l-4.4,4.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3,0.1-0.4,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0s0,0,0,0
        c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1-0.1L6.8,11z M19.9,13.5c-0.6,0-1.1,0.5-1.1,1.1v3.5
        c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2H5.9c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4v-3.5c0-0.6-0.5-1.1-1.1-1.1
        S3,14,3,14.6v3.5c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.3,0.8,2,0.8h12.2c0.8,0,1.5-0.3,2-0.8s0.8-1.3,0.8-2v-3.5
        C21,14,20.5,13.5,19.9,13.5z"
      />
    </SvgIcon>
  );
}
