import React, { useState } from "react";
import BaseModal, { BaseModalProps } from "components/modals/BaseModal";
import BasePaper from "components/uiComponents/papers/BasePaper";
import { Box, Typography } from "@material-ui/core";
import TextButton from "components/uiComponents/buttons/TextButton";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import { CheckedIcon } from "components/icons";
import StyledTextField from "components/uiComponents/inputs/styledInputs/StyledTextField";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  helperText: {
    padding: "24px 0 10px",
    color: fade(theme.palette.primary2.main, 0.6),
    fontWeight: 600,
  },
  inputWrap: {
    "&>div": {
      width: "100%",
    },
  },
  formControls: {
    margin: "20px 0 0",
    display: "flex",
    padding: "28px 0 0",
    justifyContent: "flex-end",
    borderTop: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
    "& > div": {
      "& > *": {
        margin: "0 20px",
      },
    },
  },
}));

export default function ConfirmModal({
  open,
  onClickYes,
  closeModal,
  title,
  children,
  controlPharase = null,
  controlPharaseLabel,
  showProgress = false,
}: BaseModalProps & {
  title: string;
  closeModal: () => void;
  onClickYes: () => void;
  controlPharase?: string | null;
  controlPharaseLabel?: string;
  showProgress?: boolean;
}) {
  const classes = useStyles();

  const [controlPhraseInput, setControlPhraseInput] = useState("");
  const isControlPhaseInputValid = controlPharase
    ? controlPhraseInput === controlPharase
    : true;

  return (
    <BaseModal open={open} onClose={closeModal}>
      <Box maxWidth={"571px"} margin={"auto"} width={"100vw"}>
        <BasePaper>
          <Box margin="auto" maxWidth={"450px"} padding={"30px 0"}>
            <Typography variant={"h1"}>{title}</Typography>
            <Box mt={"2px"}>
              <Typography variant={"h3"}>{children}</Typography>
            </Box>

            <Box className={classes.helperText}>
              To confirm, please enter the patient name
            </Box>
            <Box className={classes.inputWrap}>
              {controlPharase && (
                <StyledTextField
                  label={controlPharaseLabel}
                  variant="outlined"
                  onChange={(e) => setControlPhraseInput(e.target.value)}
                />
              )}
            </Box>

            <Box className={classes.formControls}>
              <Box margin="0 -20px">
                <TextButton onClick={closeModal}>Cancel</TextButton>

                <SubmitButton
                  customIcon={<CheckedIcon />}
                  onClick={onClickYes}
                  disabled={!isControlPhaseInputValid || showProgress}
                  showSpinner={showProgress}
                >
                  Unlink Patient
                </SubmitButton>
              </Box>
            </Box>
          </Box>
        </BasePaper>
      </Box>
    </BaseModal>
  );
}
