import { useState, useEffect } from "react";
import { cloneDeep, isObject, clone } from "lodash";
import { filterByHashConfigByPermissions } from "utils/filterByHashConfigByPermissions";

const yup = require("yup");

// TODO apply to inputByHash correct type
interface useFormProps {
  inputsById: string[];
  inputsByHash: any;
  type?: "edit" | "create";
  initDataResource?: null | string;
  initData?: null | { [index: string]: any };
}

const getDefaultValue = (configValue: string, type: string) => {
  let value = null;
  if (configValue !== undefined) {
    value = configValue;
  } else {
    value = type === "date" ? null : "";
  }
  return value;
};

const useForm = ({
  type = "create",
  initDataResource = null,
  initData,
  ...props
}: useFormProps) => {
  const inputsByHashInitState = () => {
    const [
      inputByIdFilteredByAccess,
      inputsByHashFiltedByAccess,
    ] = filterByHashConfigByPermissions(
      props.inputsById,
      props.inputsByHash
    ) as [string[], any];

    const inputsByHash: any = {};

    inputByIdFilteredByAccess.forEach((id) => {
      const input = inputsByHashFiltedByAccess[id];
      inputsByHash[id] = {
        id,
        type: input.type || "text",
        subType: input.subType || false,
        value: getDefaultValue(input.defaultValue || input.value, input.type),
        label: input.label,
        error: false,
        options: input.options || [],
        required: input.required || false,
        validation: input.validation || false,
        submitValueAccessor: input.submitValueAccessor || false,
        endAdornment: input.endAdornment || false,
        excludeSubmit: input.excludeSubmit || false,
        disabled: input.disabled || false,
        setInitValueAccessor: input.setInitValueAccessor || false,
        inputWrapStyles: input.inputWrapStyles || "",
        autocompleteTypeOptions: input.autocompleteTypeOptions || false,
        relativeAutoFillInputs: input.relativeAutoFillInputs || false,
        autoFillAccessor: input.autoFillAccessor || false,
        extraFields: input.extraFields || false,
        inputProps: input.inputProps || {},
      };
    });
    return inputsByHash;
  };

  // const inputsByHashValueInitState = () => {
  //   const inputsByHashValue: any = {};

  //   inputsById.forEach((id) => {
  //     const input = props.inputsByHash[id];
  //     inputsByHashValue[id] = getDefaultValue(input.value, input.type)
  //   });
  //   return inputsByHashValue;
  // };

  const [inputsById] = useState(
    filterByHashConfigByPermissions(
      props.inputsById,
      props.inputsByHash
    )[0] as string[]
  );
  const [inputsByHash, setInputsByHash] = useState(inputsByHashInitState);
  // const [inputsByHashValue, setInputsByHashValue] = useState(inputsByHashValueInitState);

  let schema = yup.object().shape(getSchema());

  useEffect(() => {
    if (initData) {
      setInitValues(initData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  const setInitValues = (initData: { [index: string]: any }) => {
    let updatedInputsByHash = cloneDeep(inputsByHash);

    inputsById.forEach((id: string) => {
      const input = updatedInputsByHash[id];
      let value = initData[id];

      if (input.setInitValueAccessor) {
        value = input.setInitValueAccessor(initData);
      } else {
        if (input.type === "phone") {
          value = "+" + value;
        }
      }

      updatedInputsByHash[id].value = value;
    });

    setInputsByHash(updatedInputsByHash);
  };

  function getSchema() {
    const yupObj: { [index: string]: any } = {};

    inputsById.forEach((id) => {
      const input = inputsByHash[id];

      let required = false;
      let validation = false;

      if (input.hasOwnProperty("required")) {
        required = input.required;
      }

      if (required) {
        if (input.validation) {
          validation = input.validation;
        } else {
          validation = yup
            .string()
            .required(`${input.label} is required`)
            .nullable(`${input.label} is required`);
        }
        yupObj[id] = validation;
      } else if (input.hasOwnProperty("validation")) {
        validation = input.validation;
        if (validation && input.value !== "") {
          yupObj[id] = validation;
        }
      }
    });

    return yupObj;
  }

  const handleInputs = (e: {
    target: { name: string; value: string | object };
  }) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    const value = isObject(inputValue) ? clone(inputValue) : inputValue;
    const input = inputsByHash[inputName];

    // handleRelativeInputs(inputName, inputValue);

    let updatedInputsByHash = { ...inputsByHash };

    if (input.relativeAutoFillInputs) {
      updatedInputsByHash = getInputsByHashWithRelativeAutoFillInputs(
        updatedInputsByHash,
        inputName,
        inputValue
      );
    }

    setInputsByHash({
      ...updatedInputsByHash,
      [inputName]: {
        ...inputsByHash[e.target.name],
        value: value,
      },
    });
  };

  // const handleRelativeInputs = (
  //   inputId: string,
  //   inputValue: string | object
  // ) => {
  //   const input = inputsByHash[inputId];
  //   if (!input.hasOwnProperty("relativeInputs")) return;

  //   const updatedInputsByHash = cloneDeep(inputsByHash);

  //   input.resetRelativeInputs.forEach((relativeInputID: string) => {
  //     updatedInputsByHash[relativeInputID].value = null;
  //     updatedInputsByHash[relativeInputID].value =
  //       input.subType === "multi" ? [] : "";
  //   });

  //   if (inputValue === "") {
  //     input.relativeInputs.forEach((relativeInputID: string) => {
  //       delete updatedInputsByHash[relativeInputID].relativeInputsData[inputId];
  //     });
  //   } else {
  //     input.relativeInputs.forEach((relativeInputID: string) => {
  //       updatedInputsByHash[relativeInputID].relativeInputsData = {
  //         ...updatedInputsByHash[relativeInputID].relativeInputsData,
  //         [inputId]: inputValue,
  //       };
  //     });
  //   }
  //   setInputsByHash(updatedInputsByHash);
  // };

  const getInputsByHashWithRelativeAutoFillInputs = (
    inputsByHash: any,
    inputId: string,
    inputValue: any
  ) => {
    const input = inputsByHash[inputId];

    if (!input.relativeAutoFillInputs) return;
    const updatedInputsByHash = inputsByHash;

    input.relativeAutoFillInputs.forEach((relativeInputID: string) => {
      const autoFillAccessorValue = inputsByHash[
        relativeInputID
      ].autoFillAccessor(inputValue);
      if (autoFillAccessorValue === false) return;
      updatedInputsByHash[relativeInputID].value = autoFillAccessorValue;
    });

    return updatedInputsByHash;
  };

  const clearAllInputs = async () => {
    const initialState = inputsByHashInitState();
    setInputsByHash(initialState);
    return initialState;
  };

  const handleBulkInputs = async (inputs: { id: string; value: string }[]) => {
    const updatedInputsByHash = cloneDeep(inputsByHash);

    inputs.forEach((input) => {
      updatedInputsByHash[input.id] = {
        ...updatedInputsByHash[input.id],
        value: input.value,
      };
    });
    setInputsByHash(updatedInputsByHash);
    return updatedInputsByHash;
  };

  const getSubmitData = ({
    skipNull = true,
    skipEmpty = false,
    skipDisabled = true,
  }: {
    skipNull?: boolean;
    skipEmpty?: boolean;
    skipDisabled?: boolean;
  } = {}) => {
    const data: { [index: string]: any } = {};

    inputsById.forEach((id) => {
      const input = inputsByHash[id];
      const submitId = input.hasOwnProperty("submitViaKey")
        ? input.submitViaKey
        : id;

      if (input.excludeSubmit) return;
      if (skipNull && input.value === null) return;
      if (skipEmpty && input.value === "") return;

      let value = null;

      if (input.submitValueAccessor) {
        value = input.submitValueAccessor(input.value);
      } else if (input.hasOwnProperty("submitValueGlobalAccessor")) {
        value = input.submitValueGlobalAccessor(inputsByHash);
      } else if (input.type === "phone") {
        value = input.value.replaceAll(/ |\+|\(|\)|-/gi, "");
      } else {
        value = input.value;
      }

      if (skipDisabled && input.disabled) return;
      if (skipNull && value === null) return;
      if (skipEmpty && value === "") return;

      data[submitId] = value;
    });
    return data;
  };

  function handleErrors(error: any) {
    const updatedInputsByHash = { ...inputsByHash };

    inputsById.forEach((inputId) => {
      updatedInputsByHash[inputId] = {
        ...inputsByHash[inputId],
        error: false,
        errorMessage: null,
      };
    });

    error.inner.forEach((validationError: any) => {
      updatedInputsByHash[validationError.path] = {
        ...updatedInputsByHash[validationError.path],
        error: true,
        errorMessage: validationError.message,
      };
    });
    setInputsByHash(updatedInputsByHash);
  }

  const isFormValid = (shouldHandleErrors = true) => {
    schema = yup.object().shape(getSchema());
    try {
      return schema.validateSync(getInputValues(), {
        abortEarly: false,
      });
    } catch (error) {
      if (shouldHandleErrors) {
        handleErrors(error);
      }
      return false;
    }
  };

  const getInputValues = () => {
    const inputValues: { [index: string]: any } = {};
    inputsById.forEach((id) => {
      inputValues[id] = inputsByHash[id].value;
    });

    return inputValues;
  };

  const resetErrors = () =>
    new Promise<void>((resolve) => {
      const updatedInputsByHash = { ...inputsByHash };

      inputsById.forEach((inputId) => {
        updatedInputsByHash[inputId] = {
          ...inputsByHash[inputId],
          error: false,
          errorMessage: null,
        };
      });
      setInputsByHash(updatedInputsByHash);
      resolve();
    });

  return {
    inputsById,
    inputsByHash,
    handleInputs,
    handleBulkInputs,
    clearAllInputs,
    getSubmitData,
    isFormValid,
    resetErrors,
    setInitValues,
  };
};

export default useForm;
