import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";
import ThreadMessage from "components/pages/customer/MessagePage/parts/ThreadMessage";

export default function ThreadMessageDateGroup({
  date,
  messages,
  userId,
}: {
  date: string;
  messages: [];
  userId: string;
}) {
  return (
    <Box>
      <Box textAlign={"center"} fontSize="11px" mt={"10px"}>
        {date}
      </Box>
      {messages.map((item: any) => {
        return (
          <ThreadMessage
            key={item.id}
            name={`${item.fromFirstName} ${item.fromLastName}`}
            role={item.fromUserRoleCode}
            time={moment(new Date(item.msgDate)).format("h:mm")}
            isActive={item.fromUserId === userId}
            message={item.msgBody}
            attachments={item.attachments}
          />
        );
      })}
    </Box>
  );
}
