import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.1,6.2h-1.6h-1.3V5.7c0-0.7-0.3-1.4-0.8-1.9C15,3.3,14.3,3,13.6,3h-3.2C9.7,3,9,3.3,8.5,3.8
        C8,4.3,7.7,5,7.7,5.7v0.5H6.5H4.9c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h0.5v9.9c0,0.7,0.3,1.4,0.8,1.9C6.7,20.7,7.3,21,8.1,21
        h7.9c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9V8.4h0.5c0.6,0,1.1-0.5,1.1-1.1S19.7,6.2,19.1,6.2z M9.9,5.7
        c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h3.2c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v0.5H9.9V5.7z M16.4,18.3
        c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1H8.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V8.4h8.8V18.3z"
      />
    </SvgIcon>
  );
}
