import { useState, useEffect, useRef } from "react";
import dataProvider from "providers/dataProvider";
import useDeepEffect from "hooks/useDeepEffect";
import { useAuthCenter } from "AuthCenter";
import axios from "axios";

export default function useProvider(
  type: string,
  resource: string | null,
  params?: {} | null,
  onDataLoaded?: ((data: []) => void) | null,
  dependencies?: any[] | null
) {
  const [data, setData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState<null | {}>(null);
  const { token } = useAuthCenter();
  const componentIsMounted = useRef(true);
  const cancelToken = useRef(null) as any;

  const loadData = () => {
    cancelToken.current = axios.CancelToken.source();
    const BreakException = {};
    let missingDependencies = false;
    if (!resource) return;
    setDataLoading(true);

    if (dependencies) {
      try {
        dependencies.forEach((dependency) => {
          if (dependency === null) {
            handleDataLoaded([]);
            setDataLoading(false);
            missingDependencies = true;
            throw BreakException;
          }
        });
      } catch (e) {
        handleDataLoaded([]);
        setDataLoading(false);
        if (e !== BreakException) throw e;
      }
    }

    if (missingDependencies) return;

    dataProvider(type, resource, params || {}, {
      cancelToken: cancelToken.current.token,
    })
      .then(({ data, total }) => {
        setDataLoading(false);
        handleDataLoaded(data);
        setDataLoaded(true);
      })
      .catch((error) => {
        if (componentIsMounted.current) {
          setError(error);
        }
      });
  };

  const updateCurrentData = (
    filterFunc: (item: { [index: string]: any }) => boolean,
    updateFunc: (item: { [index: string]: any }) => { [index: string]: any }
  ) => {
    const updatedData = [...data] as { [index: string]: any }[];

    for (const i in updatedData) {
      if (filterFunc(updatedData[i])) {
        updatedData[i] = { ...updatedData[i], ...updateFunc(updatedData[i]) };
      }
    }

    setData(updatedData as []);
  };

  const handleDataLoaded = (data: any) => {
    setData(data);
    if (onDataLoaded) {
      onDataLoaded(data);
    }
  };

  const cancelLoadData = () => {
    if (cancelToken.current !== undefined && cancelToken.current !== null) {
      cancelToken.current.cancel();
    }
  };

  useDeepEffect(() => {
    if (!resource) return;
    cancelLoadData();
    loadData();
  }, [type, resource, params, token]);

  useEffect(() => {
    componentIsMounted.current = true;
    return function () {
      componentIsMounted.current = false;
      cancelLoadData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    dataLoaded,
    dataLoading,
    error,
    loadData,
    updateCurrentData,
  };
}
