import React from "react";
import { Box } from "@material-ui/core";
import ThreadListItem from "components/pages/customer/MessagePage/parts/ThreadListItem";
import moment from "moment";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

export default function ThreadList({
  onSelectSubject,
  selectedSubjectId,
  list,
  dataLoading = false,
}: {
  onSelectSubject: (subject: any) => void;
  selectedSubjectId: string | null;
  list: any[];
  dataLoading?: boolean;
}) {
  return (
    <Box position="relative" minHeight="200px">
      {dataLoading && <CircularProgressShadow />}
      {list.length === 0 && !dataLoading && (
        <Box textAlign="center" mt={"20px"} fontSize="15px">
          Nothing Found
        </Box>
      )}
      {list.map((item: any) => {
        return (
          <ThreadListItem
            key={item.id}
            name={`${item.fromFirstName} ${item.fromlastName}`}
            subject={item.msgSubject}
            date={moment(new Date(item.msgDate)).format("h:mm, MMM D")}
            isActive={selectedSubjectId === item.id}
            isRead={item.isRead === 1}
            isArchived={item.isArchived === 1}
            isFollowUp={item.isFollowUp === 1}
            onClick={() => {
              onSelectSubject(item);
            }}
          />
        );
      })}
    </Box>
  );
}
