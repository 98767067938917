import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function KeyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.5,20.6C8.5,20.6,8.5,20.6,8.5,20.6c-0.8,0-1.5-0.1-2.1-0.4c-0.7-0.3-1.3-0.7-1.8-1.2C3.5,18,3,16.6,3,15.1
        c0-1.5,0.6-2.8,1.6-3.8c1-1,2.4-1.6,3.8-1.6c1,0,2.1,0.3,2.9,0.8l6.8-6.8c0.5-0.5,1.2-0.5,1.7,0c0.2,0.2,0.4,0.5,0.4,0.9
        c0,0.3-0.1,0.6-0.4,0.9l-0.7,0.7l1.5,1.5c0.5,0.5,0.5,1.2,0,1.7l-2.7,2.7c-0.5,0.5-1.2,0.5-1.7,0l-1.5-1.5l-1.6,1.6
        c0.2,0.3,0.3,0.5,0.4,0.8c0.3,0.7,0.4,1.4,0.4,2.1c0,0.7-0.1,1.4-0.4,2.1c-0.3,0.7-0.7,1.3-1.2,1.8c-0.5,0.5-1.1,0.9-1.8,1.2
        C9.9,20.5,9.2,20.6,8.5,20.6z M8.5,12.1C8.5,12.1,8.5,12.1,8.5,12.1c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2
        c0,0.8,0.3,1.6,0.9,2.2c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.2,1.2,0.2c0.5,0,0.8-0.1,1.2-0.2c0.4-0.2,0.7-0.4,1-0.7
        c0.3-0.3,0.5-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.4-0.1-0.8-0.2-1.2c-0.2-0.4-0.4-0.7-0.7-1C10.1,12.4,9.3,12.1,8.5,12.1z
         M16.4,8.9l0.6,0.6l1-1l-0.6-0.6L16.4,8.9z"
      />
    </SvgIcon>
  );
}
