import { useState } from "react";
import usePostProvider from "hooks/usePostProvider";
import { FileItemType } from "components/uiComponents/inputs/styledInputs/StyledFileInput/StyledFileInput";
import axios from "axios";

export default function useUploader(
  initResource: string | null = null,
  initParams: {} = {}
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState<null | {}>(null);

  const { postData: initCall } = usePostProvider("post");
  const { postData: confirmCall } = usePostProvider("post");

  const uploadFiles = async (
    initResource: string,
    confirmResource: string,
    files: FileItemType[]
  ) => {
    setDataLoading(true);
    try {
      await Promise.all(
        files.map(async (file) => {
          await upladFile(initResource, confirmResource, file);
        })
      );
    } catch (err) {
      setError(error);
    }

    setDataLoading(false);
  };

  const upladFile = async (
    initResource: string,
    confirmResource: string,
    file: FileItemType
  ) => {
    const initResponse = (await initCall(initResource, {
      data: { attachFileName: file.name },
    })) as any;

    const s3Response = await uploadFileToS3(
      initResponse.data.url,
      initResponse.data.fields,
      file.file
    );

    if (s3Response.status !== 204) {
      return false;
    }

    await confirmCall(confirmResource, {
      data: {
        attachFileName: initResponse.data.newFileName,
        attachOriginalFileName: file.name,
      },
    });
  };

  const uploadFileToS3 = async (url: string, fields: any, file: any) => {
    let formData = new FormData();

    formData.set("key", fields["key"]);
    formData.set("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.set("x-amz-credential", fields["x-amz-credential"]);
    formData.set("x-amz-date", fields["x-amz-date"]);
    formData.set("x-amz-security-token", fields["x-amz-security-token"]);
    formData.set("policy", fields["policy"]);
    formData.set("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);

    return axios.request({ url: url, method: "POST", data: formData });
  };

  return {
    dataLoading,
    error,
    uploadFiles,
  };
}
