import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { ListIcon } from "components/icons";
import useModal from "hooks/useModal";
import AttachmentImagePreviewModal from "components/modals/AttachmentImagePreviewModal";
import { isFileNameWithImageExtension } from "utils";
import useImageLoader from "hooks/useImageLoader";
import ImageIcon from "@material-ui/icons/Image";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -3px",
  },
  attachmentItem: {
    background: fade(theme.palette.primary2.main, 0.05),
    padding: "6px",
    display: "flex",
    maxWidth: "240px",
    margin: "6px 3px 0",
    borderRadius: "3px",
    fontWeight: 600,
    cursor: "pointer",
    transition: "background .5s",
    "&:hover": {
      background: fade(theme.palette.primary2.main, 0.1),

      "& $iconWrap": {
        background: fade("#fff", 0.8),
        color: theme.palette.primary2.main,
      },
    },
  },
  iconWrap: {
    background: fade("#fff", 0.6),
    padding: "5px",
    minWidth: "48px",
    minHeight: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    border: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
    color: fade(theme.palette.primary2.main, 0.4),
    transition: ".5s",
    position: "relative",
  },
  fileName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  linkWrap: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const ImageAttachmentIcon = ({
  dataLoading = false,
}: {
  dataLoading: boolean;
}) => {
  return (
    <React.Fragment>
      {dataLoading && <CircularProgressShadow />}
      <ImageIcon color="inherit" />
    </React.Fragment>
  );
};

export default function MessageAttachmentsList({
  attachments = [],
}: {
  attachments: any[];
}) {
  const classes = useStyles();
  const [isModalOpen, openModal, closeModal] = useModal();
  const [selectedImageUrl, setSelectedImageUrl] = useState<undefined | string>(
    undefined
  );
  const {
    loadImage,
    isLoading: imageIsLoading,
    loadingError: imageLoadingError,
  } = useImageLoader();

  const handleClickAttachment = async (item: any) => {
    setSelectedImageUrl(item.attachUrl);
    try {
      await loadImage(item.attachUrl);
    } catch (err) {}
    openModal();
  };

  return (
    <Box>
      <AttachmentImagePreviewModal
        open={isModalOpen}
        imageUrl={selectedImageUrl}
        closeModal={closeModal}
        imageUploadingError={imageLoadingError}
      ></AttachmentImagePreviewModal>
      <Box className={classes.root}>
        {attachments.map((item) => {
          const isImageAttachment = isFileNameWithImageExtension(
            item.attachOriginalFileName
          );

          return (
            <a
              rel="noreferrer"
              target="_blank"
              href={item.attachUrl}
              className={classes.linkWrap}
              key={item.id}
              onClick={(e) => {
                if (isImageAttachment) {
                  e.preventDefault();
                  handleClickAttachment(item);
                }
              }}
            >
              <Box className={classes.attachmentItem}>
                <Box className={classes.iconWrap}>
                  {isImageAttachment ? (
                    <ImageAttachmentIcon dataLoading={imageIsLoading} />
                  ) : (
                    <ListIcon color="inherit" />
                  )}
                </Box>
                <Box className={classes.fileName} p="5px">
                  {item.attachOriginalFileName}
                </Box>
              </Box>
            </a>
          );
        })}
      </Box>
    </Box>
  );
}
