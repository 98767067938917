import React from "react";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import {
  CheckboxIcon,
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
} from "components/icons";

export default function CheckboxTableCell({
  checkboxProps,
  tableCellProps,
}: {
  checkboxProps?: CheckboxProps;
  tableCellProps?: TableCellProps;
}) {
  return (
    <TableCell padding="checkbox" {...tableCellProps}>
      <Checkbox
        icon={<CheckboxIcon />}
        checkedIcon={<CheckboxCheckedIcon color="primary" />}
        indeterminateIcon={<CheckboxIndeterminateIcon color="primary" />}
        {...checkboxProps}
      />
    </TableCell>
  );
}
