import React, { useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import SmartTable, {
  SmartTableRef,
} from "components/uiComponents/table/SmartTable";
import syncCentertTableConfig from "config/admin/sync/syncCentertTableConfig";
import { Box } from "@material-ui/core";
import PaperTable from "components/uiComponents/papers/AdminTablePaper";
import SearchFilter from "components/uiComponents/Filter/SearchFilter";
import syncCenterFilterConfig from "config/admin/sync/syncCenterFilterConfig";

export default function PatientsPage() {
  const [filter, setFilter] = useState({});
  const tableRef = useRef<SmartTableRef>(null);

  const handleFilter = (filter: { [index: string]: any }) => {
    tableRef!.current!.resetCurrentPage();
    setFilter(filter);
  };

  return (
    <Container>
      <PaperTable
        title={"Data Sync Center"}
        controls={
          <Box display="flex">
            <SearchFilter
              onSubmitFilter={handleFilter}
              showSearch={false}
              filterConfig={syncCenterFilterConfig}
            />
          </Box>
        }
      >
        <SmartTable
          ref={tableRef}
          id="syncList"
          resource={`etl`}
          columns={syncCentertTableConfig}
          showPagination={true}
          filter={filter}
        />
      </PaperTable>
    </Container>
  );
}
