import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SmartInputProps from "interfaces/SmartInputInterface";
import StyledTextField from "components/uiComponents/inputs/styledInputs/StyledTextField";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const SmartPasswordInput = (props: SmartInputProps) => {
  const { input, inputId, handleInputs } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <StyledTextField
      value={input.value === null ? "" : input.value}
      error={input.error}
      name={inputId}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      multiline={input.subType ? input.subType === "multiline" : false}
      label={input.label}
      onChange={handleInputs}
      disabled={input.disabled}
      helperText={input.error && input.errorMessage}
      InputProps={{
        startAdornment: input.icon && (
          <InputAdornment position="start">{input.icon}</InputAdornment>
        ),
        endAdornment: (
          <React.Fragment>
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {input.endAdornment}
          </React.Fragment>
        ),
      }}
      {...input.extraFields}
    />
  );
};

export default SmartPasswordInput;
