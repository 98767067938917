import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function FlagFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.9,3.9C18.6,3.8,18.2,3.8,18,4c0,0-0.7,0.6-2.7,0.6c-1.1,0-2-0.4-3-0.8C11.2,3.4,10.1,3,8.7,3
        C6.1,3,5.1,3.9,4.9,4.1C4.7,4.2,4.6,4.4,4.6,4.7v9.8v5.7C4.6,20.6,5,21,5.5,21c0.5,0,0.8-0.4,0.8-0.8v-5.3c0.4-0.2,1.1-0.4,2.4-0.4
        c1.1,0,2,0.4,3,0.8c1.1,0.4,2.2,0.9,3.6,0.9c2.7,0,3.7-0.9,3.9-1.1c0.2-0.2,0.2-0.4,0.2-0.6V4.7C19.4,4.3,19.2,4,18.9,3.9z"
      />
    </SvgIcon>
  );
}