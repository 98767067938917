import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SmartInputProps from "interfaces/SmartInputInterface";
import MuiPhoneInput from "material-ui-phone-number";

const useStyles = makeStyles(() => ({
  helperText: {
    lineHeight: 1.1,
    letterSpacing: 0,
  },
  root: {
    borderRadius: "1px",
  },
}));

const PhoneInput = (props: SmartInputProps) => {
  const { input, inputId, handleInputs } = props;
  const classes = useStyles();

  return (
    <MuiPhoneInput
      className={classes.root}
      name={inputId}
      defaultCountry="us"
      label={input.label}
      variant={"outlined"}
      value={input.value}
      disableAreaCodes={true}
      onChange={(phone) => {
        handleInputs({
          target: {
            name: inputId,
            value: phone,
          },
        });
      }}
    />
  );
};

export default PhoneInput;
