import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import birthdayInputConfig from "config/inputs/birthdayInputConfig";
import genderInputConfig from "config/inputs/genderInputConfig";
import { convertServerDOB } from "utils";
import { canNotCustomerSubRoles, canByRoles } from "utils/permissions";
import { userRoles } from "config/userRoles";

export const getPatientEditFormInputByHashConfig = () => {
  const isPatient =
    canNotCustomerSubRoles(["guardianSelf"]) &&
    canByRoles([userRoles.customer]);

  const patientEditForm: InputByHashConfigInterface = {
    inputsById: [
      "userName",
      "userFirstName",
      "userMiddleName",
      "userLastName",
      "userPhone",
      "userEmail",
      "userDob",
      "userGenderCode",
    ],

    inputsByHash: {
      userName: {
        label: "Username",
        disabled: true,
      },
      userFirstName: {
        label: "First Name",
        disabled: isPatient,
      },
      userMiddleName: {
        label: "Middle Name",
        disabled: isPatient,
      },
      userLastName: {
        label: "Last Name",
        disabled: isPatient,
      },
      userGenderCode: {
        ...genderInputConfig,
        disabled: isPatient,
        inputWrapStyles: `
          @media(min-width: 601px) {
            padding: 26px 10px 0;
          };
        `,
      },
      userPhone: {
        label: "Phone",
        type: "phone",
        required: true,
      },
      userEmail: {
        label: "Email",
        required: true,
      },
      userDob: {
        ...{ ...birthdayInputConfig, label: "Date of Birth" },
        setInitValueAccessor: (d) => {
          return d.userDob ? convertServerDOB(d.userDob) : null;
        },
        disabled: isPatient,
      },
    },
  };
  return patientEditForm;
};
