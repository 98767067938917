import React, { useImperativeHandle, forwardRef } from "react";
import InputsList from "components/uiComponents/inputsList/InputsList";
import useForm from "hooks/useForm";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import InputByHashInterface from "interfaces/InputByHashInterface";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Box } from "@material-ui/core";
import CircularProgressShadow from "components/uiComponents/CircularProgressShadow";

const useStyles = makeStyles((theme) => ({
  formControls: {
    margin: "44px 0 0",
    display: "flex",
    padding: "28px 0 0",
    justifyContent: "space-between",
    borderTop: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
  },
  form: {
    position: "relative",
  },
}));

export type AuthFormLayoutProps = {
  inputsById: string[];
  inputsByHash: InputByHashInterface;
  submitLabel?: string;
  isDataLoading?: boolean;
  showCircularProgressShadow?: boolean;
  onSubmitValidForm?: (data: { [index: string]: any }) => void;
  backButton?: JSX.Element[] | JSX.Element | string;
  beforeInputs?: JSX.Element[] | JSX.Element | string;
  afterInputs?: JSX.Element[] | JSX.Element | string | null;
  showControls?: boolean;
  initData?: { [index: string]: any } | null;
  validateForm?: boolean;
};

export interface Ref {
  clearAllInputs(): void;
  handleSubmit(event: React.MouseEvent<unknown>): void;
  setInitValues(initData: { [index: string]: any }): void;
}

const AuthFormLayout = forwardRef<Ref, AuthFormLayoutProps>(
  (
    {
      submitLabel,
      onSubmitValidForm = () => {},
      backButton,
      isDataLoading = false,
      beforeInputs,
      afterInputs,
      showControls = true,
      showCircularProgressShadow = false,
      initData = null,
      validateForm = true,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
      clearAllInputs: () => {
        clearAllInputs();
      },
      handleSubmit: (event: React.MouseEvent<unknown>) => {
        handleSubmit(event);
      },
      setInitValues: (initData: { [index: string]: any }) => {
        setInitValues(initData);
      },
    }));

    const {
      inputsById,
      inputsByHash,
      handleInputs,
      getSubmitData,
      resetErrors,
      isFormValid,
      clearAllInputs,
      setInitValues,
    } = useForm({
      inputsById: props.inputsById,
      inputsByHash: props.inputsByHash,
      initData: initData,
    });

    const handleSubmit = async (event: React.MouseEvent<unknown>) => {
      event.preventDefault();
      if (validateForm && isFormValid()) {
        resetErrors();
        onSubmitValidForm(getSubmitData());
      }
      else if (!validateForm){
        resetErrors();
        onSubmitValidForm(getSubmitData());
      }
    };

    return (
      <form className={classes.form}>
        {beforeInputs && beforeInputs}
        <InputsList
          inputsById={inputsById}
          inputsByHash={inputsByHash}
          handleInputs={handleInputs}
          inputWrapStyles={"width: 100%;"}
        />

        {showCircularProgressShadow && <CircularProgressShadow />}
        {afterInputs && afterInputs}

        {showControls && (
          <Box className={classes.formControls}>
            <span>{backButton}</span>
            <SubmitButton showSpinner={isDataLoading} onClick={handleSubmit}>
              {submitLabel}
            </SubmitButton>
          </Box>
        )}
      </form>
    );
  }
);

export default AuthFormLayout;
