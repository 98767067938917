import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function LogOutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.9,12.3c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1l-4.5,4.5c-0.2,0.2-0.4,0.2-0.6,0.2s-0.4-0.1-0.6-0.2
        c-0.3-0.3-0.3-0.8,0-1.2l3.1-3.2H9.3c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h8.9L15.1,8c-0.3-0.3-0.3-0.8,0-1.2
        c0.3-0.3,0.8-0.3,1.2,0l4.5,4.5c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0C21,12.1,21,12.2,20.9,12.3z
         M9.3,19.4H5.6c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7V5.6c0-0.3,0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3h3.6
        c0.5,0,0.8-0.4,0.8-0.8S9.7,3,9.3,3H5.6C4.9,3,4.3,3.3,3.8,3.8S3,4.9,3,5.6v12.7c0,0.7,0.3,1.4,0.8,1.9C4.3,20.7,4.9,21,5.6,21h3.6
        c0.5,0,0.8-0.4,0.8-0.8S9.7,19.4,9.3,19.4z"
      />
    </SvgIcon>
  );
}
