import { useState } from "react";
import dataProvider from "providers/dataProvider";

export default function usePostProvider(
  type: "post" | "put" | "delete",
  initResource: string | null = null,
  initParams: {} = {}
) {
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState<null | {}>(null);

  const postData = async (resource: string | null = null, params: {} = {}) => {
    return new Promise((resolve, reject) => {
      setDataLoading(true);

      let mergedResource = initResource ? initResource : "";

      if (resource) {
        mergedResource = resource;
      }

      const mergedParams = { ...initParams, ...params };

      dataProvider(type, mergedResource, mergedParams)
        .then((data) => {
          setDataLoading(false);
          resolve(data);
        })
        .catch((error) => {
          setDataLoading(false);
          setError(error);
          reject(error);
        });
    });
  };

  return {
    dataLoading,
    error,
    postData,
  };
}
