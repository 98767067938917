import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ArchiveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.2,4.5H3.8C3.3,4.5,3,4.8,3,5.3V9c0,0.4,0.3,0.8,0.8,0.8h0.7v9c0,0.4,0.3,0.8,0.8,0.8h13.5
        c0.4,0,0.8-0.3,0.8-0.8v-9h0.7C20.7,9.8,21,9.4,21,9V5.3C21,4.8,20.7,4.5,20.2,4.5z M4.5,6h14.9v2.2H4.5V6z M18,18H6V9.8H18V18z
         M9.7,12c0-0.4,0.3-0.8,0.8-0.8h3c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8h-3C10.1,12.8,9.7,12.4,9.7,12z"
      />
    </SvgIcon>
  );
}