import React from "react";
import {
  TableBody,
  Table,
  TableContainer,
  Hidden,
  Box,
} from "@material-ui/core";
import {
  BaseTableRow,
  MobileTableRow,
} from "components/uiComponents/table/rows";
import BaseTableHeader from "components/uiComponents/table/BaseTableHeader";
import TableBodyNoDataFound from "components/uiComponents/table/TableBodyNoDataFound";
import { BaseTableProps } from "components/uiComponents/table/Interfaces";

const BaseTable = ({
  rows,
  columns,
  isDataLoading = false,
  rowIdByKey = "id",
  rowSelect = false,
  selectedRowIds = [],
  handleRowClick = null,
  handleSelectRow = () => {},
  onSelectAllClick = () => {},
  order,
  orderBy,
  setOrderBy,
  mobileConfig,
}: BaseTableProps) => {
  const isSelected = (id: string) => selectedRowIds.indexOf(id) !== -1;

  return (
    <TableContainer>
      <Box minHeight={"100px"}>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <Hidden xsDown>
            <BaseTableHeader
              rows={rows}
              columns={columns}
              order={order}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              selectedRowIds={selectedRowIds}
              rowCount={rows.length}
              onSelectAllClick={onSelectAllClick}
              rowSelect={rowSelect}
            />
          </Hidden>

          {!isDataLoading && rows.length === 0 && (
            <TableBodyNoDataFound columnsLength={columns.length} />
          )}

          {
            <TableBody>
              {rows.map((row) => {
                const rowId = row[rowIdByKey];
                const isItemSelected = isSelected(rowId);

                return (
                  <React.Fragment key={rowId}>
                    <Hidden xsDown>
                      <BaseTableRow
                        id={rowId}
                        rowSelect={rowSelect}
                        row={row}
                        handleRowClick={handleRowClick}
                        handleSelectRow={handleSelectRow}
                        isSelected={isItemSelected}
                        columns={columns}
                      />
                    </Hidden>
                    <Hidden smUp>
                      <MobileTableRow
                        id={rowId}
                        rowSelect={rowSelect}
                        row={row}
                        handleRowClick={handleRowClick}
                        handleSelectRow={handleSelectRow}
                        isSelected={isItemSelected}
                        columns={columns}
                        mobileConfig={mobileConfig}
                      />
                    </Hidden>
                  </React.Fragment>
                );
              })}
            </TableBody>
          }
        </Table>
      </Box>
    </TableContainer>
  );
};

export default BaseTable;
