import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.4,12l6.1-6.1c0.7-0.7,0.7-1.7,0-2.4c-0.7-0.7-1.7-0.7-2.4,0L12,9.6L5.9,3.5c-0.7-0.7-1.7-0.7-2.4,0
        c-0.7,0.7-0.7,1.7,0,2.4L9.6,12l-6.1,6.1c-0.7,0.7-0.7,1.7,0,2.4C3.8,20.8,4.2,21,4.7,21c0.4,0,0.9-0.2,1.2-0.5l6.1-6.1l6.1,6.1
        c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.7,0-2.4L14.4,12z"
      />
    </SvgIcon>
  );
}
