import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,21c-0.8,0-1.5-0.7-1.5-1.5V12c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v7.5C13.5,20.3,12.8,21,12,21z
        M13.5,4.5C13.5,3.7,12.8,3,12,3h0c-0.8,0-1.5,0.7-1.5,1.5S11.2,6,12,6C12.8,6,13.5,5.3,13.5,4.5z"
      />
    </SvgIcon>
  );
}