import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";

const getElementsHeight = (
  selectors: string[],
  includeMargin: boolean = false
) => {
  let height = 0;
  selectors.forEach((selector) => {
    const query = document.querySelector<HTMLElement>(selector);
    if (query) {
      let marginHeight = 0;
      if (includeMargin) {
        let style = getComputedStyle(query);

        marginHeight += parseInt(style.marginTop);
        marginHeight += parseInt(style.marginBottom);
      }

      height += query!.offsetHeight + marginHeight;
    } else {
    }
  });
  return height;
};

export default function FullHeight({
  children,
  reduceElementHeight = [],
  disabled = false,
}: {
  children: JSX.Element;
  reduceElementHeight?: string[];
  disabled?: boolean;
}) {
  const rootRef = useRef<HTMLDivElement>(null);
  let timeout = useRef<number>(0);
  const windowHeight = useWindowSize()[1];
  const [height, setHeight] = useState<"auto" | number>("auto");

  useEffect(() => {
    if (disabled) return;
    clearInterval(timeout.current);
    // setHeight("auto");
    timeout.current = window.setTimeout(() => {
      const totalReduceHeight = getElementsHeight(reduceElementHeight, true);
      setHeight(windowHeight - totalReduceHeight);
    }, 100);
    // const totalReduceHeight = getElementsHeight(reduceElementHeight, true);
    // setHeight(windowHeight - totalReduceHeight);
  }, [windowHeight, reduceElementHeight, disabled]);

  return (
    <div
      style={{
        height: disabled ? "auto" : height,
      }}
      ref={rootRef}
    >
      {children}
    </div>
  );
}
