import React from "react";
import {
  ClickAwayListener,
  Box,
  MenuList,
  Popper,
  Paper,
  Grow,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import PaperControlButton from "components/uiComponents/buttons/PaperControlButton";
import { SortIcon } from "components/icons";
import { SortType } from "hooks/useSort";
import clsx from "clsx";
import { SortDescIcon, SortAscIcon } from "components/icons";
import { fade } from "@material-ui/core/styles/colorManipulator";

export type SortConfigType = { id: string; label: string }[];
export type HandleSortByType = (sortBy: string) => void;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
    activeMenuItem: {
      color: theme.palette.primary.main,
    },
    menuItem: {
      minHeight: "43px",
      borderBottom: `1px solid ${fade(theme.palette.primary2.main, 0.1)}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
  })
);

const usePaperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1000,
      padding: "0",
      boxShadow: "0px 6px 26px -8px rgba(0,0,0,0.2) !important",
      minWidth: "150px",
      [theme.breakpoints.down("sm")]: {
        padding: "15px 15px",
      },
    },
    rounded: {
      borderRadius: "12px",
    },
  })
);

export default function Sort({
  sort,
  sortConfig,
  handleSortBy,
}: {
  sort: SortType;
  sortConfig: SortConfigType;
  handleSortBy: HandleSortByType;
}) {
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  let sortBy = null as any;
  let sortOrder = null as any;

  if (sort) {
    sortBy = sort[0];
    sortOrder = sort[1];
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      // event.preventDefault();
      setOpen(false);
    }
  }

  let menuButton = <SortIcon />;

  if (sortBy) {
    menuButton = sortOrder === "asc" ? <SortAscIcon /> : <SortDescIcon />;
  }

  return (
    <div className={classes.root}>
      <div>
        <PaperControlButton
          ref={anchorRef}
          icon={menuButton}
          onClick={handleToggle}
          subVariant={"icon"}
          status={sortBy ? "active" : "normal"}
        ></PaperControlButton>

        <Popper
          style={{ zIndex: 1 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          // disablePortal
          placement={"bottom-end"}
          onClick={() => {
            setOpen(false);
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper classes={paperClasses}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    // autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {sortConfig.map(({ id, label }) => {
                      const isActiveItem = sortBy === id;

                      const orderIcon =
                        sortOrder === "desc" ? (
                          <SortDescIcon />
                        ) : (
                          <SortAscIcon />
                        );

                      return (
                        <MenuItem
                          className={clsx({
                            [classes.menuItem]: true,
                            [classes.activeMenuItem]: isActiveItem,
                          })}
                          onClick={() => handleSortBy(id)}
                        >
                          {label}
                          {isActiveItem && (
                            <Box ml={"4px"} position="relative" top="3px">
                              {orderIcon}
                            </Box>
                          )}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
