import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

export default function SmartCheckboxInput(props: any) {
  const { input, inputId, handleInputs } = props;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputs({
      target: { name: inputId, value: event.target.checked ? 1 : 0 },
    });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!!input.value}
          onChange={onChange}
          name={inputId}
          color="primary"
        />
      }
      label={input.label}
    />
  );
}
