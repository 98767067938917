import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    primary2: Palette['primary'];
  }
  interface PaletteOptions {
    primary2: PaletteOptions['primary'];
  }

  interface Palette {
    secondary2: Palette['primary'];
  }
}

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1210,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#408EFF',
    },
    primary2: {
      main: '#445369',
    },
    secondary: {
      main: '#00C9B1',
    },
    text: {
      primary: fade('#445369', 0.8),
    },
    error: {
      main: '#EB5757',
    },
    success: {
      main: "#27AE60"
    },
    warning: {
      main: "#F2994A"
    }
  },
  typography: {
    fontSize: 13,
    fontFamily: ['"Josefin Sans"'].join(','),
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
        },
      },
    },
    MuiTableCell: {
      root: {
        textTransform: 'none',
        padding: 18,
      },
      sizeSmall: {
        '&.MuiTableCell-paddingCheckbox': {
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '17px 18px',
      },
      root: { borderRadius: 10 },
      multiline: {
        padding: '17px 18px',
      }
    },

    MuiTypography: {
      h1: {
        fontSize: '1.95rem',
        fontWeight: 600,
      },
      h2: {
        fontSize: '26px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '18px',
      },
      h4: {
        fontSize: '13px',
      },
      h6: {
        fontSize: '13px',
        fontWeight: 400,
      },
    },
  },
  props: {},
});

theme.typography.h1 = {
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h2 = {
  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '18px',
  },
};


export default theme;
