import React, { useState, useEffect } from "react";
import SmartTable from "components/uiComponents/table/SmartTable";
import tableConfig from "config/customer/testReports/tableConfig";
import useDownloader from "hooks/useDownloader";
import Timeline from "components/uiComponents/timeline/Timeline";
import Container from "@material-ui/core/Container";
import HistoryChart from "components/uiComponents/HistoryChart";
import { DOWNLOAD_FILE } from "providers/dataProvider";
import { PaperWithTitle } from "components/uiComponents/papers";
import { enhanceActionConfig } from "utils";

export default function SinglePatientContent ({
  patientId,
}: {
  patientId: number | null;
}) {
  const [isDataDownloading, downloadData] = useDownloader();
  const [selectedReqId, setSelectedReqId] = useState<null | number>(null);
  const [tableResouce, setTableResource] = useState<null | string>(null);

  const downloadReport = (rows: {}[]) => {};

  const handleClickDownload = (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    downloadData(
      DOWNLOAD_FILE,
      `patients/${patientId}/reqs/${selectedReqId}/reports/${row.reportId}/view`
    );
  };

  useEffect(() => {
    setSelectedReqId(null);
  }, [patientId]);

  useEffect(() => {
    if (patientId && selectedReqId) {
      setTableResource(`patients/${patientId}/reqs/${selectedReqId}/reports`);
    } else {
      setTableResource(null);
    }
  }, [patientId, selectedReqId]);

  const handleClickBulkDownload = (
    event: React.MouseEvent<unknown>,
    rows: {}[]
  ) => {
    downloadReport(rows);
  };

  const tableActionsConfig = {
    reportAlias: {
      onClick: handleClickDownload,
      onClickBulkAction: handleClickBulkDownload,
      inProgress: isDataDownloading,
    },
    collectedTz: {
      onClick: () => {},
    },
  };

  const tableConfigWithClickHandlers = enhanceActionConfig(
    tableConfig,
    tableActionsConfig
  );

  return (
    <div>
      {patientId && (
        <Timeline
          selectedPatientId={patientId}
          setSelectedReqId={setSelectedReqId}
          selectedReqId={selectedReqId}
        />
      )}

      <Container>
        <PaperWithTitle>
          <React.Fragment>
            {patientId && selectedReqId && (
              <SmartTable
                id="clientList"
                rowIdByKey={"reportId"}
                resource={tableResouce}
                columns={tableConfigWithClickHandlers}
              />
            )}
          </React.Fragment>
        </PaperWithTitle>
      </Container>

      <HistoryChart selectedPatientId={patientId} />
    </div>
  );
};