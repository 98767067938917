export default function filterArrayOfObjectsByDate(
  objects: {}[],
  key: string,
  year: number,
  month?: number,
): { [key: string]: any }[] {
  return objects.filter((obj: { [key: string]: any }) => {
    const objDate = new Date(obj[key]);
    const objDateYear = objDate.getFullYear();

    if (!month) {
      return objDateYear === year;
    }
    const objDataMonthIndex = objDate.getMonth();
    return objDateYear === year && objDataMonthIndex === month;
  });
}
