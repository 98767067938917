import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function MagnifierIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.8,19.7l-3.5-3.5c1.2-1.4,1.9-3.2,1.9-5.2c0-4.5-3.6-8.1-8.1-8.1C6.6,3,3,6.6,3,11.1c0,4.5,3.6,8.1,8.1,8.1
        c2,0,3.8-0.7,5.2-1.9l3.5,3.5c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2C21.1,20.5,21.1,20,20.8,19.7z M4.5,11.1c0-3.6,3-6.6,6.6-6.6
        s6.6,3,6.6,6.6c0,1.8-0.7,3.5-1.9,4.7c0,0,0,0,0,0c0,0,0,0,0,0c-1.2,1.2-2.8,1.9-4.7,1.9C7.4,17.7,4.5,14.7,4.5,11.1z"
      />
    </SvgIcon>
  );
}