import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function PaperClipIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.9,20.8c-1.5,0-2.9-0.6-4-1.6c-1-1-1.6-2.5-1.6-4c0-1.5,0.6-2.9,1.6-4L12.2,4c1.5-1.5,4.1-1.5,5.7,0
        c0.8,0.8,1.2,1.8,1.2,2.8c0,1.1-0.4,2.1-1.2,2.8l-7.3,7.3c-0.9,0.9-2.5,0.9-3.4,0c-0.5-0.5-0.7-1.1-0.7-1.7c0-0.6,0.3-1.2,0.7-1.7
        l6.8-6.7c0.3-0.3,0.8-0.3,1.2,0c0.3,0.3,0.3,0.8,0,1.2l-6.8,6.7c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5
        c0.3,0.3,0.8,0.3,1.1,0l7.3-7.3c0.4-0.4,0.7-1.1,0.7-1.7c0-0.6-0.2-1.2-0.7-1.7c-0.9-0.9-2.5-0.9-3.3,0l-7.3,7.3
        c-0.7,0.7-1.2,1.7-1.2,2.8c0,1,0.4,2.1,1.2,2.8c1.5,1.5,4.1,1.5,5.6,0l7.3-7.3c0.3-0.3,0.8-0.3,1.2,0s0.3,0.8,0,1.2l-7.3,7.3
        C11.8,20.3,10.4,20.8,8.9,20.8z"
      />
    </SvgIcon>
  );
}
