import React from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import authStorage from 'providers/authStorage';

const GuestRoute = ({component, ...rest}) => {
    return (<Route {...rest} render={(props) => {
        if (authStorage.getAccess()) {
            return (
                <Redirect
                    to={{
                        pathname: "/404",
                        from: props.from
                    }}
                />
            )
        } else {
            return (React.createElement(component, props))
        }
    }}/>)
}

export default GuestRoute;