import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const helpRequestForm: InputByHashConfigInterface = {
  inputsById: [
    "userName",
    "userFirstName",
    "userLastName",
    "userRoleCode",
    "userPhone",
    "userEmail",
  ],

  inputsByHash: {
    userName: {
      label: "Username",
      required: true,
    },
    userFirstName: {
      label: "First Name",
      required: true,
    },
    userLastName: {
      label: "Last Name",
      required: true,
    },
    userRoleCode: {
      label: "Role",
      type: "autocomplete",
      autocompleteTypeOptions: {
        resource: "lovs/USERROLE",
        optionAccessor: (d) => {
          return { title: d.userRoleDesc, value: d.userRoleCode };
        },
      },
      submitValueAccessor: (d) => {
        return d.value;
      },
      required: true,
    },
    userPhone: {
      label: "Phone Number",
      type: "phone",
      required: true,
    },
    userEmail: {
      label: "Email",
      required: true,
    },
  },
};

export default helpRequestForm;
