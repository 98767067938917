import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";
import moment from "moment";

const patientFilterConfig: InputByHashConfigInterface = {
  inputsById: ["etlStatusCode", "etlStartDate"],

  inputsByHash: {
    etlStatusCode: {
      label: "Status",
      type: "autocomplete",
      autocompleteTypeOptions: {
        resource: "lovs/ETLSTATUS",
        optionAccessor: (d) => {
          return { title: d.etlStatusDesc, value: d.etlStatusCode };
        },
      },
      submitValueAccessor: (d) => {
        return d.value;
      },
    },
    etlStartDate: {
      type: "dateRange",
      label: "From",
      submitValueAccessor: (d) => {
        return `[${moment(d.from).format("MM/DD/YYYY")}-${moment(d.to).format(
          "MM/DD/YYYY"
        )}]`;
      },
    },
  },
};

export default patientFilterConfig;
