import { canByRoles } from "utils/permissions";

const Can = ({
  allowedRoles = [],
  notAllowedRoles = [],
  children,
}: {
  allowedRoles?: string[];
  notAllowedRoles?: string[];
  children: JSX.Element;
}) => {
  if (allowedRoles) {
    return canByRoles(allowedRoles) ? children : null;
  } else if (notAllowedRoles) {
    return !canByRoles(allowedRoles) ? children : null;
  } else {
    return null;
  }
};

export default Can;
