import React, { useState, useRef } from "react";
import BaseModal from "components/modals/BaseModal";
import TextButton from "components/uiComponents/buttons/TextButton";
import { changePasswordFormConfig } from "config/auth/authForm";
import { useAuthCenter } from "AuthCenter";
import AuthFormLayout, {
  Ref,
} from "components/uiComponents/forms/AuthFormLayout";
import AuthPaper from "components/uiComponents/papers/AuthPaper";
import { Box } from "@material-ui/core";
import { useFormMesssageBar } from "components/uiComponents/forms/FormMessageBar";

export default function ChangePasswordModal({
  modalIsOpen,
  closeModal,
}: {
  modalIsOpen: boolean;
  closeModal: () => void;
}) {
  const authCenter = useAuthCenter();
  const [dataLoading, setDataLoading] = useState(false);
  const { title, children, type, setMessage } = useFormMesssageBar();
  const authFormLayoutRef = useRef<Ref>(null);

  const onSubmitValidForm = async (data: any) => {
    try {
      setDataLoading(true);
      const response = await authCenter.mfaChangePassword(
        data.currentPwd,
        data.newPwd
      );
      setDataLoading(false);
      authFormLayoutRef?.current?.clearAllInputs();
      if (response.message) {
        setMessage(response.message);
      }
      closeModal();
    } catch (err) {
      setDataLoading(false);

      if (err.response.data.message) {
        setMessage(err.response.data.message, null, "error");
      }
    }
  };

  let formMessageBarProps = {
    title: title,
    children: children,
    type: type,
  };

  return (
    <BaseModal open={modalIsOpen} onClose={closeModal}>
      <Box maxWidth={"571px"} margin={"auto"} width={"100%"}>
        <AuthPaper
          title={"Change password"}
          formMessageBar={formMessageBarProps}
          showLogo={false}
        >
          <AuthFormLayout
            ref={authFormLayoutRef}
            inputsById={changePasswordFormConfig.inputsById}
            inputsByHash={changePasswordFormConfig.inputsByHash}
            onSubmitValidForm={onSubmitValidForm}
            isDataLoading={dataLoading}
            backButton={<TextButton onClick={closeModal}>Cancel</TextButton>}
            submitLabel={"Change Password"}
          />
        </AuthPaper>
      </Box>
    </BaseModal>
  );
}
