import { useState } from 'react';

export default function useModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalId, setModalId] = useState<null | string>(null);

  const openModal = (modalId?: string) => {
    setModalIsOpen(true);

    if (modalId) {
      setModalId(modalId);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return [modalIsOpen, openModal, closeModal, modalId] as [
    boolean,
    (modalId?: string) => void,
    () => void,
    string
  ];
}
