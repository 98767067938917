import React, { useState, useRef } from "react";
import { Container } from "@material-ui/core";
import SmartTable, {
  SmartTableRef,
} from "components/uiComponents/table/SmartTable";
import userTableConfig from "config/admin/users/userTableConfig";
import { enhanceActionConfig } from "utils";
import AddPostModal, { AddPostModalRef } from "components/modals/AddPostModal";
import useModal from "hooks/useModal";
import newUserFormConfig from "config/admin/users/newUserFormConfig";
import SubmitButton from "components/uiComponents/buttons/SubmitButton";
import usePostProvider from "hooks/usePostProvider";
import { useSnackbar } from "notistack";
import PaperTable from "components/uiComponents/papers/AdminTablePaper";
import SearchFilter from "components/uiComponents/Filter/SearchFilter";
import { Box } from "@material-ui/core";
import filterConfig from "config/admin/users/usersFilterConfig";

export default function UsersPage() {
  const [modalIsOpen, openModal, closeModal, modalId] = useModal();
  const editPostModalRef = useRef<AddPostModalRef>(null);
  const tableRef = useRef<SmartTableRef>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState({});
  const [editData, setEditData] = useState<{ [index: string]: any }>({});

  const { postData, dataLoading: createUserInProgress } = usePostProvider(
    "post",
    `mfa/users`
  );

  const {
    postData: editPatient,
    dataLoading: editUserInProgress,
  } = usePostProvider("put");

  const {
    postData: deleteUser,
    dataLoading: deleteUserInProgress,
  } = usePostProvider("delete");

  const handleClickEdit = (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    openModal("edit");
    setEditData(row);
  };

  const handleClickRemove = async (
    event: React.MouseEvent<unknown>,
    row: { [index: string]: any }
  ) => {
    try {
      await deleteUser(`mfa/users/${row.id}`);
      tableRef!.current!.loadData();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const tableActionsConfig = {
    edit: {
      onClick: handleClickEdit,
      inProgress: editUserInProgress,
    },
    remove: {
      onClick: handleClickRemove,
      inProgress: deleteUserInProgress,
    },
  };

  const tableConfigWithClickHandlers = enhanceActionConfig(
    userTableConfig,
    tableActionsConfig
  );

  const onSubmitValidEdit = async (data: any) => {
    try {
      await editPatient(`mfa/users/${editData.id}`, {
        data,
      });
      closeModal();
      tableRef!.current!.loadData();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const onSubmitValidCreate = async (data: any) => {
    try {
      await postData(null, {
        data,
      });
      closeModal();
      tableRef!.current!.loadData();
    } catch (err) {
      enqueueSnackbar({ message: err.message, variant: "error" });
    }
  };

  const handleFilter = (filter: { [index: string]: any }) => {
    tableRef!.current!.resetCurrentPage();
    setFilter(filter);
  };

  return (
    <Container>
      <AddPostModal
        ref={editPostModalRef}
        modalIsOpen={modalIsOpen && modalId === "edit"}
        closeModal={closeModal}
        title={"Edit User"}
        inputsById={newUserFormConfig.inputsById}
        inputsByHash={newUserFormConfig.inputsByHash}
        onSubmitValidForm={onSubmitValidEdit}
        submitLabel={"Save"}
        isDataLoading={editUserInProgress}
        initData={editData}
      />
      <AddPostModal
        modalIsOpen={modalIsOpen && modalId === "create"}
        closeModal={closeModal}
        title={"New User"}
        inputsById={newUserFormConfig.inputsById}
        inputsByHash={newUserFormConfig.inputsByHash}
        onSubmitValidForm={onSubmitValidCreate}
        submitLabel={"Create User"}
        isDataLoading={createUserInProgress}
      />
      <PaperTable
        title={"User List"}
        controls={
          <Box display="flex">
            <SearchFilter
              onSubmitFilter={handleFilter}
              filterConfig={filterConfig}
              showSearch={false}
            />
            <SubmitButton
              disableEndIcon={true}
              size={"small"}
              onClick={() => {
                openModal("create");
              }}
            >
              Add User
            </SubmitButton>
          </Box>
        }
      >
        <SmartTable
          ref={tableRef}
          id="users"
          resource={`users`}
          columns={tableConfigWithClickHandlers}
          showPagination={true}
          filter={filter}
          mobileConfig={{
            actionsCol: {
              gridProps: {
                xs: 6,
              },
            },
          }}
        />
      </PaperTable>
    </Container>
  );
}
