import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.4,12.6H3.6C3.3,12.6,3,12.3,3,12s0.3-0.6,0.6-0.6h16.9c0.3,0,0.6,0.3,0.6,0.6S20.7,12.6,20.4,12.6z M21,6.4
        c0-0.3-0.3-0.6-0.6-0.6H3.6C3.3,5.8,3,6.1,3,6.4s0.3,0.6,0.6,0.6h16.9C20.7,6.9,21,6.7,21,6.4z M21,17.6c0-0.3-0.3-0.6-0.6-0.6H3.6
        c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h16.9C20.7,18.2,21,17.9,21,17.6z"
      />
    </SvgIcon>
  );
}
