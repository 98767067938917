import InputByHashConfigInterface from "interfaces/InputByHashConfigInterface";

const patientFilterConfig: InputByHashConfigInterface = {
  inputsById: ["patientFirstName", "patientLastName"],

  inputsByHash: {
    patientFirstName: {
      label: "First Name",
    },
    patientLastName: {
      label: "Last Name",
    }, 
  },
};

export default patientFilterConfig;
